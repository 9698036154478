import React from 'react'
import NumberFormat from 'react-number-format'
import {ReactComponent as Earrings} from '../../assets/icons/drop-earrings.svg'

const splitPrice = (price) => {
    if (price==null || price==='') return [null];
    const idx = price.indexOf(' - ');
    if (idx < 0) return (
        <NumberFormat 
            displayType='text'
            decimalScale={2}
            fixedDecimalScale={true} 
            thousandSeparator={true}
            prefix='$'
            value={price}
        />
    )
    return <>
        <NumberFormat
            displayType='text'
            decimalScale={2}
            fixedDecimalScale={true} 
            thousandSeparator={true}
            prefix='$'
            value={price.slice(0, idx)}
        />
        -
        <NumberFormat
            displayType='text'
            decimalScale={2}
            fixedDecimalScale={true} 
            thousandSeparator={true}
            prefix='$'
            value={price.slice(idx+3)}
        />
    </>
}

export default function StoneCard({jwl, deleteStone}) {
    const getIcon = () => {
        if (jwl.stillPhotoUrl) return <img className='photo-icon' src={jwl.stillPhotoUrl} />
        else return <Earrings style={{verticalAlign: 'middle'}} />
    }
    
    return (
        <div className='confirmation-container border stone-card confirmation-text'>
            <div className='row even-split' style={{marginBottom: '0.5rem', color: 'var(--primary-colour)'}}>
                <div className='align-top bold'>
                    {getIcon()}
                    <span>&nbsp;{jwl.name}</span>
                </div>
                <div className='right align-top semibold'>
                    {
                        jwl.virtual
                        ? splitPrice(jwl.total)
                        : <NumberFormat 
                            displayType='text'
                            decimalScale={2}
                            fixedDecimalScale={true} 
                            thousandSeparator={true}
                            prefix='$'
                            value={jwl.total}
                        />
                    }
                </div>
            </div>
            <div className='row even-split'>
                <div className='caption40'>
                    <div className='align-top semibold'>Model:</div>
                    <div className='align-top'>{jwl.model}</div>
                </div>
                <div className='caption40'>
                    <div className='align-top semibold'>Weight:</div>
                    <div className='align-top'><NumberFormat displayType='text' decimalScale={2} fixedDecimalScale={true} value={jwl.weight}/></div>
                </div>
            </div>
            <div className='row even-split'>
                <div className='caption40'>
                    <div className='align-top semibold'>Type:</div>
                    <div className='align-top'>{jwl.modelType}</div>
                </div>
                <div style={{verticalAlign: 'bottom'}}>
                    {
                        deleteStone
                        ? <button className='tab-button right' onClick={() => deleteStone(jwl.parcelName)}><i className='fas fa-times-circle'/></button>
                        : null
                    }
                </div>
            </div>
        </div>
    )
}