import React, {Component} from 'react'
import {connect} from 'react-redux'
import moment from 'moment-timezone'
import NumberFormat from 'react-number-format'
import * as actions from '../../actions'
import {history_type_names} from '../../assets/codes'
import ConfirmModal from '../common/confirm_modal'
import ConfirmJewelleryModal from './confirm_jewellery_modal'
import {ReactComponent as Earrings} from '../../assets/icons/drop-earrings.svg'
import {error} from '../common/toast'

const dateFormat = 'MMM DD, YYYY h:mm A'
const deletableTypes = ['REQUEST','RETURN','SOLD']

class JewelleryDetails extends Component {

    state = {
        remarks: null,
        dateChange: null
    }

    componentDidMount() {
        this.props.getJewelleryDetails(this.props.parcelName)
    }

    componentDidUpdate(prevProps) {
        if (this.props.parcelName!==prevProps.parcelName) {
            this.props.getJewelleryDetails(this.props.parcelName)
        }
    }

    renderCertNo = (lab, cert) => {
        if (lab=='GIA' || lab=='GIA-LG') return <a href={`https://www.gia.edu/report-check?reportno=${cert}`} target='_blank'>{cert}</a>
        else return cert;
    }

    deleteHistory = (historyRow) => {
        return this.props.deleteLastJewelleryHistory(historyRow)
        .then(() => this.props.getJewelleryDetails(this.props.parcelName))
    }
    backdateHistory = (historyRow) => {
        if (moment(this.state.dateChange).isValid()) {
            return this.props.backdateJewelleryHistory(historyRow, moment(this.state.dateChange).toISOString())
            .then(() => {
                this.setState({dateChange: null})
                return this.props.getJewelleryDetails(this.props.parcelName)
            })
        } else {
            return Promise.reject(error("Not a valid date."));
        }
    }

    requestStone = () => {
        return this.props.requestJewellery([this.props.parcelName], this.state.remarks)
        .then(() => this.setState({remarks: null}))
        .then(() => this.props.getJewelleryDetails(this.props.parcelName))
    }
    returnStone = () => {
        return this.props.returnJewellery([this.props.parcelName], this.state.remarks)
        .then(() => this.setState({remarks: null}))
        .then(() => this.props.getJewelleryDetails(this.props.parcelName))
    }
    sellStone = () => {
        return this.props.sellJewellery([this.props.parcelName], this.state.remarks)
        .then(() => this.setState({remarks: null}))
        .then(() => this.props.getJewelleryDetails(this.props.parcelName))
    }

    formatDate = date => {
        const dt = moment(date);
        return <>
            {dt.format('MMM D, YYYY')}&nbsp;<span className='time-only'>{dt.format('h:mm A')}</span>
        </>
    }

    getDataRow = (caption, value, number) => {
        if (value==null || value==='' || (number && value===0)) return null;
        if (number) return (
            <div className='row caption38'>
                <div>{caption}</div>
                <div className="semibold">
                    <NumberFormat displayType='text' decimalScale={3} thousandSeparator={true} value={value} {...number} />
                </div>
            </div>
        )
        else return (
            <div className='row caption38'>
                <div>{caption}</div>
                <div className="semibold">{value}</div>
            </div>
        )
    }

    renderHistory = (jwl) => {
        if (!jwl || !Array.isArray(jwl.history) || !jwl.history.length) return null;
        return <>
            {jwl.history.map((row,i) => (
                <div key={i} className='row caption40'>
                    <div className="semibold">
                        {history_type_names[row.type] ? history_type_names[row.type] : row.type}
                        &emsp;
                        {
                            i==jwl.history.length-1 && deletableTypes.includes(row.type) && this.props.isAdmin ? 
                            <ConfirmModal
                                confirm={() => this.deleteHistory(row)}
                                message='Are you sure you want to delete this event?'
                                trigger={onClick => <button className='list-button btn-warning' onClick={onClick}><i className="fas fa-backspace"/></button>}
                            />
                            : null
                        }
                    </div>
                    <div className="semibold">
                        {this.formatDate(row.date)}
                        &emsp;
                        {
                            i==jwl.history.length-1 && deletableTypes.includes(row.type) && this.props.isAdmin ? 
                            <ConfirmModal
                                confirm={() => this.backdateHistory(row)}
                                message={<>
                                    Change this event's date to {this.state.dateChange ? moment(this.state.dateChange).format(dateFormat) : '...'}?
                                    <br/><br/>
                                    <input type="datetime-local" onChange={e => this.setState({dateChange: e.target.value})} value={this.state.dateChange || moment(row.date).format("YYYY-MM-DDTHH:mm")} />
                                </>}
                                trigger={onClick => <button className='list-button btn-warning' onClick={onClick}><i className="fas fa-edit"/></button>}
                            />
                            : null
                        }
                    </div>
                </div>
            ))}
        </>
    }

    getIcon = () => {
        if (this.props.jwl.stillPhotoUrl) return <img className='photo-icon' src={this.props.jwl.stillPhotoUrl} />
        else return <Earrings style={{verticalAlign: 'middle', color: 'var(--primary-colour)'}} />
    }

    render() {
        if (!this.props.jwl) return "Piece could not be loaded";
        const jwl = this.props.jwl || {};
        return (
            <div className='body stone-details__container'>
                <div className='row stone-details__container--header'>
                    <div className='stone-details__page-title--container'>
                        <div className='stone-details__page-title'>
                            Jewellery Details
                        </div>
                        <div className='stone-details__page-parcelName semibold'>
                            {jwl.parcelName}
                        </div>
                    </div>
                    <div className='stone-details__price-container card'>
                        <div className='row stone-details__price--card-header'>
                            {this.getIcon()}
                            <div className='flexbox-filler'/>
                            &nbsp;
                            <span><NumberFormat displayType='text' decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} value={jwl.total} prefix='$' /></span>
                        </div>
                        <div className='row even-split'>
                            {
                                jwl.status==='AVAILABLE' ? 
                                <div className="stone-details__container--request-btn">
                                    <ConfirmJewelleryModal
                                        confirm={this.requestStone} 
                                        title='Confirm Request'
                                        message={`Review and click "Request Jewellery" to confirm.`} 
                                        confirmCaption='Request Jewellery'
                                        trigger={onClick => <button className='btn' onClick={onClick}>Request</button>}
                                        stones={[jwl]}
                                        comment={this.state.remarks}
                                        updateComment={(e) => this.setState({remarks: e.target.value})}
                                    />
                                </div>
                                : null
                            }
                            {
                                jwl.status==='CUSTOMER' || jwl.status==='REQUESTED' ? 
                                <>
                                    <div>
                                        <ConfirmJewelleryModal
                                            confirm={this.returnStone} 
                                            title='Confirm Return'
                                            message={`Review and click "Return Jewellery" to confirm.`} 
                                            confirmCaption='Return Jewellery'
                                            trigger={onClick => <button className='btn btn-secondary' onClick={onClick}>Return</button>}
                                            stones={[jwl]}
                                            comment={this.state.remarks}
                                            updateComment={(e) => this.setState({remarks: e.target.value})}
                                        />
                                    </div>
                                    <div>
                                        <ConfirmJewelleryModal
                                            confirm={this.sellStone} 
                                            title='Confirm Sale'
                                            message={`Review and click "Sell Jewellery" to confirm.`} 
                                            confirmCaption='Sell Jewellery'
                                            trigger={onClick => <button className='btn' onClick={onClick}>Sold</button>}
                                            stones={[jwl]}
                                            comment={this.state.remarks}
                                            updateComment={(e) => this.setState({remarks: e.target.value})}
                                        />
                                    </div>
                                </>
                                : null
                            }
                        </div>
                    </div>
                </div>
                <div className='card stone-details__details-container'>
                    <div className='jwl-details__grid'>
                        <div className='stone-details__section'>
                            <div className='row section-header'>Piece Properties<hr/></div>
                            {this.getDataRow('Weight', jwl.weight, {decimalScale: 2, fixedDecimalScale: true})}
                            {this.getDataRow('Lab', jwl.lab)}
                            {this.getDataRow('Cert', this.renderCertNo(jwl.lab, jwl.certNo))}
                            {this.getDataRow('Model', jwl.model)}
                            {this.getDataRow('Type', jwl.modelType)}
                            {this.getDataRow('Style', jwl.modelSubType)}
                            {this.getDataRow('Finish', jwl.modelFinish)}
                            {this.getDataRow('Status', jwl.status)}
                            {this.getDataRow('In Cart', jwl.inCart ? "Yes" : "No")}
                            {this.getDataRow('Last Updated', this.formatDate(jwl.lastUpdated))}
                        </div>
                        <div className='stone-details__section'>
                            <div className='row section-header'>History<hr/></div>
                            {this.renderHistory(jwl)}
                        </div>
                        {
                            jwl.photoUrl ?
                                /(\.jpg|\.jpeg|\.png)$/i.test(jwl.photoUrl) ? 
                                <img src={jwl.photoUrl} alt='Image of diamond' className='row' /> :
                                <div className={`video-container${jwl.photoUrl.toLowerCase().includes('vision360.html') ? '' : (jwl.photoUrl.toLowerCase().includes('dnadiamond.net') || jwl.photoUrl.toLowerCase().includes('/dnadata/') ? '-dnad' : '-old')}`}>
                                    <iframe
                                        src={jwl.photoUrl}
                                        scrolling="no" seamless="seamless" 
                                        className={`video-iframe${jwl.photoUrl.toLowerCase().includes('vision360.html') ? '' : (jwl.photoUrl.toLowerCase().includes('dnadiamond.net') || jwl.photoUrl.toLowerCase().includes('/dnadata/') ? '-dnad' : '-old')}`}
                                    />
                                </div>
                            : (jwl.stillPhotoUrl ?
                                <img src={jwl.stillPhotoUrl} alt='Image of diamond' className='row' />
                            : null)
                        }
                        {((jwl.photoUrl || jwl.stillPhotoUrl) && jwl.stones?.length > 1) ? <div className='jwl-details__spacer' /> : null}
                        {jwl.stones?.map(stone => (
                            <div className='jwl-details__stone' key={stone.parcelname}>
                                <div className='row section-header'>{stone.parcelName}<hr/></div>
                                {this.getDataRow('Lab', stone.lab)}
                                {this.getDataRow('Cert', this.renderCertNo(stone.lab, stone.certNo))}
                                {this.getDataRow('Weight', stone.weight, {decimalScale: 2, fixedDecimalScale: true})}
                                {this.getDataRow('Shape', ((this.props.shapeList || []).find(sh => sh.shapeCode==stone.shapeCode) || {})[stone.cut=='BL' ? 'blDescription' : 'description']) || stone.shapeCode}
                                {this.getDataRow('Colour', stone.colour)}
                                {this.getDataRow('Clarity', stone.clarity)}
                                {this.getDataRow('Fluor', stone.fluorescence)}
                                {this.getDataRow('Cut', stone.cut)}
                                {this.getDataRow('Polish', stone.polish)}
                                {this.getDataRow('Symmetry', stone.symmetry)}
                                {this.getDataRow('Length', stone.length, {})}
                                {this.getDataRow('Width', stone.width, {})}
                                {this.getDataRow('Height', stone.height, {})}
                                {this.getDataRow('Table', stone.tablePct, {suffix: '%'})}
                                {this.getDataRow('Depth', stone.depthPct, {suffix: '%'})}
                                {this.getDataRow('Girdle', stone.girdlePct, {suffix: '%'})}
                                {this.getDataRow('Crown Height', stone.crownHeight, {suffix: '%'})}
                                {this.getDataRow('Crown Angle', stone.crownAngle, {suffix: '°'})}
                                {this.getDataRow('Pavilion Depth', stone.pavilionDepth, {suffix: '%'})}
                                {this.getDataRow('Pavilion Angle', stone.pavilionAngle, {suffix: '°'})}
                                {this.getDataRow('Star Halves', stone.starHalves, {suffix: '%'})}
                                {this.getDataRow('Lower Half', stone.pavilionHalves, {suffix: '%'})}
                                {
                                    stone.photoUrl && false ? // no v360 for now
                                        /(\.jpg|\.jpeg|\.png)$/i.test(stone.photoUrl) ? 
                                        <img src={stone.photoUrl} alt='Image of diamond' className='row' /> :
                                        <div className={`video-container${stone.photoUrl.toLowerCase().includes('vision360.html') ? '' : (stone.photoUrl.toLowerCase().includes('dnadiamond.net') || stone.photoUrl.toLowerCase().includes('/dnadata/') ? '-dnad' : '-old')}`}>
                                            <iframe
                                                src={stone.photoUrl}
                                                scrolling="no" seamless="seamless" 
                                                className={`video-iframe${stone.photoUrl.toLowerCase().includes('vision360.html') ? '' : (stone.photoUrl.toLowerCase().includes('dnadiamond.net') || stone.photoUrl.toLowerCase().includes('/dnadata/') ? '-dnad' : '-old')}`}
                                            />
                                        </div>
                                    : null
                                }
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state, ownProps) {
    return {
        jwl: (state.jewellery.details) ? state.jewellery.details[ownProps.parcelName] : null,
        shapeList: state.attributes.shapes,
        isAdmin: state.user.currentUser.administrator
    };
}

export default connect(mapStateToProps, actions)(JewelleryDetails)