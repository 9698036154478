import axios from 'axios'
import FileSaver from 'file-saver'
import {
    START_LOADING,
    STOP_LOADING,
    SET_CUSTOMER_LIST,
    SET_CUSTOMER,
    SET_USER_LIST,
    SET_AVAILABLE_ACCOUNTS,
    SET_STORES,
    SET_COL_MAP,
    SET_VAL_MAP,
    SET_BIN_TEMPLATES,
    SET_IDEAL_BINS,
    CLEAR_IDEAL_BINS,
    SET_REASSORT_RESULTS,
    SET_ASSET_PAGES
} from '../reducers/types'
import {success, error} from '../components/common/toast'
import {ROOT_URL} from './config'

export function setMyCustomer(customer) {
    return function(dispatch) {
        dispatch({type: SET_CUSTOMER, payload: customer})
    }
}

export function getAllCustomers() {
    return function(dispatch) {
        dispatch({type: START_LOADING, payload: 'Retrieving Customer List'})
        return axios({
            method: 'POST',
            url: `${ROOT_URL}/admin/allcustomers`
        })
        .finally(() => dispatch({type: STOP_LOADING, payload: 'Retrieving Customer List'}))
        .then(res => {
            dispatch({type: SET_CUSTOMER_LIST, payload: res.data});
        })
        .catch(err => {
            error(err)
            return Promise.reject(err);
        })
    }
}

export function createCustomer(customer) {
    return function(dispatch) {
        dispatch({type: START_LOADING, payload: 'Creating Customer'})
        return axios({
            method: 'POST',
            url: `${ROOT_URL}/admin/newcustomer`,
            data: customer
        })
        .finally(() => dispatch({type: STOP_LOADING, payload: 'Creating Customer'}))
        .catch(err => {
            error(err);
            return Promise.reject(err);
        })
    }
}

export function editCustomer(customer) {
    return function(dispatch) {
        dispatch({type: START_LOADING, payload: 'Updating Customer'})
        return axios({
            method: 'POST',
            url: `${ROOT_URL}/admin/updatecustomer`,
            data: customer
        })
        .finally(() => dispatch({type: STOP_LOADING, payload: 'Updating Customer'}))
        .catch(err => {
            error(err);
            return Promise.reject(err);
        })
    }
}

export function getMarkups(customerId) {
    return function(dispatch) {
        dispatch({type: START_LOADING, payload: 'Retrieving Markup Rules'})
        return axios({
            method: 'POST',
            url: `${ROOT_URL}/admin/getmarkup`,
            data: customerId,
            headers: {'Content-Type': 'application/json'}
        })
        .finally(() => dispatch({type: STOP_LOADING, payload: 'Retrieving Markup Rules'}))
        .then(res => res.data)
        .catch(err => {
            error(err)
            return Promise.reject(err);
        })
    }
}

export function setMarkups(customerId, data) {
    return function(dispatch) {
        dispatch({type: START_LOADING, payload: 'Updating Retail Markup'})
        return axios({
            method: 'POST',
            url: `${ROOT_URL}/admin/setmarkup`,
            data: data,
            headers: {'X-DIALOG-CUSTOMER': customerId}
        })
        .finally(() => dispatch({type: STOP_LOADING, payload: 'Updating Retail Markup'}))
        .catch(err => {
            error(err);
            return Promise.reject(err);
        })
    }
}

export function getAvailableAccountCodes() {
    return function(dispatch) {
        dispatch({type: START_LOADING, payload: 'Retrieving Accounts'})
        return axios({
            method: 'POST',
            url: `${ROOT_URL}/admin/getnullaccounts`
        })
        .finally(() => dispatch({type: STOP_LOADING, payload: 'Retrieving Accounts'}))
        .then(res => {
            dispatch({type: SET_AVAILABLE_ACCOUNTS, payload: res.data});
            return res.data;
        })
        .catch(err => {
            error(err);
            return Promise.reject(err);
        })
    }
}

export function updateAvailableAccounts(accountCodes) {
    return function(dispatch) {
        dispatch({type: START_LOADING, payload: 'Updating Accounts'})
        return axios({
            method: 'POST',
            url: `${ROOT_URL}/admin/updateaccountcodes`,
            data: accountCodes
        })
        .finally(() => dispatch({type: STOP_LOADING, payload: 'Updating Accounts'}))
        .catch(err => {
            error(err);
            return Promise.reject(err);
        })
    }
}

export function deleteAvailableAccounts(accountCodes) {
    return function(dispatch) {
        dispatch({type: START_LOADING, payload: 'Updating Accounts'})
        return axios({
            method: 'POST',
            url: `${ROOT_URL}/admin/deleteaccountcodes`,
            data: accountCodes
        })
        .finally(() => dispatch({type: STOP_LOADING, payload: 'Updating Accounts'}))
        .catch(err => {
            error(err);
            return Promise.reject(err);
        })
    }
}

export function getAllUsers() {
    return function(dispatch) {
        dispatch({type: START_LOADING, payload: 'Retrieving Users'})
        return axios({
            method: 'POST',
            url: `${ROOT_URL}/admin/allusers`
        })
        .finally(() => dispatch({type: STOP_LOADING, payload: 'Retrieving Users'}))
        .then(res => {
            dispatch({type: SET_USER_LIST, payload: res.data});
        })
        .catch(err => {
            error(err)
            return Promise.reject(err);
        })
    }
}

export function createUser(user) {
    return function(dispatch) {
        dispatch({type: START_LOADING, payload: 'Creating User'})
        return axios({
            method: 'POST',
            url: `${ROOT_URL}/admin/newuser`,
            data: user
        })
        .finally(() => dispatch({type: STOP_LOADING, payload: 'Creating User'}))
        .catch(err => {
            error(err);
            return Promise.reject(err);
        })
    }
}

export function editUser(user) {
    return function(dispatch) {
        dispatch({type: START_LOADING, payload: 'Updating User'})
        return axios({
            method: 'POST',
            url: `${ROOT_URL}/admin/updateuser`,
            data: user
        })
        .finally(() => dispatch({type: STOP_LOADING, payload: 'Updating User'}))
        .catch(err => {
            error(err);
            return Promise.reject(err);
        })
    }
}

export function resetPassword(username) {
    return function(dispatch) {
        dispatch({type: START_LOADING, payload: 'Resetting Password'})
        return axios({
            method: 'POST',
            url: `${ROOT_URL}/admin/resetpassword`,
            data: {username}
        })
        .finally(() => dispatch({type: STOP_LOADING, payload: 'Resetting Password'}))
        .catch(err => {
            error(err);
            return Promise.reject(err);
        })
    }
}

export function sync() {
    return function(dispatch) {
        dispatch({type: START_LOADING, payload: 'Synchronizing with DX'})
        return axios({
            method: 'POST',
            url: `${ROOT_URL}/sync/sync`
        })
        .finally(() => dispatch({type: STOP_LOADING, payload: 'Synchronizing with DX'}))
        .then(() => {
            success('Sync request submitted.');
        })
        .catch(err => {
            success('Sync failed.');
        })
    }
}

export function makeReferral(ref) {
    return function(dispatch) {
        dispatch({type: START_LOADING, payload: 'Sending Referral'})
        return axios({
            method: 'POST',
            url: `${ROOT_URL}/admin/makecustomerreferral`,
            data: ref
        })
        .finally(() => dispatch({type: STOP_LOADING, payload: 'Sending Referral'}))
        .then(() => {
            success('Referral Sent');
        })
        .catch(err => {
            error(err);
            return Promise.reject(err);
        })
    }
}

export function getStores() {
    return function(dispatch) {
        dispatch({type: START_LOADING, payload: 'Retrieving Stores'})
        return axios({
            method: 'POST',
            url: `${ROOT_URL}/reassortment/getstores`
        })
        .finally(() => dispatch({type: STOP_LOADING, payload: 'Retrieving Stores'}))
        .then(res => {
            dispatch({type: SET_STORES, payload: res.data})
        })
        .catch(err => {
            error(err)
            return Promise.reject(err)
        })
    }
}
export function clearStores() {
    return function(dispatch) {
        dispatch({type: SET_STORES, payload: null})
    }
}

export function saveStores(stores) {
    return function(dispatch) {
        dispatch({type: START_LOADING, payload: 'Saving Stores'})
        return axios({
            method: 'POST',
            url: `${ROOT_URL}/reassortment/setstores`,
            data: stores
        })
        .finally(() => dispatch({type: STOP_LOADING, payload: 'Saving Stores'}))
        .then(() => success('Stores saved'))
        .catch(err => {
            error(err)
            return Promise.reject(err)
        })
    }
}

export function getCustomerColumnMap() {
    return function(dispatch) {
        dispatch({type: START_LOADING, payload: 'Retrieving Columns'})
        return axios({
            method: 'POST',
            url: `${ROOT_URL}/reassortment/getcolumnmaps`
        })
        .finally(() => dispatch({type: STOP_LOADING, payload: 'Retrieving Columns'}))
        .then(res => {
            dispatch({type: SET_COL_MAP, payload: res.data})
        })
        .catch(err => {
            error(err)
            return Promise.reject(err);
        })
    }
}

export function clearCustomerColumnMap() {
    return function(dispatch) {
        dispatch({type: SET_COL_MAP, payload: null})
    }
}

export function saveColMap(colMap) {
    return function(dispatch) {
        dispatch({type: START_LOADING, payload: 'Saving Columns'})
        return axios({
            method: 'POST',
            url: `${ROOT_URL}/reassortment/setcolumnmaps`,
            data: colMap
        })
        .finally(() => dispatch({type: STOP_LOADING, payload: 'Saving Columns'}))
        .then(() => success('Column Mapping Saved'))
        .catch(err => {
            error(err)
            return Promise.reject(err);
        })
    }
}

export function getCustomerValueMap() {
    return function(dispatch) {
        dispatch({type: START_LOADING, payload: 'Retrieving Values'})
        return axios({
            method: 'POST',
            url: `${ROOT_URL}/reassortment/getvaluemaps`
        })
        .finally(() => dispatch({type: STOP_LOADING, payload: 'Retrieving Values'}))
        .then(res => {
            dispatch({type: SET_VAL_MAP, payload: res.data})
        })
        .catch(err => {
            error(err)
            return Promise.reject(err);
        })
    }
}

export function clearCustomerValueMap() {
    return function(dispatch) {
        dispatch({type: SET_VAL_MAP, payload: null})
    }
}

export function saveValueMap(valMap) {
    return function(dispatch) {
        dispatch({type: START_LOADING, payload: 'Saving Value Mapping'})
        return axios({
            method: 'POST',
            url: `${ROOT_URL}/reassortment/setvaluemaps`,
            data: valMap
        })
        .finally(() => dispatch({type: STOP_LOADING, payload: 'Saving Value Mapping'}))
        .then(() => success('Value Mapping Saved'))
        .catch(err => {
            error(err)
            return Promise.reject(err);
        })
    }
}

export function getBinTemplates() {
    return function(dispatch) {
        dispatch({type: START_LOADING, payload: 'Getting Ideal Templates'})
        return axios({
            method: 'POST',
            url: `${ROOT_URL}/reassortment/getbintemplates`
        })
        .finally(() => dispatch({type: STOP_LOADING, payload: 'Getting Ideal Templates'}))
        .then(res => {
            dispatch({type: SET_BIN_TEMPLATES, payload: res.data})
        })
        .catch(err => {
            error(err)
            return Promise.reject(err);
        })
    }
}

export function getTemplateBins(templateId) {
    return function(dispatch) {
        dispatch({type: START_LOADING, payload: 'Getting Ideal Inventory'})
        return axios({
            method: 'POST',
            url: `${ROOT_URL}/reassortment/getbins`,
            data: templateId,
            headers: {'Content-Type': 'application/json'}
        })
        .finally(() => dispatch({type: STOP_LOADING, payload: 'Getting Ideal Inventory'}))
        .then(res => {
            dispatch({type: SET_IDEAL_BINS, templateId, payload: res.data})
        })
        .catch(err => {
            error(err)
            return Promise.reject(err);
        })
    }
}

export function clearTemplateBins() {
    return function(dispatch) {
        dispatch({type: CLEAR_IDEAL_BINS})
    }
}

export function loadIdealInventory(data) {
    return function(dispatch) {
        dispatch({type: START_LOADING, payload: 'Saving Ideal Inventory'})
        return axios({
            method: 'POST',
            url: `${ROOT_URL}/reassortment/loadideal`,
            data
        })
        .finally(() => dispatch({type: STOP_LOADING, payload: 'Saving Ideal Inventory'}))
        .then(() => success('Ideal Inventory Loaded'))
        .catch(err => {
            error(err)
            return Promise.reject(err);
        })
    }
}

export function loadCurrentStock(data) {
    return function(dispatch) {
        dispatch({type: START_LOADING, payload: 'Uploading Current Inventory'})
        return axios({
            method: 'POST',
            url: `${ROOT_URL}/reassortment/reassortexcel`,
            data
        })
        .finally(() => dispatch({type: STOP_LOADING, payload: 'Uploading Current Inventory'}))
        .then(res => {
            dispatch({type: SET_REASSORT_RESULTS, payload: res.data})
            success('Current Stock Loaded')
        })
        .catch(err => {
            error(err)
            return Promise.reject(err);
        })
    }
}

export function clearReassortResults() {
    return function(dispatch) {
        dispatch({type: SET_REASSORT_RESULTS, payload: null})
    }
}

export function compareDialogIdeal({templateId}) {
    return function(dispatch) {
        dispatch({type: START_LOADING, payload: 'Sorting Available Inventory'})
        return axios({
            method: 'POST',
            url: `${ROOT_URL}/reassortment/comparedialogideal/${templateId}`,
        })
        .finally(() => dispatch({type: STOP_LOADING, payload: 'Sorting Available Inventory'}))
        .then(res => {
            return res.data
        })
        .catch(err => {
            error(err)
            return Promise.reject(err);
        })
    }
}

export function getAllReports() {
    return function(dispatch, getState) {
        dispatch({type: START_LOADING, payload: 'Generating Reports'})
        const costPart = getState().user.currentUser.administrator ? '?includeCost=true' : ''
        return axios({
            method: 'POST',
            url: `${ROOT_URL}/reports/combined${costPart}`,
            responseType: 'arraybuffer'
        })
        .finally(() => dispatch({type: STOP_LOADING, payload: 'Generating Reports'}))
        .then(res => {
            let filename = null;
            const disposition = res.headers['content-disposition'];
            if (disposition && disposition.indexOf('attachment') !== -1) {
                const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                var matches = filenameRegex.exec(disposition);
                if (matches != null && matches[1]) { 
                    filename = matches[1].replace(/['"]/g, '');
                }
            }
            FileSaver.saveAs(new Blob([res.data], {type: res.headers['content-type']}), filename || "Dialog Reports.xlsx");
        })
        .catch(err => {
            error(err)
            return Promise.reject(err);
        })
    }
}

export function getAssetPages() {
    return function(dispatch) {
        dispatch({type: START_LOADING, payload: 'Checking for Assets'})
        return axios({
            method: 'POST',
            url: `${ROOT_URL}/admin/getassetpages`
        })
        .finally(() => dispatch({type: STOP_LOADING, payload: 'Checking for Assets'}))
        .then(res => {
            dispatch({type: SET_ASSET_PAGES, payload: res.data})
        })
        .catch(err => {
            error(err)
            return Promise.reject(err)
        })
    }
}
