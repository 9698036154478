import Excel from 'exceljs'
import FileSaver from 'file-saver'

function promiseReadBinaryFile(f) {
    return new Promise((resolve, reject) => {
        const fr = new FileReader()
        fr.onloadend = () => resolve(fr.result)
        fr.onabort = reject
        fr.onerror = reject
        fr.readAsArrayBuffer(f)
    })
}

const columns = {
    name: ['name', 'model'],
    additionalQuantity: ['additionalquantity', 'quantity', 'additional quantity'],
    photoUrl: ['v360','photourl'],
    stillPhotoUrl: ['photo', 'stillphotourl','still photo'],
    tier: ['tier'],
    labGrown: ['laggrown', 'lab grown'],
    hide: ['hide', 'hidden']
}

export async function parseModelFile(file) {
    const buffer = await promiseReadBinaryFile(file)
    const book = new Excel.Workbook()
    await book.xlsx.load(buffer)

    const models = []
    book.eachSheet(sheet => {
        let columnMap = null
        sheet.eachRow(row => {
            if (columnMap == null) {
                const cM = {}
                row.eachCell((cell, colNo) => {
                    for (const col in columns) {
                        if (columns[col].includes(cell.value?.toLowerCase?.())) {
                            cM[col] = colNo
                        }
                    }
                })
                if (cM.name && cM.additionalQuantity >= 0) columnMap = cM
            } else {
                const model = Object.entries(columnMap).reduce((mod, [key, colNo]) => ({...mod, [key]: row.getCell(colNo)?.text}), {})
                if ('labGrown' in model) {
                    if (model.labGrown && (model.labGrown.toLowerCase() === 'false' || model.labGrown === '0')) model.labGrown = false
                    else model.labGrown = Boolean(model.labGrown)
                }
                if ('hide' in model) {
                    if (model.hide && (model.hide.toLowerCase() === 'false' || model.hide === '0')) model.hide = false
                    else model.hide = Boolean(model.hide)
                }
                for (const key in model) {
                    if (model[key] === '') model[key] = null
                }
                if (model.name && model.additionalQuantity != null) models.push(model)
            }
        })
    })
    return models
}

export function exportToExcel(jwls, adminMode) {
    const workbook = new Excel.Workbook();
    workbook.creator = 'Dialog';
    workbook.created = new Date();
    const sheet = workbook.addWorksheet('Dialog Jewellery Inventory');
    let columns = [
        //{key: 'img', width: 5},
        {header: 'Model', key: 'name', width: 15},
        {header: 'Description', key: 'description', width: 30},
        {header: 'Type', key: 'type', width: 15},
        {header: 'Style', key: 'subType', width: 15},
        {header: 'Finish', key: 'finish', width: 15},
        {header: 'Diamond Type', key: 'labGrown', width: 8, excelCell: (lg) => lg ? 'Lab Grown' : 'Natural'},
        {header: 'Price', key: 'total', width: 12, style: {numFmt: '$#,##0.00'}}
    ]
    if (adminMode) {
        columns = columns.concat([
            {header: 'DX Quantity', key: 'availableQuantity', width: 8},
            {header: 'Additional Quantity', key: 'additionalQuantity', width: 8},
            {header: 'V360', key: 'photoUrl', width: 15},
            {header: 'Still Photo', key: 'stillPhotoUrl', width: 15},
            {header: 'Tier', key: 'tier', width: 12},
            {header: 'Hidden', key: 'hide', width: 7}
        ])
    } else {
        columns.push({header: 'On Hand', key: 'availableTotalQty', width: 8})
    }
    sheet.columns = columns;
    sheet.addRows(jwls.map(jwl => jwl._original));

    for (let col of columns.filter(c => c.excelCell)) {
        const fileColumn = sheet.getColumn(col.key);
        if (!fileColumn) continue;
        fileColumn.eachCell((cell, row) => {
            if (row > 1 && cell.value!=null) cell.value = col.excelCell(cell.value);
        })
    }

    // sheet.getColumn('img').eachCell(cell => {
    //     if (adminMode) cell.value = {formula: `_xlfn.IMAGE(L${cell.row})`}
    //     else {
    //         const photo = jwls[cell.row-2]?._original?.stillPhotoUrl
    //         if (photo) cell.value = {formula: `_xlfn.IMAGE("${photo}")`}
    //     }
    // })

    return workbook.xlsx.writeBuffer()
    .then(buffer => {
        FileSaver.saveAs(new Blob([buffer], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"}), `Dialog Export.xlsx`);
    })
}