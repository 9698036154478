import React, {Component} from 'react'
import {connect} from 'react-redux'
import ReactTable from 'react-table-6'
import selectTableHOC from 'react-table-6/lib/hoc/selectTable'
import NumberFormat from 'react-number-format'
import Modal from 'react-responsive-modal'
import * as actions from '../../actions'
import {TextFilter, NumberFilter, OptionsFilter, filterText, filterNumber, filterOptions, filterJewelleryCerts} from './filters'
import { getTotalsText } from './utils'

const SelectTable = selectTableHOC(ReactTable);

class JewelleryTable extends Component {

    tableRef = React.createRef();
    state = {embedOpen: null};

    static defaultProps = {
        data: [],
        extraColumns: [],
        extraTableProps: {},
        small: false
    }

    componentDidUpdate() {
        if (this.state.clearFilters) {
            this.setState({clearFilters: undefined})
            this.handleFilterChange([])
        }
    }

    getFilteredParcelNames = () => {
        if (!this.tableRef.current) return this.props.data.map(row => row.parcelName);
        if (this.props.onSelectRows && this.props.selectedRows) {
            const wrappedInstance = this.tableRef.current.getWrappedInstance();
            const currentRecords = wrappedInstance.getResolvedState().sortedData;
            return currentRecords.map(row => row.parcelName).filter(p => p);
        } else {
            const currentRecords = this.tableRef.current.getResolvedState().sortedData;
            return currentRecords.map(row => row.parcelName).filter(p => p);
        }
    }

    isSelected = (parcelName) => {
        return Boolean(this.props.selectedRows[parcelName]);
    }

    isSelectAll = () => {
        const fpn = this.getFilteredParcelNames();
        return Array.isArray(fpn) && fpn.length && fpn.every(parcelName => this.isSelected(parcelName));
    }

    toggleRow = (parcelName) => {
        if (parcelName.startsWith('select-')) parcelName = parcelName.slice(7);
        this.props.onSelectRows({...this.props.selectedRows, [parcelName]: !this.isSelected(parcelName)});
    }

    toggleAll = () => {
        const isa = this.isSelectAll();
        this.props.onSelectRows({...this.props.selectedRows, ...this.getFilteredParcelNames().reduce((obj,parcelName) => ({...obj, [parcelName]: !isa}), {})});
    }

    handleFilterChange = (filtered) => {
        if (this.props.tableId) {
            this.props.setSavedFilters(this.props.tableId, filtered)
        }
        if (this.props.onSelectRows && this.props.selectedRows) {
            this.refilterSelection()
        }
    }

    refilterSelection = () => {
        const fpn = this.getFilteredParcelNames();
        this.props.onSelectRows(Object.keys(this.props.selectedRows).reduce((newSelection,key) => {
            if (fpn.includes(key)) {
                if (this.props.selectedRows[key]===0) return {...newSelection, [key]: true};
                else return {...newSelection, [key]: this.props.selectedRows[key]};
            }
            else if (this.isSelected(key)) return {...newSelection, [key]: 0};
            else return newSelection;
        }, {}));
    }

    attributeSorter = (key) => ((a,b) => a.isFancy-b.isFancy || (a.isFancy ? (a[key]>b[key] ? 1 : a[key]<b[key] ? -1 : 0) : a.order-b.order))
    LabFilter = props => <OptionsFilter data={this.props.data} {...props} />
    DTypeFilter = props => <OptionsFilter options={['Natural', 'Lab Grown']} {...props} />

    getTableProps = () => {
        const pageSize = 15;
        return {
            data: this.state.virtualRow ? this.props.data.concat(this.state.virtualRow) : this.props.data,
            filterable: Array.isArray(this.props.data) && (this.props.data.length > 5 || (this.props.tableId && this.props.savedFilters[this.props.tableId])),
            defaultFiltered: this.props.tableId ? (this.props.savedFilters[this.props.tableId] || []) : [],
            ...(this.state.clearFilters ? {filtered: []} : {}),
            onFilteredChange: this.handleFilterChange,
            resizable: false,
            className: '-highlight',
            NoDataComponent: () => (<div style={{textAlign: 'center'}}>0 stones found</div>),
            defaultFilterMethod: this.defaultFilterMethod,
            minRows: 0,
            //showPagination: (this.props.data || []).length > pageSize ? true : false,
            showPagination: true,
            showPageSizeOptions: false,
            defaultPageSize: pageSize, 
            defaultSorted: [
                {id: "weight", desc: false},
            ],
            getTrProps: (state, row) => ({onClick: () => this.props.addJTab(row.original)}),
            columns: [
                {
                    Header: 'V360',
                    accessor: 'photoUrl',
                    width: 50,
                    sortable: false,
                    filterable: false,
                    className: 'list-photo-column',
                    Cell: (row) => row.value ? (
                        /*<a href={row.value} target='_blank' className='list-button' onClick={e => e.stopPropagation()}>
                            <i className="fas fa-camera" />
                        </a>*/
                        <button onClick={(e) => {
                            e.stopPropagation()
                            e.preventDefault()
                            this.setState({embedOpen: row.value})
                        }} className='list-button'>
                            <i className="fas fa-camera" />
                        </button>
                    ) : (
                        <span className='list-button disabled'>
                            <i className="fas fa-camera" />
                        </span>
                    ),
                    show: false
                },
                {
                    Header: 'Parcel Name', 
                    accessor: 'parcelName', 
                    minWidth: 100, 
                    //show: this.props.admin,
                    Cell: rowInfo => <span>{rowInfo.value}</span>
                },
                /*{
                    Header: 'Certs', 
                    accessor: 'stoneCerts', 
                    minWidth: 110, 
                    Filter: TextFilter, 
                    filterMethod: filterJewelleryCerts,
                    Cell: rowInfo => <div className='list-cell'>
                        {(rowInfo.value ?? []).map(stone => <span key={stone.certNo}>{stone.lab}&nbsp;{stone.certNo}</span>)}
                    </div>
                },
                {
                    Header: 'Model',
                    accessor: 'model',
                    minWidth: 160,
                    Filter: TextFilter,
                    filterMethod: filterText,
                    // Cell: rowInfo => <span className='capitalize'>{rowInfo.value?.toLowerCase()}</span>,
                    Cell: rowInfo => <span>{rowInfo.value}</span>
                },*/
                {
                    Header: 'Model',
                    accessor: 'modelDescription',
                    minWidth: 200,
                    Filter: TextFilter,
                    filterMethod: filterText,
                    Cell: rowInfo => <span>{rowInfo.value}</span>
                },
                {
                    Header: 'Type',
                    accessor: 'modelType',
                    minWidth: 80,
                    Filter: this.LabFilter,
                    filterMethod: filterOptions,
                    // Cell: rowInfo => <span className='capitalize'>{rowInfo.value?.toLowerCase()}</span>
                },
                {
                    Header: 'Style',
                    accessor: 'modelSubType',
                    minWidth: 80,
                    Filter: this.LabFilter,
                    filterMethod: filterOptions,
                    // Cell: rowInfo => <span className='capitalize'>{rowInfo.value?.toLowerCase()}</span>
                },
                {
                    Header: 'Finish',
                    accessor: 'modelFinish',
                    minWidth: 80,
                    Filter: this.LabFilter,
                    filterMethod: filterOptions,
                    // Cell: rowInfo => <span className='capitalize'>{rowInfo.value?.toLowerCase()}</span>
                },
                ...(this.props.lgAllowed ? [{
                    Header: 'Diamond Type',
                    id: 'labGrown',
                    accessor: (row) => row.labGrown ? 'Lab Grown' : 'Natural',
                    minWidth: 80,
                    Filter: this.DTypeFilter,
                    filterMethod: filterOptions
                }] : []),
                {
                    Header: 'Total Weight', 
                    accessor: 'weight', 
                    sortMethod: this.numericSort, 
                    minWidth: 60, 
                    Cell: rowInfo => {
                        if (rowInfo.original.virtual) return rowInfo.value;
                        else return <NumberFormat displayType='text' value={rowInfo.value} decimalScale={2} fixedDecimalScale={true} />
                    },
                    Filter: NumberFilter, 
                    filterMethod: filterNumber
                },
                ...(this.props.priceMode === 'NONE' ? [] : [{
                    Header: 'Total', 
                    accessor: 'total', 
                    Cell: (row => {
                        return <NumberFormat value={row.value} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true} />
                    }),
                    minWidth: 70,
                    Filter: NumberFilter,
                    filterMethod: filterNumber
                }]),
                ...this.props.extraColumns
            ],
            PaginationComponent: (props) => {
                return (
                    <div className='-pagination'>
                        {
                            this.props.exporter
                            ? <button 
                                className='list-button' 
                                disabled={props.sortedData.length===0}
                                onClick={() => this.props.exporter(
                                    props.sortedData.map(({_original, ...d}) => ({
                                        ..._original, 
                                        ...d,
                                        shapeName: (((this.props.shapeList || []).find(sh => sh.shapeCode==_original.shapeCode) || {})[_original.cut=='BL' ? 'blDescription' : 'description']) || _original.shapeCode
                                    })),
                                    this.props.extraColumns.filter(col => col.includeExcel),
                                    this.props
                                )}
                            >
                                <i className="fas fa-file-excel" />
                            </button>
                            : <div />
                        }
                        <button
                            className='list-button'
                            onClick={() => props.onPageChange(0)}
                            disabled={!props.canPrevious}
                        >
                            <i className="fas fa-angle-double-left" />
                        </button>
                        <button
                            className='list-button'
                            onClick={() => props.onPageChange(props.page-1)}
                            disabled={!props.canPrevious}
                        >
                            <i className="fas fa-angle-left" />
                        </button>
                        <div>
                            Page&nbsp;
                            <NumberFormat 
                                key='pageinput'
                                displayType='input' 
                                style={{width: '3rem', textAlign: 'center'}}
                                value={props.page+1} 
                                onBlur={(e) => props.onPageChange(Number(e.target.value)-1)}
                                onKeyUp={(e) => {
                                    if (e.key=='Enter') props.onPageChange(Number(e.target.value)-1)
                                }}
                            />
                            &nbsp;of {props.pages}
                        </div>
                        <button
                            className='list-button'
                            onClick={() => props.onPageChange(props.page+1)}
                            disabled={!props.canNext}
                        >
                            <i className="fas fa-angle-right" />
                        </button>
                        <button
                            className='list-button'
                            onClick={() => props.onPageChange(props.pages-1)}
                            disabled={!props.canNext}
                        >
                            <i className="fas fa-angle-double-right" />
                        </button>
                        <div>
                            <NumberFormat
                                displayType='input'
                                style={{width: '3rem', textAlign: 'center'}}
                                value={props.pageSize}
                                onBlur={(e) => props.onPageSizeChange(Number(e.target.value))}
                                onKeyUp={(e) => {
                                    if (e.key=='Enter') props.onPageSizeChange(Number(e.target.value))
                                }}
                            />
                            &nbsp;/page
                        </div>
                    </div>
                )
            },
            ...this.props.extraTableProps
        }
    }

    defaultFilterMethod = (filter, row, column) => {
        const rowVal = row[filter.pivotId || filter.id]
        if (column.altFilters) {
            let rowVals = [rowVal, ...column.altFilters.map(id => row[id] || (row._original && row._original[id]))];
            return rowVals.some(val => val !== undefined ? String(val).toLowerCase().includes(String(filter.value).toLowerCase()) : false)
                || rowVals.every(val => val===undefined);
        } else {
            return rowVal !== undefined ? String(rowVal).toLowerCase().includes(String(filter.value).toLowerCase()) : true
        }
    }

    getTableAmounts = () => {
        const filteredParcels = new Set(this.getFilteredParcelNames())
        return this.props.data.reduce((tots, stone) => {
            tots.totalCount++
            tots.totalValue+= stone.total
            if (filteredParcels.has(stone.parcelName)) {
                tots.filteredCount++
                tots.filteredValue += stone.total
                if (this.props.selectedRows && this.isSelected(stone.parcelName)) {
                    tots.selectedCount++
                    tots.selectedValue += stone.total
                }
            }
            return tots
        }, {totalCount: 0, totalValue: 0, filteredCount: 0, filteredValue: 0, selectedCount: 0, selectedValue: 0})
    }

    numericSort = (a,b) => {
        a = Number(a); b = Number(b);
        return a-b;
    }

    render() {
        const tableAmounts = this.getTableAmounts()
        return <div style={{position: 'relative'}}>
            <div className='tabletop-buttons'>
                <div className='table-contents-header'>
                    <span>
                        {tableAmounts.selectedCount ? getTotalsText(tableAmounts.selectedCount, tableAmounts.selectedValue) + ' selected. ' : ''}
                    </span>
                    <span>
                        Showing {tableAmounts.filteredCount === tableAmounts.totalCount ? 'all' : getTotalsText(tableAmounts.filteredCount, tableAmounts.filteredValue)} of
                        {' '}
                        {getTotalsText(tableAmounts.totalCount, tableAmounts.totalValue)}
                    </span>
                </div>
                <button 
                    className='btn' 
                    style={{
                        display: this.props.savedFilters[this.props.tableId] && this.props.savedFilters[this.props.tableId].length ? 'block' : 'none',
                        marginRight: '8px',
                        marginBottom: '1.2px'
                    }} 
                    onClick={() => this.setState({clearFilters: true})}
                >
                    Clear Filters
                    {/*<span className="fa-stack" style={{fontSize: 'smaller'}}>
                        <i className="fas fa-ban fa-stack-1x"/>
                        <i className="fas fa-filter fa-stack-1x" style={{fontSize: 'smaller'}}/>
                    </span>*/}
                </button>
            </div>
            {
                this.props.onSelectRows && this.props.selectedRows ? 
                <SelectTable
                    isSelected={this.isSelected}
                    selectAll={this.isSelectAll()}
                    toggleAll={this.toggleAll}
                    toggleSelection={this.toggleRow}
                    keyField='parcelName'
                    selectType='checkbox'
                    ref={this.tableRef}
                    {...this.getTableProps()}
                /> :
                <ReactTable
                    ref={this.tableRef}
                    {...this.getTableProps()}
                />
            }
            <Modal
                open={!!this.state.embedOpen} 
                onClose={() => this.setState({embedOpen: null})}
                showCloseIcon={true}
                styles={{modal: {padding: '3rem 3rem 2rem 3rem'}}}
                focusTrapped={false}
                center
            >
                <div className={`modal-video-container${this.state.embedOpen?.toLowerCase()?.includes('vision360.html') ? '' : (this.state.embedOpen?.toLowerCase()?.includes('dnadiamond.net') ? '-dnad' : '-old')}`} >
                    <iframe 
                        src={this.state.embedOpen} 
                        scrolling='no'
                        className={`modal-video-iframe${this.state.embedOpen?.toLowerCase()?.includes('vision360.html') ? '' : (this.state.embedOpen?.toLowerCase()?.includes('dnadiamond.net') ? '-dnad' : '-old')}`}
                    />
                </div>
            </Modal>
        </div>
    }
}

function mapStateToProps(state) {
    return {
        admin: state.user && state.user.currentUser ? state.user.currentUser.administrator : false,
        priceMode: state.user && state.user.currentUser ? state.user.currentUser.showPrices : 'NONE',
        savedFilters: state.tabs.savedFilters || {},
        lgAllowed: state.user?.customer?.lgAllowed
    }
}

export default connect(mapStateToProps, actions, null, {forwardRef: true})(JewelleryTable);