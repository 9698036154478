import React from "react";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }
  
    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidUpdate(prevProps) {
        if (this.state.hasError && prevProps.children !== this.props.children) this.setState({hasError: false})
    }
  
    render() {
        if (this.state.hasError) {
            return <>
                <h2>There was an error displaying this page.</h2>
                <h3>We're sorry for the inconvenience.</h3>
            </>
        }
    
        return this.props.children; 
    }
}

export default ErrorBoundary