import React, {useEffect, useState, useMemo} from 'react'
import {connect} from 'react-redux'
import * as actions from '../../actions'
import { getBinRanges, parseCrosstabs, normalColumnOrder, saveFlatIdeal } from './ideal_utils'

function IdealUpload({customer, ...props}) {

    const binTemplates = useMemo(() => props.binTemplates.filter(tmpl => tmpl.active && tmpl.customerId === customer.customerId), [props.binTemplates])

    const [selectedFile, setSelectedFile] = useState(null)
    const [crosstabFile, setCrosstabFile] = useState(null)
    const handleFileChange = (e) => {
        const target = e.currentTarget || e.target
        if (target.name === 'idealInventory') {
            if (target.files.length && target.files[0]) setSelectedFile(target.files[0])
            else setSelectedFile(null)
        } else if (target.name === 'crosstabInventory') {
            if (target.files.length && target.files[0]) setCrosstabFile(target.files[0])
            else setCrosstabFile(null)
        }
    }

    const [templateName, setTemplateName]= useState(null)
    const handleTemplateNameChange = (e) => {
        const target = e.currentTarget || e.target
        setTemplateName(target.value || null)
    }
    useEffect(() => {
        if (templateName == null && binTemplates) {
            const tmplNames = binTemplates.filter(tmp => tmp.active && tmp.idealInventoryName).reduce((set,tmp) => set.add(tmp.idealInventoryName), new Set())
            if (tmplNames.size === 1) setTemplateName([...tmplNames][0])
        }
    }, [binTemplates])

    const submitIdealInventory = () => {
        new Promise((resolve,reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result);
            reader.onerror = (err) => reject(err);
            reader.readAsDataURL(selectedFile);
        })
        .then(dataUrl => {
            let splitString = dataUrl.split(/[\:\,]/);
            let data = splitString[splitString.length-1];
            props.loadIdealInventory({
                customerId: customer.customerId,
                templateName,
                excelBody: data
            }).then(() => {
                setSelectedFile(null)
                props.getBinTemplates()
            })
        })
    }

    const [flattenedCrosstabs, setFlattenedCrosstabs] = useState(null)
    const convertCrosstabInventory = () => {
        parseCrosstabs(crosstabFile, props.attributes)
        .then(parsedData => {
            setFlattenedCrosstabs(parsedData)
            setCrosstabFile(null)
        })
    }
    const saveFlattened = () => {
        saveFlatIdeal(flattenedCrosstabs)
    }

    const [viewTemplateId, setViewTemplateId] = useState('')
    const changeViewTemplateId = (e) => {
        setViewTemplateId(e.target.value)
    }

    useEffect(() => {
        if (viewTemplateId) {
            props.getTemplateBins(viewTemplateId)
            return props.clearTemplateBins
        }
    }, [viewTemplateId])

    const {attributes, neededValues: idealInventory} = useMemo(() => {
        if (!viewTemplateId || !props.templateBins[viewTemplateId]) return {}
        const template = binTemplates.find(tmp => tmp.id == viewTemplateId)
        const bins =  props.templateBins[viewTemplateId] ?? []
        return getBinRanges(template, bins)
    }, [viewTemplateId, props.templateBins])

    const binMap = useMemo(() => {
        const map = new Map()
        for (const bin of (props.templateBins[viewTemplateId] ?? [])) {
            map.set(`{${bin.storeId}}|${bin.binSpec}`, bin)
        }
        return map;
    }, [viewTemplateId, props.templateBins])

    const showBinValue = bin => {
        if (!bin) return 0
        let res = '0'
        if (bin.minQty!=null && bin.maxQty!=null) res = `${bin.minQty} - ${bin.maxQty}`
        else if (bin.targetQty!=null) res = bin.targetQty
        if (bin.targetValue!=null) res = `${res} (${bin.targetValue})`
        return res
    }

    const renderCrosstabs = (attributes, opts, prefix) => {
        switch (attributes.length) {
            case 0: return null
            case 1: return (
                <div className='reassort_ideal--list'>{
                    opts[attributes[0]].map(opt => <React.Fragment key={opt}>
                        <div>{opt}</div><div>{showBinValue(binMap?.get(prefix ? `${prefix}|{${opt}}` : `{${opt}}`))}</div>
                    </React.Fragment>)
                }</div>
            )
            case 2: 
                const [vAtt, hAtt, swap] = opts[attributes[0]].length > opts[attributes[1]].length ? [0, 1, false] : [1, 0, true]
                return (
                    <div className='reassort_ideal--wccgrid'>
                        {opts[attributes[hAtt]].map((opt1, i) => {
                            const displayOpt = attributes[0]==='storeId' ? props.stores?.find(st => st.storeId==opt1)?.storeName ?? opt1 : opt1
                            return <div key={opt1} className='reassort_ideal--upperheader' style={{gridColumnStart: i+2, gridColumnEnd: 'span 1', borderLeft: i===0 ? '1px solid' : undefined}}>{displayOpt}</div>
                        })}
                        {opts[attributes[vAtt]].map((opt0, r) => {
                            const displayOpt = attributes[0]==='storeId' ? props.stores?.find(st => st.storeId==opt0)?.storeName ?? opt0 : opt0
                            return <React.Fragment key={opt0}>
                                <div className='reassort_ideal--colheader' style={{gridRowStart: r+2, borderTop: r===0 ? '1px solid' : undefined}}>{displayOpt}</div>
                                {opts[attributes[hAtt]].map((opt1, i) => (
                                    <div key={i} className='reassort_ideal--cell' style={{gridRowStart: r+2, gridColumnStart: i+2}}>
                                        {showBinValue(binMap?.get((prefix ? `${prefix}|` : '') + `{${swap ? opt1 : opt0}}|{${swap ? opt0 : opt1}}`))}
                                    </div>
                                ))}
                            </React.Fragment>
                        })}
                    </div>
                )
            case 3: 
                return <div className='reassort_ideal--wccgrid'>
                    {opts[attributes[2]].map((opt2, j) => <React.Fragment key={opt2}>
                        <div className='reassort_ideal--upperheader' style={{gridColumnStart: j*opts[attributes[1]].length + 2, gridColumnEnd: `span ${opts[attributes[1]].length}`, borderLeft: j===0 ? '1px solid' : undefined}}>{opt2}</div>
                        {opts[attributes[1]].map((opt1, i) => <div key={opt1} className='reassort_ideal--lowerheader' style={{gridColumnStart: i + j*opts[attributes[1]].length + 2, borderLeft: (i===0 && j===0) ? '1px solid' : undefined}}>{opt1}</div>)}
                    </React.Fragment>)}
                    {opts[attributes[0]].map((opt0, r) => <React.Fragment key={opt0}>
                        <div className='reassort_ideal--colheader' style={{gridRowStart: r+3, borderTop: r===0 ? '1px solid' : undefined}}>{opt0}</div>
                        {opts[attributes[1]].map((opt1, i) => (
                            opts[attributes[2]].map((opt2, j) => (
                                <div key={`${i}|${j}`} className='reassort_ideal--cell' style={{gridRowStart: r+3, gridColumnStart: i + j*opts[attributes[1]].length + 2}}>
                                    {showBinValue(binMap?.get((prefix ? `${prefix}|` : '') + `{${opt0}}|{${opt1}}|{${opt2}}`))}
                                </div>
                            ))
                        ))}
                    </React.Fragment>)}
                </div>
            default: 
                const nextLevel = attributes.slice(1)
                return opts[attributes[0]].map(option => {
                    const displayOpt = attributes[0]==='storeId' ? props.stores?.find(st => st.storeId==option)?.storeName ?? option : option
                    return <div key={option}>
                        <span>{displayOpt}</span>
                        <div className='reassort_ideal--group'>
                            {renderCrosstabs(nextLevel, opts, prefix ? `${prefix}|{${option}}` : `{${option}}`)}
                        </div>
                    </div>
                })
        }
    }

    const renderFlat = (bins) => {
        const columns = normalColumnOrder.filter(col => bins.some(bin => bin[col.key]))
        return (
            <div className='reassort_page--ideal_table' style={{gridTemplateColumns: `repeat(${columns.length}, auto)`}}>
                {columns.map(col => <div key={col.key} className='reassort_page--ideal_flat_header'>{col.header}</div>)}
                {bins.map((bin, i) => <React.Fragment key={i}>
                    {columns.map(col => <div key={col.key} className='reassort_page--ideal_flat_cell'>{bin[col.key]}</div>)}
                </React.Fragment>)}
            </div>
        )
    }

    return  (
        <div className='reassort_page--ideal'>
            <div className='row spaced'>
                <div>Read Ideal Inventory (Crosstabs)</div>
                <input name='crosstabInventory' type='file' onChange={handleFileChange} disabled={!props.isAdmin} value={crosstabFile==null ? '' : undefined} />
                <button className='btn' onClick={convertCrosstabInventory} disabled={!crosstabFile || !props.isAdmin}>Read</button>
            </div>
            {flattenedCrosstabs?.length ? (
                <div className='reassort_page--ideal_flat'>
                    <div className='row spaced space-contents'>
                        <button className='btn' onClick={saveFlattened}>Save to Excel</button>
                        <button className='btn btn-secondary' onClick={() => setFlattenedCrosstabs(null)}>Close</button>
                    </div>
                    {renderFlat(flattenedCrosstabs)}
                </div>
            ) : null}
            <div className='row spaced'>
                <div>Upload Ideal Inventory (Flat)</div>
                <input name='idealInventory' type='file' onChange={handleFileChange} disabled={!props.isAdmin} value={selectedFile==null ? '' : undefined} />
                <input name='idealInventoryName' onChange={handleTemplateNameChange} disabled={!props.isAdmin} value={templateName ?? ''} placeholder="Nickname" style={{marginRight: '2rem'}} />
                <button className='btn' onClick={submitIdealInventory} disabled={!selectedFile || !props.isAdmin}>Submit</button>
            </div>
            <div className='row spaced'>
                <div>View Ideal Inventory</div>
                <select value={viewTemplateId} onChange={changeViewTemplateId}>
                    <option value=''>View Ideal Inventory...</option>
                    {binTemplates.map(t => <option key={t.id} value={t.id}>{t.id}{t.idealInventoryName ? ' | ' + t.idealInventoryName : ''} | {t.templateString}</option>)}
                </select>
            </div>
            {attributes?.length && Object.keys(idealInventory)?.length ? (
                <div className='reassort_page--ideal_crosstabs'>
                    {renderCrosstabs(attributes, idealInventory, '')}
                </div>
            ) : null}
            {idealInventory?.length ? (
                <div className='reassort_page--ideal_crosstabs'> {
                    idealInventory.map(bin => <div>{bin.targetQty}</div>)
                } </div>
            ) : null}
        </div>
    )
}

function mapStateToProps(state) {
    return {
        customer: state.user.customer,
        binTemplates: state.admin.binTemplates || [],
        templateBins: state.admin.idealBins || {},
        stores: state.admin.stores || [],
        attributes: state.attributes,
        isAdmin: Boolean(state.user?.currentUser?.administrator)
    }
}

export default connect(mapStateToProps, actions)(IdealUpload)