import React, {Component} from 'react'
import {connect} from 'react-redux'
import Modal from 'react-responsive-modal'
import * as actions from '../../actions'
import {success, error} from '../common/toast'
import moment from 'moment-timezone'
import StoneTable, {formatStatus} from '../common/stone_table'
import ReplenishInventory from '../inventory/replenish'
import {OptionsFilter, filterOptions, NumberFilter, filterNumber, DateFilter, filterDate} from '../common/filters';
import InvoiceModal from '../customer/invoice_modal'

class PastInventory extends Component {

    state = {
        billDownloadSelection: '',
        replenishStone: null,
        availableLoaded: false,
        showInvoices: false
    }

    componentDidMount() {
        this.props.getPastInventory()
    }

    viewReplacements = (stone) => {
        if (!this.state.availableLoaded) this.props.getAvailableInventory();
        this.setState({replenishStone: stone, availableLoaded: true})
    }

    StatusFilter = props => <OptionsFilter data={this.props.pastInventory || []} {...props} />

    render() {
        return (
            <>
                <div className='row spaced'>
                    <StoneTable 
                        data={this.props.pastInventory} 
                        extraColumns={[
                            {
                                Header: 'Status', 
                                id: 'status', 
                                accessor: formatStatus,
                                minWidth: 80,
                                Filter: this.StatusFilter, 
                                filterMethod: filterOptions,
                            },
                            {
                                Header: 'Last Action',
                                accessor: 'lastAction',
                                minWidth: 70,
                                Cell: rowInfo => <span>{moment(rowInfo.value).format('YYYY-MM-DD')}</span>,
                                includeExcel: true,
                                excelCell: intDate => moment(intDate).format('YYYY-MM-DD'),
                                Filter: DateFilter,
                                filterMethod: filterDate
                            },
                            /*{
                                Header: 'Days On Hand',
                                accessor: 'daysOnHand',
                                minWidth: 80,
                                Filter: NumberFilter,
                                filterMethod: filterNumber,
                                includeExcel: true
                            },*/
                            {
                                Header: 'Refill',
                                accessor: 'replace',
                                width: 50,
                                sortable: false,
                                filterable: false,
                                Cell: (row) => row.original ? (
                                    <button
                                        title="View Replacements"
                                        className="list-button"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            this.viewReplacements(row.original);
                                        }}
                                    >
                                        <i className='fas fa-exchange-alt' />
                                    </button>
                                ) : null
                            }
                        ]}
                        exporter={this.props.excelExport}
                        tableId='previous'
                    />
                </div>
                <Modal 
                    open={!!this.state.replenishStone} 
                    onClose={() => this.setState({replenishStone: null})} 
                    styles={{modal: {width: '90%', padding: '1rem'}}}
                    center
                >
                    <ReplenishInventory stone={this.state.replenishStone} />
                </Modal>
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        customer: state.user.customer,
        pastInventory: state.inventory.past
    }
}

export default connect(mapStateToProps, actions)(PastInventory);