export const tierList = [
    {value: 'Reserve', description: 'Dialog Reserve', infoTip: 'Dialog\'s highest tier and premium brand, quality controlled to the highest standards. In Dialog Reserve, we deliver only the very top tier of diamonds, where the diamond\'s actual appearance is unassailable compared to its grading certificate.'},
    {value: 'Essentials', description: 'Dialog Essentials', infoTip: 'Dialog\'s commercial product tier \u2013 for the every day diamonds essential to all retailers.  Essentials is quality controlled to very strict standards; however, we allow slightly greater tolerances that allow Dialog to deliver diamonds that are more competitively priced.'},
    {value: 'Disons', description: 'Dialog by Disons', infoTip: 'This diamond is owned by Dison\'s Gems. It is available on Dialog with the same terms and at the same price if purchased direct. Dialog now provides you with three tiers of product to service all your diamond merchandising needs.'},
    //{value: 'Fancy Color', description: 'Fancy Color', infoTip: 'Dialog\'s fancy coloured diamond line.'},
    //{value: 'Unspecified', description: 'Unspecified', infoTip: 'Diamonds where no tier has been specified. These are typically special orders.'},
    {value: 'Clarity', description: 'Clarity Diamond', infoTip: 'Clarity diamonds offer the best in all areas: cutting, polishing, color, clarity, certification. These are the highest class of artfully engineered diamonds \u2013 American-grown, GIA certified, and cut to perfection. Clarity diamonds are meticulously crafted and undergo a stringent quality assurance process.'},
    {value: 'Unbound', description: 'Unbound by Clarity', infoTip: 'Unbound by Clarity Diamond\u2122 allows us to go beyond the Clarity Diamond\u2122 parameters with slightly eased color, clarity, and cut thresholds. Staying true to the Clarity name, the Unbound diamonds are American-grown and GIA dossier certified.'},
    {value: 'Freedom', description: 'Dialog Freedom', infoTip: 'Your own diamond, invoiced to Dialog and redeployed to you as your Dialog inventory.'},
    {value: ''}
]