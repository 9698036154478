import React, {useMemo} from 'react'
import Excel from 'exceljs'
import FileSaver from 'file-saver'
import { templateSplitter, normalColumnOrder } from './ideal_utils'

const shipColumns = [
    {key: 'customerStoneId', header: 'Stone ID'},
    {key: 'lab', header: 'Lab'},
    {key: 'certNo', header: 'Cert'},
    {key: 'weight', header: 'Weight'},
    {key: 'shape', header: 'Shape'},
    {key: 'colour', header: 'Colour'},
    {key: 'clarity', header: 'Clarity'}
]

function ReassortResults({reassortOutput, binTemplate}) {
    const twoDecimals = x => Number(x).toFixed(2)

    const reassortOut = useMemo(() => {
        if (!reassortOutput) return reassortOutput
        return {
            ...reassortOutput, 
            suggestedAdditions: reassortOutput.suggestedAdditions.map(({stone, ...addition}) => ({...addition, ...stone}))
            // suggestedAdditions: Object.entries(reassortOutput.suggestedAdditions).reduce((arr, [binSpec, stones]) => ([
            //     ...arr, 
            //     ...stones.map(stone => ({...stone, forBin: binSpec}))
            // ]), [])
        }
    }, [reassortOutput])

    const displayShipColumns = useMemo(() => {
        return shipColumns.filter(col => reassortOut?.shipments?.some(ship => ship?.stones.some(row => row[col.key]!=null)))
    }, [reassortOut?.shipments])

    const templateColumns = useMemo(() => {
        if (!binTemplate) return []
        const attributes = [...binTemplate.templateString.matchAll(templateSplitter)].map(res => res[1])
        return normalColumnOrder.filter(col => attributes.some(attr => attr === col.key)).concat(attributes.filter(attr => !normalColumnOrder.some(col => col.key === attr)).map(attr => ({key: attr, header: `${attr.slice(0,1).toUpperCase()}${attr.slice(1)}`})))
    }, [binTemplate])

    function exportExcel() {
        const workbook = new Excel.Workbook();
        workbook.creator = 'Dialog';
        workbook.created = new Date();

        if (reassortOut.shipments?.length) {
            const sheet = workbook.addWorksheet('Suggested Shipments')
            let columns = [
                {header: 'From', key: 'fromStore', width: 19},
                {header: 'To', key: 'toStore', width: 19},
                {header: 'Stone ID', key: 'customerStoneId', width: 19},
                {header: 'Lab', key: 'lab', width: 7},
                {header: 'Cert', key: 'certNo', width: 12},
                {header: 'Weight', key: 'weight', width: 6},
                {header: 'Shape', key: 'shape', width: 7},
                {header: 'Colour', key: 'colour', width: 8},
                {header: 'Clarity', key: 'clarity', width: 8}
            ]
            columns = columns.filter(col => displayShipColumns.some(dCol => dCol.key === col.key) || !shipColumns.some(sCol => sCol.key === col.key))
            sheet.columns = columns
            sheet.addRows(reassortOut.shipments.reduce((arr,ship) => [...arr, ...ship.stones.map(stone => ({
                ...stone, fromStore: ship.fromStoreName, toStore: ship.toStoreName
            }))], []))
            sheet.views = [{ state: 'frozen', ySplit: 1 }]
        }

        if (reassortOut.suggestedAdditions?.length) {
            const sheet = workbook.addWorksheet('Suggested Dialog Stones')
            let columns = [
                {header: 'For Store', key: 'storeName', width: 19},
                {header: 'Stone ID', key: 'parcelName', width: 19},
                {header: 'Lab', key: 'lab', width: 7},
                {header: 'Cert', key: 'certNo', width: 12},
                {header: 'Weight', key: 'weight', width: 6},
                {header: 'Shape', key: 'shapeCode', width: 7},
                {header: 'Colour', key: 'colour', width: 8},
                {header: 'Clarity', key: 'clarity', width: 8},
                {header: 'Value', key: 'total', width: 9},
                {header: 'Max Inventory', key: 'underLimit', width: 9},
                {header: 'Match Type', key: 'matchType', width: 12},
                {header: 'For Bin', key: 'binSpec', width: 25}
            ]
            sheet.columns = columns
            sheet.addRows(reassortOut.suggestedAdditions.map(
                ({stone, ...addition}) => ({underLimit: addition.overLimit ? 'Over' : 'OK', matchType: addition.exactMatch ? 'In Bin' : 'Close to', ...addition, ...stone})
            ).sort((a,b) => {
                let diff = a.overLimit - b.overLimit
                if (!diff) diff = a.storeName.localeCompare(b.storeName)
                if (!diff) diff = a.binSpec.localeCompare(b.binSpec)
                if (!diff) diff = a.parcelName.localeCompare(b.parcelName)
                return diff
            }))
            sheet.views = [{ state: 'frozen', ySplit: 1 }]
        }

        if (reassortOut.suggestedRemovals?.length) {
            const sheet = workbook.addWorksheet('Redundant Inventory')
            const columns = [
                {header: 'In Store', key: 'storeName', width: 19},
                ...templateColumns.map(col => ({...col, width: 20})),
                {header: 'Quantity', key: 'quantity', width: 15}
            ]
            sheet.columns = columns
            sheet.addRows(reassortOut.suggestedRemovals.map(item => {
                const attributes = [...item.binSpec.matchAll(templateSplitter)].map(res => res[1])
                return attributes.reduce((obj,val,idx) => ({...obj, [templateColumns[idx].key]: val}), {...item})
            }).sort((a,b) => (a.storeName ?? '').localeCompare(b.storeName ?? '') || a.binSpec.localeCompare(b.binSpec)))
            sheet.views = [{ state: 'frozen', ySplit: 1 }]
        }

        return workbook.xlsx.writeBuffer()
        .then(buffer => {
            FileSaver.saveAs(new Blob([buffer], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"}), `Dialog Suggested Shipments.xlsx`);
        })
    }

    if (!reassortOut) return null
    else return <>
        <div className='reassort-results'>
            {reassortOut.shipments.map(ship => <div key={`${ship.fromStoreId}-${ship.toStoreId}`} className='reassort-shipment'>
                <div className='reassort-ship-header'>{ship.fromStoreName} &rarr; {ship.toStoreName}</div>
                {/* <div className='reassort-ship-stone'> */}
                    {
                        shipColumns.map(col => {
                            const disp = displayShipColumns.includes(col)
                            return <span key={col.key} className={'bold'+(disp ? '' : ' no-margin')}>{disp ? col.header : ''}</span>
                        })
                    }
                {/* </div> */}
                {ship.stones.map((stone,idx) => <React.Fragment key={idx}>
                    {/* <div className='reassort-ship-stone'> */}
                    {
                        shipColumns.map(col => {
                            const disp = displayShipColumns.includes(col)
                            return <span key={col.key} className={disp ? '' : 'no-margin'}>{disp ? (col.key==='weight' ? twoDecimals(stone.weight) : stone[col.key]) : ''}</span>
                        })
                    }
                    {/* </div> */}
                </React.Fragment>)}
            </div>)}
            {reassortOut.suggestedAdditions?.length ?
                <div className='reassort-additions'>
                    <div className='reassort-additions-header'>Additional Dialog Stones</div>
                    <span className='bold'>Stone ID</span>
                    <span className='bold'>Lab</span>
                    <span className='bold'>Cert</span>
                    <span className='bold'>Weight</span>
                    <span className='bold'>Shape</span>
                    <span className='bold'>Colour</span>
                    <span className='bold'>Clarity</span>
                    <span className='bold'>Value</span>
                    {reassortOut.suggestedAdditions.map(stone => <React.Fragment key={stone.parcelName}>
                        <span>{stone.parcelName}</span>
                        <span>{stone.lab}</span>
                        <span>{stone.certNo}</span>
                        <span>{twoDecimals(stone.weight)}</span>
                        <span>{stone.shapeCode}</span>
                        <span>{stone.colour}</span>
                        <span>{stone.clarity}</span>
                        <span>{twoDecimals(stone.total)}</span>
                    </React.Fragment>)}
                </div>
            : null}
            {reassortOut.suggestedRemovals?.length ? 
                <div className='reassort-removals' style={{gridTemplateColumns: `repeat(${(templateColumns?.length ?? 0) + 2} auto)`}}>
                    <div className='reassort-removals-header' style={{gridColumnEnd: (templateColumns?.length ?? 0) + 3}}>Inventory to Reduce</div>
                    <span className='bold'>Store</span>
                    {templateColumns?.map(col => <React.Fragment key={col.key}><span className='bold'>{col.header}</span></React.Fragment>)}
                    <span className='bold'>Quantity</span>
                    {reassortOut.suggestedRemovals.map((item,idx) => <React.Fragment key={idx}>
                        <span>{item.storeName}</span>
                        {[...item.binSpec.matchAll(templateSplitter)].map(res => res[1]).map(col => <span>{col}</span>)}
                        <span>{item.quantity}</span>
                    </React.Fragment>)}
                </div>
            : null}
        </div>
        <button className='list-button' onClick={exportExcel}><i className="fas fa-file-excel" /></button>
        {/*<button className='btn btn-secondary' onClick={handleClose}>Close</button>*/}
    </>
}

export default ReassortResults