import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import queryString from 'query-string'
import * as actions from '../../actions'
import Loading from '../common/loading'

function Referral(props) {
    const [state, setState] = useState({})

    useEffect(() => {
        if (props.location.search) {
            const parsed = queryString.parse(props.location.search)
            setState({...state, gifterName: parsed.name, gifterEmail: parsed.email})
        }
    }, [props.location.search])

    const handleChange = (e) => {
        setState({...state, [e.target.name]: e.target.value});
    }

    const submitForm = (e) => {
        e.preventDefault()
        props.makeReferral(state)
        .then(() => {
            window.top.location.href = '/'
        })
    }

    return (
        <div className='referral-form'>
            <div className='referral-text-block'>
                <div className='referral-panel'>
                    <div className='input-block'>
                        <label>Your Name</label>
                        <input name='gifterName' className='login-control' value={state.gifterName ?? ''} onChange={handleChange} placeholder='Your Name' />
                    </div>
                    <div className='input-block'>
                        <label>Your Email Address</label>
                        <input name='gifterEmail' className='login-control' value={state.gifterEmail ?? ''} onChange={handleChange} placeholder='Your Email Address' />
                    </div>
                    <div className='input-block'>
                        <label>Your Friend's Name</label>
                        <input name='recipientName' className='login-control' value={state.recipientName ?? ''} onChange={handleChange} placeholder='Your Friend&#39;s Name' />
                    </div>
                    <div className='input-block'>
                        <label>Their Email Address</label>
                        <input name='recipientEmail' className='login-control' value={state.recipientEmail ?? ''} onChange={handleChange} placeholder='Their Email Address' />
                    </div>
                    <button className='btn btn-primary login-control' style={{width: 'fit-content'}} onClick={submitForm}>Submit</button>
                </div>
            </div>
            <Loading />
        </div>
    )
}

export default connect(null, actions)(Referral)