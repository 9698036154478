import userReducer from './user'
import authReducer from './auth'
import tabReducer from './tabs'
import inventoryReducer from './inventory'
import jewelleryReducer from './jewellery'
import labGrownReducer from './labGrown'
import attributeReducer from './attributes'
import adminReducer from './admin'
import billingReducer from './billing'

const rootReducer = {
    user: userReducer,
    auth: authReducer,
    tabs: tabReducer,
    inventory: inventoryReducer,
    jewellery: jewelleryReducer,
    labGrown: labGrownReducer,
    attributes: attributeReducer,
    admin: adminReducer,
    billing: billingReducer
}

export default rootReducer;