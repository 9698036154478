import React from "react";
import { connect } from "react-redux";

function AssetFileFrame({assetFilePath}) {
    return <iframe className='embedded-html-iframe' src={assetFilePath} />
}

function mapStateToProps(state) {
    return {
        assetFilePath: state.tabs?.embeddedFilePath
    }
}

export default connect(mapStateToProps)(AssetFileFrame)