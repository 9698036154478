import React, {Component} from 'react'
import {connect} from 'react-redux'
import moment from 'moment-timezone'
import NumberFormat from 'react-number-format'
import * as actions from '../../actions'
import {history_type_names} from '../../assets/codes'
import ConfirmModal from '../common/confirm_modal'
import ConfirmStonesModal from '../common/confirm_stones_modal'
import {getInscriptionNo} from '../../utils'
import {error} from '../common/toast'
import SimilarStones from '../inventory/similarStones'
import CertModal from '../common/cert_modal'
import { tierList } from '../common/tierList'

const dateFormat = 'MMM DD, YYYY h:mm A'
const deletableTypes = ['REQUEST','RETURN','SOLD']

class LabGrownDetails extends Component {

    state = {
        remarks: null,
        dateChange: null,
        certOpen: null,
        pairsOpen: false
    }

    componentDidMount() {
        this.props.getLabGrownDetails(this.props.parcelName)
    }

    componentDidUpdate(prevProps) {
        if (this.props.parcelName!==prevProps.parcelName) {
            if (this.props.parcelname) this.props.getLabGrownDetails(this.props.parcelName)
            this.setState({certOpen: null})
        }
    }

    renderCertNo = (lab, cert) => {
        if (lab=='GIA' || lab=='GIA-LG') return <>
            <a href={`https://www.gia.edu/report-check?reportno=${cert}`} target='_blank'>{cert}</a>
            &nbsp;
            <i onClick={this.showCert} className='fas fa-external-link-alt list-button'/>
        </>
        else if (lab === 'FM' && getInscriptionNo(lab, cert)) {
            const inscriptionNo = getInscriptionNo(lab, cert)
            return <>
                <a href={`https://institute.debeers.com/en-gb/report/${inscriptionNo}`} target='_blank'>{cert}</a>
                &nbsp;
                <i onClick={this.showCert} className='fas fa-external-link-alt list-button'/>
            </>
        } else return cert;
    }
    showCert = async () => {
        if (this.props.stone?.lab === 'GIA' || this.props.stone?.lab === 'GIA-LG') {
            this.props.getLabGrownCert(this.props.parcelName)
            .then(certData => {
                this.setState({certOpen: certData})
            })
        } else if (this.props.stone?.lab === 'FM') {
            const foundUrl = await this.props.fmCertExists(this.props.stone?.shortCertNo)
            if (foundUrl) {
                this.setState({certOpen: {pdfUrl: foundUrl}})
            } else {
                this.props.getLabGrownCert(this.props.parcelName)
                .then(certData => {
                    this.setState({certOpen: certData})
                })
            }
        }
        this.setState({pdfOpen: true})
    }

    deleteHistory = (historyRow) => {
        return this.props.deleteLastLabGrownHistory(historyRow)
        .then(() => this.props.getLabGrownDetails(this.props.parcelName))
    }
    backdateHistory = (historyRow) => {
        if (moment(this.state.dateChange).isValid()) {
            return this.props.backdateLabGrownHistory(historyRow, moment(this.state.dateChange).toISOString())
            .then(() => {
                this.setState({dateChange: null})
                return this.props.getLabGrownDetails(this.props.parcelName)
            })
        } else {
            return Promise.reject(error("Not a valid date."));
        }
    }

    requestStone = () => {
        return this.props.requestLabGrown([this.props.parcelName], this.state.remarks)
        .then(() => this.setState({remarks: null}))
        .then(() => this.props.getLabGrownDetails(this.props.parcelName))
    }
    returnStone = () => {
        return this.props.returnLabGrown([this.props.parcelName], this.state.remarks)
        .then(() => this.setState({remarks: null}))
        .then(() => this.props.getLabGrownDetails(this.props.parcelName))
    }
    sellStone = () => {
        return this.props.sellLabGrown([this.props.parcelName], this.state.remarks)
        .then(() => this.setState({remarks: null}))
        .then(() => this.props.getLabGrownDetails(this.props.parcelName))
    }

    formatDate = date => {
        const dt = moment(date);
        return <>
            {dt.format('MMM D, YYYY')}&nbsp;<span className='time-only'>{dt.format('h:mm A')}</span>
        </>
    }

    getDataRow = (caption, value, number) => {
        if (value==null || value==='' || (number && value===0)) return null;
        if (number) return (
            <div className='row caption38'>
                <div>{caption}</div>
                <div className="semibold">
                    <NumberFormat displayType='text' decimalScale={3} thousandSeparator={true} value={value} {...number} />
                </div>
            </div>
        )
        else return (
            <div className='row caption38'>
                <div>{caption}</div>
                <div className="semibold">{value}</div>
            </div>
        )
    }

    renderHistory = (stone) => {
        if (!stone || !Array.isArray(stone.history) || !stone.history.length) return null;
        return <>
            {stone.history.map((row,i) => (
                <div key={i} className='row caption40'>
                    <div className="semibold">
                        {history_type_names[row.type] ? history_type_names[row.type] : row.type}
                        &emsp;
                        {
                            i==stone.history.length-1 && deletableTypes.includes(row.type) && this.props.isAdmin ? 
                            <ConfirmModal
                                confirm={() => this.deleteHistory(row)}
                                message='Are you sure you want to delete this event?'
                                trigger={onClick => <button className='list-button btn-warning' onClick={onClick}><i className="fas fa-backspace"/></button>}
                            />
                            : null
                        }
                    </div>
                    <div className="semibold">
                        {this.formatDate(row.date)}
                        &emsp;
                        {
                            i==stone.history.length-1 && deletableTypes.includes(row.type) && this.props.isAdmin ? 
                            <ConfirmModal
                                confirm={() => this.backdateHistory(row)}
                                message={<>
                                    Change this event's date to {this.state.dateChange ? moment(this.state.dateChange).format(dateFormat) : '...'}?
                                    <br/><br/>
                                    <input type="datetime-local" onChange={e => this.setState({dateChange: e.target.value})} value={this.state.dateChange || moment(row.date).format("YYYY-MM-DDTHH:mm")} />
                                </>}
                                trigger={onClick => <button className='list-button btn-warning' onClick={onClick}><i className="fas fa-edit"/></button>}
                            />
                            : null
                        }
                    </div>
                </div>
            ))}
        </>
    }

    render() {
        if (!this.props.stone) return "Stone could not be loaded";
        const stone = this.props.stone || {};
        const tier = tierList.find(t => t.value === stone?.tier)
        return <>
            <div className='body stone-details__container'>
                <div className='row stone-details__container--header'>
                    <div className='stone-details__page-title--container'>
                        <div className='stone-details__page-title'>
                            Lab Grown Diamond Details
                        </div>
                        <div className='stone-details__page-parcelName semibold'>
                            {stone.parcelName}
                        </div>
                    </div>
                    <div className='stone-details__page-matchbutton'>
                        <button style={{marginLeft: '0.5rem'}} className='btn' onClick={() => this.setState({pairsOpen: true})}>Find a Match</button>
                    </div>
                    <div className='flexbox-filler'>&nbsp;</div>
                    <div className='stone-details__price-container card'>
                        <div className='row stone-details__price--card-header'>
                            <i className='far fa-gem' style={{color: 'var(--primary-colour)'}}/>
                            <div className='flexbox-filler'/>
                            &nbsp;
                            <span>{stone.shapeCode}</span>
                            &nbsp;
                            <span>{stone.weight}</span>
                            &nbsp;
                            <span>{stone.colour}</span>
                            &nbsp;
                            <span>{stone.clarity}</span>
                            &nbsp;
                            <span>{stone.cut}</span>
                            &nbsp;
                            <span>{stone.polish}</span>
                            &nbsp;
                            <span>{stone.symmetry}</span>
                        </div>
                        <div className='row stone-details__price--card-values'>
                            <div>
                                {stone.displayRap &&
                                    <>
                                        <span className="stone-details__price--card-values__rap-title">RAP</span>
                                        <div className="bold"><NumberFormat displayType='text' decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} value={stone.displayRap} prefix='$' /></div>
                                    </>
                                }
                            </div>
                            <div>
                                {stone.displayRap &&
                                    <>
                                        <span className="stone-details__price--card-values__rap-title">RAP %</span>
                                        <div className="bold"><NumberFormat displayType='text' decimalScale={3} value={stone.displayRapPercent} suffix='%' /></div>
                                    </> 
                                }
                            </div>
                            <div className='valign-centre primary-text stone-details__price--card-values__price'>   
                                <div><NumberFormat displayType='text' decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} value={stone.total} prefix='$' /></div>
                            </div>
                        </div>
                        <div className='row even-split'>
                            {
                                stone.status==='AVAILABLE' ? 
                                <div className="stone-details__container--request-btn">
                                    <ConfirmStonesModal
                                        confirm={this.requestStone} 
                                        title='Confirm Request'
                                        message={`Review and click "Request Stone" to confirm.`} 
                                        confirmCaption='Request Stone'
                                        trigger={onClick => <button className='btn' onClick={onClick}>Request</button>}
                                        stones={[stone]}
                                        comment={this.state.remarks}
                                        updateComment={(e) => this.setState({remarks: e.target.value})}
                                    />
                                </div>
                                : null
                            }
                            {
                                stone.status==='CUSTOMER' || stone.status==='REQUESTED' ? 
                                <>
                                    <div>
                                        <ConfirmStonesModal
                                            confirm={this.returnStone} 
                                            title='Confirm Return'
                                            message={`Review and click "Return Stone" to confirm.`} 
                                            confirmCaption='Return Stone'
                                            trigger={onClick => <button className='btn btn-secondary' onClick={onClick}>Return</button>}
                                            stones={[stone]}
                                            comment={this.state.remarks}
                                            updateComment={(e) => this.setState({remarks: e.target.value})}
                                        />
                                    </div>
                                    <div>
                                        <ConfirmStonesModal
                                            confirm={this.sellStone} 
                                            title='Confirm Sale'
                                            message={`Review and click "Sell Stone" to confirm.`} 
                                            confirmCaption='Sell Stone'
                                            trigger={onClick => <button className='btn' onClick={onClick}>Sold</button>}
                                            stones={[stone]}
                                            comment={this.state.remarks}
                                            updateComment={(e) => this.setState({remarks: e.target.value})}
                                        />
                                    </div>
                                </>
                                : null
                            }
                        </div>
                    </div>
                </div>
                <div className='card stone-details__details-container'>
                    <div className='stone-details__grid'>
                        <div className='stone-details__column'>
                            <div className='stone-details__section'>
                                <div className='row section-header'>
                                    Stone Properties
                                    {/* <button style={{marginLeft: '0.5rem'}} className='btn btn-secondary btn-small' onClick={() => this.setState({pairsOpen: true})}>Find a Match</button> */}
                                    <hr/>
                                </div>
                                {tier ? <div className='row semibold'>{tier.description}</div> : null}
                                {this.getDataRow('Weight', stone.weight, {decimalScale: 2, fixedDecimalScale: true})}
                                {this.getDataRow('Shape', ((this.props.shapeList || []).find(sh => sh.shapeCode==stone.shapeCode) || {})[stone.cut=='BL' ? 'blDescription' : 'description']) || stone.shapeCode}
                                {this.getDataRow('Colour', stone.colour)}
                                {this.getDataRow('Clarity', stone.clarity)}
                                {this.getDataRow('Fluor', stone.fluorescence)}
                                {this.getDataRow('Cut', stone.cut)}
                                {this.getDataRow('Polish', stone.polish)}
                                {this.getDataRow('Symmetry', stone.symmetry)}
                                {/*this.getDataRow('Origin', stone.countryOfOrigin)*/}
                            </div>
                            <div className='stone-details__section'>
                                <div className='row section-header'>Proportions<hr/></div>
                                {this.getDataRow('Table', stone.tablePct, {suffix: '%'})}
                                {this.getDataRow('Depth', stone.depthPct, {suffix: '%'})}
                                {this.getDataRow('Girdle', stone.girdlePct, {suffix: '%'})}
                                {this.getDataRow('Crown Height', stone.crownHeight, {suffix: '%'})}
                                {this.getDataRow('Crown Angle', stone.crownAngle, {suffix: '°'})}
                                {this.getDataRow('Pavilion Depth', stone.pavilionDepth, {suffix: '%'})}
                                {this.getDataRow('Pavilion Angle', stone.pavilionAngle, {suffix: '°'})}
                                {this.getDataRow('Star Halves', stone.starHalves, {suffix: '%'})}
                                {this.getDataRow('Lower Half', stone.pavilionHalves, {suffix: '%'})}
                            </div>
                            {
                                stone.photoUrl ? 
                                <div className='stone-details__section'>
                                    {
                                        /(\.jpg|\.jpeg|\.png)$/i.test(stone.photoUrl) ? 
                                        <img src={stone.photoUrl} alt='Image of diamond' className='row' /> :
                                        <div className={`video-container${stone.photoUrl.toLowerCase().includes('vision360.html') ? '' : (stone.photoUrl.toLowerCase().includes('dnadiamond.net') || stone.photoUrl.toLowerCase().includes('/dnadata/') ? '-dnad' : '-old')}`}>
                                            <iframe
                                                src={stone.photoUrl}
                                                scrolling="no" seamless="seamless" 
                                                className={`video-iframe${stone.photoUrl.toLowerCase().includes('vision360.html') ? '' : (stone.photoUrl.toLowerCase().includes('dnadiamond.net') || stone.photoUrl.toLowerCase().includes('/dnadata/') ? '-dnad' : '-old')}`}
                                            />
                                        </div>
                                    }
                                </div>
                                : null
                            }
                        </div>
                        <div className='stone-details__column'>
                            <div className='stone-details__section'>
                                <div className='row section-header'>Certificate<hr/></div>
                                {this.getDataRow('Lab', stone.lab)}
                                {this.getDataRow('Cert', this.renderCertNo(stone.lab, stone.certNo))}
                            </div>
                            <div className='stone-details__section'>
                                <div className='row section-header'>Status<hr/></div>
                                {this.getDataRow('Status', stone.status)}
                                {this.getDataRow('In Cart', stone.inCart ? "Yes" : "No")}
                                {this.getDataRow('Last Updated', this.formatDate(stone.lastUpdated))}
                                {this.props.isAdmin ? this.getDataRow('Department', stone.location) : null}
                            </div>
                            <div className='stone-details__section'>
                                <div className='row section-header'>Measurements<hr/></div>
                                {this.getDataRow('Length', stone.length, {})}
                                {this.getDataRow('Width', stone.width, {})}
                                {this.getDataRow('Height', stone.height, {})}
                            </div>
                            <div className='stone-details__section'>
                                <div className='row section-header'>History<hr/></div>
                                {this.renderHistory(stone)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <CertModal report={this.state.certOpen} />
            <SimilarStones
                stone={this.state.pairsOpen ? stone : null}
                rankingType='PAIR'
                onClose={() => this.setState({pairsOpen: false})}
            />
        </>
    }
}

function mapStateToProps(state, ownProps) {
    return {
        stone: (state.labGrown.details) ? state.labGrown.details[ownProps.parcelName] : null,
        shapeList: state.attributes.shapes,
        isAdmin: state.user.currentUser.administrator
    };
}

export default connect(mapStateToProps, actions)(LabGrownDetails)