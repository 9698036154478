import React, {Component} from 'react'
import {connect} from 'react-redux'
import * as actions from '../../actions'
import CustomerList from './customer_list'
import CustomerForm from './customer_form'
import AvailableAccounts from './available_accounts'

class CustomerBase extends Component {

    state = {
        customerId: null,
        newCustomer: false,
        availableAccounts: false
    }

    viewCustomer = (customerId) => (
        () => this.setState({customerId, newCustomer: false})
    )

    newCustomer = (customer) => {
        this.props.createCustomer(customer)
        .then(() => this.setState({customerId: null, newCustomer: false}))
    }

    editCustomer = (customer) => {
        this.props.editCustomer(customer)
        .then(() => this.setState({customerId: null, newCustomer: false}))
    }

    render() {
        if (this.state.availableAccounts) {
            return (
                <AvailableAccounts
                    onCancel={() => this.setState({availableAccounts: false, newCustomer: false, customerId: false})}
                />
            )
        } else if (this.state.customerId) {
            return (
                <CustomerForm 
                    customerId={this.state.customerId} 
                    onCancel={() => this.setState({customerId: null, newCustomer: false})} 
                    onSave={this.editCustomer} 
                    getMarkups={this.props.getMarkups}
                    saveMarkups={this.props.setMarkups}
                />
            )
        } else if (this.state.newCustomer) {
            return (
                <CustomerForm 
                    onCancel={() => this.setState({customerId: null, newCustomer: false})} 
                    onSave={this.newCustomer} 
                />
            )
        } else {
            return (
                <>
                    <div className='row spaced'>
                        <button className='btn btn-row' onClick={() => this.setState({newCustomer: true, customerId: null})}>New</button>
                        <button className='btn btn-row' onClick={() => this.setState({availableAccounts: true})}>Available Accounts</button>
                    </div>
                    <div className='row spaced'>
                        <CustomerList viewCustomer={this.viewCustomer} />
                    </div>
                </>
            )
        }
    }
}

export default connect(null, actions)(CustomerBase);