import React, {Component} from 'react'
import {connect} from 'react-redux'
import ReactTable from 'react-table-6'
import selectTableHOC from 'react-table-6/lib/hoc/selectTable'
import NumberFormat from 'react-number-format'
import Modal from 'react-responsive-modal'
import Gallery from './gallery'
import CertIcon from './cert_icon'
import * as actions from '../../actions'
import {TextFilter, NumberFilter, OptionsFilter, AttributeFilter, filterText, filterNumber, filterOptions, filterAttribute} from './filters'
import { getTotalsText } from './utils'
import ReactTooltip from 'react-tooltip'
import { tierList } from './tierList'
import CertModal from './cert_modal'
import ListIcon from './list-icon'

const SelectTable = selectTableHOC(ReactTable);

class StoneTable extends Component {

    tableRef = React.createRef();
    state = {virtualRow: null, embedOpen: null, pdfUrl: null};

    static defaultProps = {
        data: [],
        extraColumns: [],
        extraTableProps: {},
        small: false,
        includeDesireRow: false,
        galleryOpen: false,
        galleryStones: null
    }

    componentDidMount() {
        if (this.props.tableId && this.props.savedFilters[this.props.tableId])
            this.refilterDesire()
    }

    componentDidUpdate(prevProps) {
        if (this.state.clearFilters) {
            this.setState({clearFilters: undefined})
            this.handleFilterChange([])
        }
        if (prevProps.data !== this.props.data && this.state.galleryOpen) {
            this.setState({galleryStones: this.tableRef.current.state.sortedData})
        }
        if (prevProps.data !== this.props.data) {
            ReactTooltip.rebuild()
        }
    }
    
    showCert = async (parcelName, lab, certNo) => {
        if (lab === 'FM') {
            const foundUrl = await this.props.fmCertExists(certNo)
            if (foundUrl) {
                this.setState({pdfUrl: {pdfUrl: foundUrl}})
                return
            }
        }
        this.props[this.props.labGrown ? 'getLabGrownCert' : 'getCert'](parcelName)
        .then(certData => {
            this.setState({pdfUrl: certData})
        })
    }

    getFilteredParcelNames = () => {
        if (!this.tableRef.current) return this.props.data.map(row => row.parcelName);
        if (this.props.onSelectRows && this.props.selectedRows) {
            const wrappedInstance = this.tableRef.current.getWrappedInstance();
            const currentRecords = wrappedInstance.getResolvedState().sortedData;
            return currentRecords.map(row => row.parcelName).filter(p => p);
        } else {
            const currentRecords = this.tableRef.current.getResolvedState().sortedData;
            return currentRecords.map(row => row.parcelName).filter(p => p);
        }
    }

    isSelected = (parcelName) => {
        return Boolean(this.props.selectedRows[parcelName]);
    }

    isSelectAll = () => {
        const fpn = this.getFilteredParcelNames();
        return Array.isArray(fpn) && fpn.length && fpn.every(parcelName => this.isSelected(parcelName));
    }

    toggleRow = (parcelName) => {
        if (parcelName.startsWith('select-')) parcelName = parcelName.slice(7);
        this.props.onSelectRows({...this.props.selectedRows, [parcelName]: !this.isSelected(parcelName)});
    }

    toggleAll = () => {
        const isa = this.isSelectAll();
        this.props.onSelectRows({...this.props.selectedRows, ...this.getFilteredParcelNames().reduce((obj,parcelName) => ({...obj, [parcelName]: !isa}), {})});
    }

    handleFilterChange = (filtered) => {
        if (this.props.tableId) {
            this.props.setSavedFilters(this.props.tableId, filtered)
        }
        if (this.props.onSelectRows && this.props.selectedRows) {
            this.refilterSelection()
        } else {
            this.refilterDesire()
        }
    }

    refilterSelection = () => {
        const fpn = this.getFilteredParcelNames();
        this.props.onSelectRows(Object.keys(this.props.selectedRows).reduce((newSelection,key) => {
            if (fpn.includes(key)) {
                if (this.props.selectedRows[key]===0) return {...newSelection, [key]: true};
                else return {...newSelection, [key]: this.props.selectedRows[key]};
            }
            else if (this.isSelected(key)) return {...newSelection, [key]: 0};
            else return newSelection;
        }, {}));
        this.refilterDesire(fpn);
    }

    refilterDesire = (fpn=this.getFilteredParcelNames()) => {
        if (fpn.length<3 && this.props.includeDesireRow && this.tableRef.current) {
            const filters = this.tableRef.current.getResolvedState().filtered;
            let virtualRow = {
                virtual: true, certNo: 'Similar Stone...',
                shapeCode: 'All', weight: 'All', colour: 'All', clarity: 'All', fluorescence: 'All', cut: 'All', polish: 'All', symmetry: 'All',
                desire: {quantity: 1}
            };
            for (let filter of filters) {
                if (filter.id==='shapeCode' && Array.isArray(filter.value) && filter.value.length) {
                    virtualRow.shapeCode = filter.value.join(', ');
                    virtualRow.desire.shape = filter.value;
                }
                if (filter.id==='weight') {
                    virtualRow.weight = filter.value.min + " - " + filter.value.max;
                    virtualRow.desire.weight = {minimum: filter.value.min, maximum: filter.value.max};
                }
                if (filter.id==='colour' && Array.isArray(filter.value) && filter.value.length) {
                    virtualRow.colour = filter.value.join(', ')
                    virtualRow.desire.colour = filter.value
                    // Slider version below
                    // const min = this.props.colourList.find(c => c.order==filter.value.min).colour;
                    // const max = this.props.colourList.find(c => c.order==filter.value.max).colour;
                    // virtualRow.colour = min + '-' + max;
                    // virtualRow.desire.colour = this.props.colourList.filter(c => !c.isFancy && c.order >= filter.value.min && c.order <= filter.value.max)
                    //     .map(c => c.colour);
                    // if (Array.isArray(filter.value.include) && filter.value.include.length) {
                    //     virtualRow.colour += ', ' + filter.value.include.join(', ');
                    //     virtualRow.desire.colour = virtualRow.desire.colour.concat(filter.value.include)
                    // }
                }
                if (filter.id==='clarity' && Array.isArray(filter.value) && filter.value.length) {
                    virtualRow.clarity = filter.value.join(', ')
                    virtualRow.desire.clarity = filter.value
                    // Slider version below
                    // const min = this.props.clarityList.find(c => c.order==filter.value.min).clarity;
                    // const max = this.props.clarityList.find(c => c.order==filter.value.max).clarity;
                    // virtualRow.clarity = min + '-' + max;
                    // virtualRow.desire.clarity = this.props.clarityList.filter(c => !c.isFancy && c.order >= filter.value.min && c.order <= filter.value.max)
                    //     .map(c => c.clarity);
                    // if (Array.isArray(filter.value.include) && filter.value.include.length) {
                    //     virtualRow.clarity += ', ' + filter.value.include.join(', ');
                    //     virtualRow.desire.clarity = virtualRow.desire.clarity.concat(filter.value.include)
                    // }
                }
                if (filter.id==='fluorescence' && Array.isArray(filter.value) && filter.value.length) {
                    virtualRow.fluorescence = filter.value.join(', ')
                    virtualRow.desire.fluorescence = filter.value
                    // Slider version below
                    // const min = this.props.fluorList.find(c => c.order==filter.value.min).fluorescence;
                    // const max = this.props.fluorList.find(c => c.order==filter.value.max).fluorescence;
                    // virtualRow.fluorescence = min + '-' + max;
                    // virtualRow.desire.fluorescence = this.props.fluorList.filter(c => !c.isFancy && c.order >= filter.value.min && c.order <= filter.value.max)
                    //     .map(c => c.fluorescence);
                    // if (Array.isArray(filter.value.include) && filter.value.include.length) {
                    //     virtualRow.fluorescence += ', ' + filter.value.include.join(', ');
                    //     virtualRow.desire.fluorescence = virtualRow.desire.fluorescence.concat(filter.value.include)
                    // }
                }
                if (filter.id==='cut' && Array.isArray(filter.value) && filter.value.length) {
                    virtualRow.cut = filter.value.join(', ')
                    virtualRow.desire.cut = filter.value
                    // Plain text version below
                    // virtualRow.cut = filter.value;
                    // virtualRow.desire.cut = [filter.value]
                }
                if (filter.id==='polish' && Array.isArray(filter.value) && filter.value.length) {
                    virtualRow.polish = filter.value.join(', ')
                    virtualRow.desire.polish = filter.value
                    // Plain text version below
                    // virtualRow.polish = filter.value;
                    // virtualRow.desire.polish = [filter.value]
                }
                if (filter.id==='symmetry' && Array.isArray(filter.value) && filter.value.length) {
                    virtualRow.symmetry = filter.value.join(', ')
                    virtualRow.desire.symmetry = filter.value
                    // Plain text version below
                    // virtualRow.symmetry = filter.value;
                    // virtualRow.desire.symmetry = [filter.value]
                }
            }
            if (!virtualRow.desire.cut) {
                virtualRow.cut = 'EX-VG';
                virtualRow.desire.cut = ['EX','VG'];
            }
            if (!virtualRow.desire.polish) {
                virtualRow.polish = 'EX-VG';
                virtualRow.desire.polish = ['EX','VG'];
            }
            if (!virtualRow.desire.symmetry) {
                virtualRow.symmetry = 'EX-VG';
                virtualRow.desire.symmetry = ['EX','VG'];
            }
            this.setState({virtualRow});
            this.props.priceDesire(virtualRow)
            .then(data => {
                if (this.state.virtualRow === virtualRow) {
                    virtualRow.total = `${data.minimum} - ${data.maximum}`;
                    this.setState({virtualRow});
                }
            })
            .catch((err) => {console.log("@",err)
                if (this.state.virtualRow === virtualRow) {
                    virtualRow.total = 'Unknown';
                    this.setState({virtualRow});
                }
            })
        } else if (this.state.virtualRow) {
            this.setState({virtualRow: null});
        }
    }

    formatPriceRange = (total) => {
        const idx = total.indexOf(' - ');
        if (idx < 0) return <NumberFormat value={total} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true} />
        else return <>
            <NumberFormat value={total.slice(0, idx)} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true} />
            -
            <NumberFormat value={total.slice(idx+3)} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true} />
        </>
    }

    attributeSorter = (key) => ((a,b) => a.isFancy-b.isFancy || (a.isFancy ? (a[key]>b[key] ? 1 : a[key]<b[key] ? -1 : 0) : a.order-b.order))
    LabFilter = props => <OptionsFilter data={this.props.data} {...props} />
    ShapeFilter = props => <OptionsFilter data={this.props.data} options={this.props.shapeList.sort((a,b) => a.description<b.description ? -1 : (a.description>b.description ? 1 : 0))} optionKey='shapeCode' {...props} />
    //ColourFilter = props => <AttributeFilter options={this.props.colourList} optionKey='colour' {...props} />
    //ClarityFilter = props => <AttributeFilter options={this.props.clarityList} optionKey='clarity' {...props} />
    //FluorFilter = props => <AttributeFilter options={this.props.fluorList} optionKey='fluorescence' {...props} />
    ColourFilter = props => <OptionsFilter data={this.props.data} options={this.props.colourList.sort(this.attributeSorter('colour'))} optionKey='colour' {...props} />
    ClarityFilter = props => <OptionsFilter data={this.props.data} options={this.props.clarityList.sort(this.attributeSorter('clarity'))} optionKey='clarity' {...props} />
    FluorFilter = props => <OptionsFilter data={this.props.data} options={this.props.fluorList.sort(this.attributeSorter('fluorescence'))} optionKey='fluorescence' {...props} />
    CutPolSymFilter = props => <OptionsFilter data={this.props.data} {...props} />
    TierFilter = props => <OptionsFilter data={this.props.data} options={tierList} optionKey='value' {...props} />

    getTableProps = () => {
        const pageSize = 15;
        const gallery = this.state.galleryOpen && this.state.galleryStones != null
        return {
            data: this.state.virtualRow ? this.props.data.concat(this.state.virtualRow) : this.props.data,
            filterable: Array.isArray(this.props.data) && (this.props.data.length > 5 || (this.props.tableId && this.props.savedFilters[this.props.tableId])),
            defaultFiltered: this.props.tableId ? (this.props.savedFilters[this.props.tableId] || []) : [],
            ...(this.state.clearFilters ? {filtered: []} : {}),
            onFilteredChange: this.handleFilterChange,
            resizable: false, //true,
            className: `-highlight${gallery ? ' no-render' : ''}`,
            NoDataComponent: () => (<div style={{textAlign: 'center'}}>0 stones found</div>),
            defaultFilterMethod: this.defaultFilterMethod,
            minRows: 0,
            //showPagination: (this.props.data || []).length > pageSize ? true : false,
            showPagination: true,
            showPageSizeOptions: false,
            defaultPageSize: pageSize, 
            defaultSorted: [
                {id: "weight", desc: false},
            ],
            getTrProps: (state, row) => ({onClick: () => this.props[this.props.labGrown ? 'addLGTab' : 'addTab'](row.original)}),
            columns: [
                {
                    Header: 'V360',
                    accessor: 'photoUrl',
                    width: 50,
                    sortable: false,
                    filterable: false,
                    resizable: false,
                    className: 'list-photo-column',
                    Cell: (row) => row.value ? (
                        /*<a href={row.value} target='_blank' className='list-button' onClick={e => e.stopPropagation()}>
                            <i className="fas fa-camera" />
                        </a>*/
                        <button onClick={(e) => {
                            e.stopPropagation()
                            e.preventDefault()
                            this.setState({embedOpen: row.value})
                        }} className='list-button'>
                            <i className="fas fa-camera" />
                        </button>
                    ) : (
                        <span className='list-button disabled'>
                            <i className="fas fa-camera" />
                        </span>
                    )
                },
                {
                    Header: 'Cert',
                    id: 'certLink',
                    accessor: row => row.lab,
                    width: 50,
                    sortable: false,
                    filterable: false,
                    resizable: false,
                    className: 'list-photo-column',
                    Cell: (row) => row.value==='GIA' || row.value==='GIA-LG' || (row.value === 'FM' && row.original.shortCertNo?.length && row.original.shortCertNo.length < 10) ? (
                        <button onClick={(e) => {
                            e.stopPropagation()
                            e.preventDefault()
                            this.showCert(row.original.parcelName, row.value, row.original.shortCertNo)
                        }} className='list-button'>
                            <CertIcon stone={row.original} />
                        </button>
                    ) : (
                        <span className='list-button disabled'>
                            <CertIcon stone={row.original} />
                        </span>
                    )
                },
                ...(this.props.labGrown ? [] : [
                    {
                        Header: 'H&A',
                        accessor: 'haSleeve',
                        width: 50,
                        sortable: false,
                        filterable: false,
                        resizeable: false,
                        className: 'list-photo-column',
                        Cell: ({value}) => value ? <ListIcon icon='hearts-arrows' className='table-icon'/> : <span>&nbsp;</span>
                    },
                    {
                        Header: 'Brand',
                        accessor: 'vendorId',
                        width: 50,
                        sortable: false,
                        filterable: false,
                        resizeable: false,
                        className: 'list-photo-column',
                        Cell: ({value}) => <ListIcon icon={`vendor-${value ?? 0}`} className='table-icon'/>
                    }
                ]),
                {
                    Header: 'Parcel Name', 
                    accessor: 'parcelName', 
                    minWidth: 110, 
                    //show: this.props.admin,
                    Cell: rowInfo => <span>{rowInfo.value}</span>
                },
                {
                    Header: 'Lab', 
                    accessor: 'lab', 
                    minWidth: 50, 
                    Filter: this.LabFilter, 
                    filterMethod: filterOptions,
                    Cell: rowInfo => <span>{rowInfo.value}</span>
                },
                {
                    Header: 'Cert', 
                    accessor: 'certNo', 
                    minWidth: 90, 
                    Filter: TextFilter, 
                    filterMethod: filterText,
                    Cell: rowInfo => <span>{rowInfo.value}</span>
                },
                {
                    Header: 'Weight', 
                    accessor: 'weight', 
                    sortMethod: this.numericSort, 
                    minWidth: 60, 
                    Cell: rowInfo => {
                        if (rowInfo.original.virtual) return rowInfo.value;
                        else return <NumberFormat displayType='text' value={rowInfo.value} decimalScale={2} fixedDecimalScale={true} />
                    },
                    Filter: NumberFilter, 
                    filterMethod: filterNumber
                },
                {
                    Header: 'Shape', 
                    accessor: 'shapeCode', 
                    minWidth: 100, 
                    Cell: rowInfo => <span>{(((this.props.shapeList || []).find(sh => sh.shapeCode==rowInfo.value) || {})[rowInfo.original.cut=='BL' ? 'blDescription' : 'description']) || rowInfo.value}</span>,
                    Filter: this.ShapeFilter,
                    filterMethod: filterOptions
                },
                {
                    Header: 'Colour', 
                    accessor: 'colour', 
                    minWidth: 60, 
                    Filter: this.ColourFilter,
                    filterMethod: filterOptions
                    //filterMethod: (filter, row, column) => filterAttribute(this.props.colourList, 'colour', filter, row, column)
                },
                {
                    Header: 'Clarity', 
                    accessor: 'clarity', 
                    minWidth: 70,
                    Filter: this.ClarityFilter,
                    filterMethod: filterOptions
                    //filterMethod: (filter, row, column) => filterAttribute(this.props.clarityList, 'clarity', filter, row, column)
                },
                {
                    Header: 'Flu', 
                    accessor: 'fluorescence', 
                    minWidth: 35, 
                    show: !this.props.small,
                    Filter: this.FluorFilter,
                    filterMethod: filterOptions
                    //filterMethod: (filter, row, column) => filterAttribute(this.props.fluorList, 'fluorescence', filter, row, column)
                },
                {
                    Header: 'Cut', 
                    accessor: 'cut', 
                    minWidth: 35, 
                    show: !this.props.small,
                    Filter: this.CutPolSymFilter, filterMethod: filterOptions
                },
                {
                    Header: 'Pol', 
                    accessor: 'polish', 
                    minWidth: 35, 
                    show: !this.props.small,
                    Filter: this.CutPolSymFilter, filterMethod: filterOptions
                },
                {
                    Header: 'Sym', 
                    accessor: 'symmetry', 
                    minWidth: 35, 
                    show: !this.props.small,
                    Filter: this.CutPolSymFilter, filterMethod: filterOptions
                },
                {
                    Header: 'Table %',
                    accessor: 'tablePct',
                    minWidth: 35,
                    show: this.props.large ?? false,
                    Filter: NumberFilter,
                    filterMethod: filterNumber
                },
                {
                    Header: 'Depth %',
                    accessor: 'depthPct',
                    minWidth: 40,
                    show: this.props.large ?? false,
                    Filter: NumberFilter,
                    filterMethod: filterNumber
                },
                {
                    Header: 'Measurements',
                    id: 'measurements',
                    accessor: row => `${row.length}-${row.width}\u00d7${row.height}`,
                    minWidth: 100,
                    show: this.props.large ?? false
                },
                {
                    Header: 'Ratio',
                    id: 'lwRatio',
                    accessor: row => (Math.max(row.length,row.width) / Math.min(row.length,row.width)).toFixed(2),
                    minWidth: 35,
                    Filter: NumberFilter, 
                    filterMethod: filterNumber,
                    show: this.props.large ?? false
                },
                {
                    Header: 'Tier',
                    accessor: 'tier',
                    minWidth: 60,
                    show: !this.props.hideTier,
                    //show: this.props.large ?? false,
                    Filter: this.TierFilter,
                    filterMethod: filterOptions
                },
                /*{
                    Header: 'Origin',
                    accessor: 'origin',
                    minWidth: 75,
                    show: this.props.large ?? false
                },*/
                ...(this.props.priceMode==='WHOLESALE' ? [{
                    Header: 'Rap %',
                    accessor: 'displayRapPercent',
                    minWidth: 50,
                    show: !this.props.small,
                    Cell: row => <NumberFormat value={row.value} displayType={'text'} decimalScale={2} fixedDecimalScale={false} />,
                    Filter: NumberFilter,
                    filterMethod: filterNumber
                }] : []),
                ...(this.props.priceMode === 'NONE' ? [] : [{
                    Header: 'Ppc',
                    id: 'ppc',
                    accessor: row => (row.ppc ?? row.total/row.weight),
                    minWidth: 65,
                    show: !this.props.small,
                    Cell: row => <NumberFormat value={row.value} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true} />,
                    Filter: NumberFilter,
                    filterMethod: filterNumber
                },
                {
                    Header: 'Total', 
                    accessor: 'total', 
                    Cell: (row => {
                        if (row.original.virtual) {
                            if ('total' in row.original) return this.formatPriceRange(row.value);
                            else return "Calculating...";
                        } else return <NumberFormat value={row.value} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true} />
                    }),
                    minWidth: 70,
                    Filter: NumberFilter,
                    filterMethod: filterNumber
                }]),
                ...this.props.extraColumns
            ],
            PaginationComponent: (props) => {
                return <>
                    <div className='-pagination'>
                        <div>
                            {
                                this.props.exporter
                                ? <button 
                                    className='list-button' 
                                    disabled={props.sortedData.length===0}
                                    onClick={() => this.props.exporter(
                                        props.sortedData.map(({_original, ...d}) => ({
                                            ..._original, 
                                            ...d,
                                            shapeName: (((this.props.shapeList || []).find(sh => sh.shapeCode==_original.shapeCode) || {})[_original.cut=='BL' ? 'blDescription' : 'description']) || _original.shapeCode
                                        })),
                                        this.props.extraColumns.filter(col => col.includeExcel),
                                        this.props
                                    )}
                                >
                                    <i className="fas fa-file-excel" />
                                </button>
                                : null
                            }
                        </div>
                        <button
                            className='list-button'
                            onClick={() => props.onPageChange(0)}
                            disabled={!props.canPrevious}
                        >
                            <i className="fas fa-angle-double-left" />
                        </button>
                        <button
                            className='list-button'
                            onClick={() => props.onPageChange(props.page-1)}
                            disabled={!props.canPrevious}
                        >
                            <i className="fas fa-angle-left" />
                        </button>
                        <div>
                            Page&nbsp;
                            <NumberFormat 
                                key='pageinput'
                                displayType='input' 
                                style={{width: '3rem', textAlign: 'center'}}
                                value={props.page+1} 
                                onBlur={(e) => props.onPageChange(Number(e.target.value)-1)}
                                onKeyUp={(e) => {
                                    if (e.key=='Enter') props.onPageChange(Number(e.target.value)-1)
                                }}
                            />
                            &nbsp;of {props.pages}
                        </div>
                        <button
                            className='list-button'
                            onClick={() => props.onPageChange(props.page+1)}
                            disabled={!props.canNext}
                        >
                            <i className="fas fa-angle-right" />
                        </button>
                        <button
                            className='list-button'
                            onClick={() => props.onPageChange(props.pages-1)}
                            disabled={!props.canNext}
                        >
                            <i className="fas fa-angle-double-right" />
                        </button>
                        <div>
                            <NumberFormat
                                displayType='input'
                                style={{width: '3rem', textAlign: 'center'}}
                                value={props.pageSize}
                                onBlur={(e) => props.onPageSizeChange(Number(e.target.value))}
                                onKeyUp={(e) => {
                                    if (e.key=='Enter') props.onPageSizeChange(Number(e.target.value))
                                }}
                            />
                            &nbsp;/page
                        </div>
                    </div>
                </>
            },
            ...this.props.extraTableProps
        }
    }

    defaultFilterMethod = (filter, row, column) => {
        const rowVal = row[filter.pivotId || filter.id]
        if (column.altFilters) {
            let rowVals = [rowVal, ...column.altFilters.map(id => row[id] || (row._original && row._original[id]))];
            return rowVals.some(val => val !== undefined ? String(val).toLowerCase().includes(String(filter.value).toLowerCase()) : false)
                || rowVals.every(val => val===undefined);
        } else {
            return rowVal !== undefined ? String(rowVal).toLowerCase().includes(String(filter.value).toLowerCase()) : true
        }
    }

    getTableAmounts = () => {
        const filteredParcels = new Set(this.getFilteredParcelNames())
        return this.props.data.reduce((tots, stone) => {
            tots.totalCount++
            tots.totalValue+= stone.total
            if (filteredParcels.has(stone.parcelName)) {
                tots.filteredCount++
                tots.filteredValue += stone.total
                if (this.props.selectedRows && this.isSelected(stone.parcelName)) {
                    tots.selectedCount++
                    tots.selectedValue += stone.total
                }
            }
            return tots
        }, {totalCount: 0, totalValue: 0, filteredCount: 0, filteredValue: 0, selectedCount: 0, selectedValue: 0})
    }

    numericSort = (a,b) => {
        a = Number(a); b = Number(b);
        return a-b;
    }

    render() {
        const gallery = this.state.galleryOpen && this.state.galleryStones != null
        const tableAmounts = gallery ? {} : this.getTableAmounts()
        return <div style={{position: 'relative'}}>
            {
                gallery ?
                <>
                    <button 
                        className='btn' 
                        style={{
                            position: 'absolute', 
                            right: 0, top: '-2.5rem', 
                            display: 'block'
                        }} 
                        onClick={() => {
                            this.setState({galleryOpen: false, galleryStones: null})
                        }}
                    >
                        Table
                    </button>
                    <Gallery visible={true} stones={this.state.galleryStones} />
                </>
                : null
            }
            <div className={`tabletop-buttons${gallery ? ' no-render' : ''}`}>
                <div className='table-contents-header'>
                    <span>
                        {tableAmounts.selectedCount ? getTotalsText(tableAmounts.selectedCount, tableAmounts.selectedValue) + ' selected' : ''}
                    </span>
                    <span>
                        Showing {tableAmounts.filteredCount === tableAmounts.totalCount ? 'all' : getTotalsText(tableAmounts.filteredCount, tableAmounts.filteredValue)} of
                        {' '}
                        {getTotalsText(tableAmounts.totalCount, tableAmounts.totalValue)}
                    </span>
                </div>
                <button 
                    className='btn' 
                    style={{
                        display: this.props.savedFilters[this.props.tableId] && this.props.savedFilters[this.props.tableId].length ? 'block' : 'none',
                        marginRight: '8px',
                        marginBottom: '1.2px'
                    }} 
                    onClick={() => this.setState({clearFilters: true})}
                >
                    Clear Filters
                    {/*<span className="fa-stack" style={{fontSize: 'smaller'}}>
                        <i className="fas fa-ban fa-stack-1x"/>
                        <i className="fas fa-filter fa-stack-1x" style={{fontSize: 'smaller'}}/>
                    </span>*/}
                </button>
                {
                    this.props.allowGallery && this.tableRef?.current?.state?.sortedData
                    ? <button
                        className='btn'
                        disabled={this.tableRef.current.state.sortedData.length===0 || !this.tableRef.current.state.sortedData.some(x => x._original.stillPhotoUrl)}
                        onClick={() => this.setState({galleryOpen: true, galleryStones: this.tableRef.current.state.sortedData})}
                    >
                        Gallery
                    </button>
                    : null
                }
            </div>
            {
                this.props.onSelectRows && this.props.selectedRows ? 
                <SelectTable
                    isSelected={this.isSelected}
                    selectAll={this.isSelectAll()}
                    toggleAll={this.toggleAll}
                    toggleSelection={this.toggleRow}
                    keyField='parcelName'
                    selectType='checkbox'
                    ref={this.tableRef}
                    {...this.getTableProps()}
                /> :
                <ReactTable
                    ref={this.tableRef}
                    {...this.getTableProps()}
                />
            }
            <Modal
                open={!!this.state.embedOpen} 
                onClose={() => this.setState({embedOpen: null})}
                showCloseIcon={true}
                styles={{modal: {padding: '3rem 3rem 2rem 3rem'}}}
                focusTrapped={false}
                center
            >
                <div className={`modal-video-container${this.state.embedOpen?.toLowerCase()?.includes('vision360.html') ? '' : (this.state.embedOpen?.toLowerCase()?.includes('dnadiamond.net') ? '-dnad' : '-old')}`} >
                    <iframe 
                        src={this.state.embedOpen} 
                        scrolling='no'
                        className={`modal-video-iframe${this.state.embedOpen?.toLowerCase()?.includes('vision360.html') ? '' : (this.state.embedOpen?.toLowerCase()?.includes('dnadiamond.net') ? '-dnad' : '-old')}`}
                    />
                </div>
            </Modal>
            <CertModal report={this.state.pdfUrl} />
        </div>
    }
}

function mapStateToProps(state) {
    return {
        shapeList: state.attributes.shapes || [],
        colourList: state.attributes.colours || [],
        clarityList: state.attributes.clarities || [],
        fluorList: state.attributes.fluorescences || [],
        gradeList: state.attributes.grades || [],
        admin: state.user && state.user.currentUser ? state.user.currentUser.administrator : false,
        priceMode: state.user && state.user.currentUser ? state.user.currentUser.showPrices : 'NONE',
        savedFilters: state.tabs.savedFilters || {}
    }
}

export default connect(mapStateToProps, actions, null, {forwardRef: true})(StoneTable);

export const formatStatus = row => {
    if (!row?.status) return null
    else if (row.status === 'NOT_AVAILABLE' || row.status === 'AVAILABLE') return 'Returned'
    else return row.status.split(/[_\ ]+/g).map(w => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(' ')
}
