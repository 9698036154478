import {
    SET_AUTH_TIME,
    LOG_OUT
} from './types'

export default function(state={}, action) {
    switch (action.type) {
        case SET_AUTH_TIME: {
            return {...state, authenticated: true, timeOut: (new Date()).getTime() + 3600000} //one hour from now
        }
        case LOG_OUT: {
            return {...state, authenticated: false, timeOut: null}
        }
        default: {
            return state;
        }
    }
}