import React from 'react';
import { Route, Switch, Router } from 'react-router-dom'
//import { Switch } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import { ToastContainer, Flip } from 'react-toastify'
import './App.css';
import Home from './components/login/home'
import Login from './components/login/login'
import LoginForm from './components/login/loginForm'
import Referral from './components/login/referral'
import ReferralForm from './components/login/referralForm'
import About from './components/login/about'
import News from './components/login/news'
import AppBase from './components/appbase'

const history = createBrowserHistory();

function App() {
    return (
        <Router history={history}>
            <ToastContainer transition={Flip}/>
            <Switch>
                <Route path='/home' component={Home} />
                <Route path='/login' component={Login} />
                <Route path='/loginform' component={LoginForm} />
                <Route path='/referral' component={Referral} />
                <Route path='/referralform' component={ReferralForm} />
                <Route path='/about' component={About} />
                <Route path='/news' component={News} />
                <Route path='' component={AppBase} />
            </Switch>
        </Router>
    );
}

export default App;
