import React, {useState, useEffect, useMemo} from 'react'
import {connect} from 'react-redux';
import * as actions from '../../actions'
import { success } from '../common/toast';
import NumberFormat from 'react-number-format'
import Modal from 'react-responsive-modal'

function SimilarStones({stone, rankingType, onClose, ...props}) {

    const [rankedList, setRankedList] = useState([])
    const [selectedIndex, setSelectedIndex] = useState(null)

    const similarLookup = stone?.labGrown ? props.lgSimilarStones : props.similarStones
    const enCart = stone?.labGrown ? props.addToLabGrownCart : props.addToCart
    const refreshCart = stone?.labGrown ? props.getLabGrownCart : props.getCart

    useEffect(() => {
        if (!stone?.parcelName || !rankingType) {
            setRankedList([])
            setSelectedIndex(null)
        } else {
            similarLookup({parcelName: stone.parcelName, rankingType})
            .then(results => {
                setRankedList(results.map(rStone => Object.assign(rStone.stone, {rank: rStone.rank})))
                if (results.length && selectedIndex==null) setSelectedIndex(0)
            })
        }
    }, [stone, rankingType])

    const selectedStone = useMemo(() => {
        if (selectedIndex == null) return null
        return (rankedList?.[selectedIndex]) ?? null
    }, [rankedList, selectedIndex])

    const addToCart = (parcelName) => {
        enCart(parcelName)
        .then(() => {
            success('Added to Cart')
            refreshCart()
            if (stone?.parcelName === parcelName) stone.inCart = true
            const newList = rankedList.slice()
            for (const st2 of newList) {
                if (st2.parcelName === parcelName) st2.inCart = true
            }
            setRankedList(newList)
        })
    }

    const getDataRow = (caption, value, number) => {
        if (value==null || value==='' || (number && value===0)) return null;
        if (number) return <>
            <div>{caption}</div>
            <div className="semibold">
                <NumberFormat displayType='text' decimalScale={3} thousandSeparator={true} value={value} {...number} />
            </div>
        </>
        else return <>
            <div>{caption}</div>
            <div className="semibold">{value}</div>
        </>
    }

    const getCartButton = stone => {
        if (stone?.status === 'AVAILABLE') {
            return (
                <button
                    title="Add to Cart"
                    disabled={stone?.inCart}
                    className="list-button"
                    onClick={(e) => {
                        e.stopPropagation();
                        addToCart(stone?.parcelName);
                    }}
                >{
                    stone?.inCart ?
                    <i className='fa fa-check-circle' /> : 
                    <i className='fa fa-shopping-cart' />
                }</button>
            )
        } else return null
    }

    const renderLeftBar = () => {
        return <div className='similar--left'>
            <div className='similar--selected'>
                <div className='similar--selected_container'>
                    <div className='similar--selected'>{stone?.parcelName}</div>
                    <div className='similar--selected_bullet'>&bull;</div>
                </div>
            </div>
            <hr/>
            <div className='similar--left'>
                {!rankedList?.length ? <span>We weren't able to find a close match.<br/>Sorry!</span> : null}
                {rankedList?.map((st,i) => {
                    if (i===selectedIndex) return <div key={i} className='similar--selected_container'>
                        <div className='similar--selected' onClick={() => setSelectedIndex(i)}>{st.parcelName}</div>
                        <div className='similar--selected_bullet'>&bull;</div>
                    </div>
                    else return <div key={i} onClick={() => setSelectedIndex(i)}>{st.parcelName}</div>
                })}
            </div>
        </div>
    }
    const renderStones = (stone1, stone2) => {
        return <>
            <div className='similar--detail_head'>
                <div>
                    <div className='similar--stone_head'>{stone1?.parcelName}</div>
                    <div className="semibold">
                        <NumberFormat displayType='text' decimalScale={2} fixedDecimalScale={2} thousandSeparator={true} value={stone1?.total} prefix='$' />
                    </div>
                </div>
                {getCartButton(stone1)}
            </div>
            <div className='similar--detail_head'>
                <div>{stone2?.parcelName ? <>
                    <div className='similar--stone_head'>
                        {stone2?.parcelName}
                    </div>
                    <div className="semibold">
                        <NumberFormat displayType='text' decimalScale={2} fixedDecimalScale={2} thousandSeparator={true} value={stone2?.total} prefix='$' />
                    </div>
                </> : null}</div>
                {getCartButton(stone2)}
            </div>
            <div className='similar--stone_group'>
                <div className='row section-header gridspan'>Stone Properties<hr/></div>
                {!stone1?.labGrown && stone1?.tier ? <div className='row gridspan'>Dialog {stone1?.tier}</div> : null}
                {getDataRow('Weight', stone1?.weight, {decimalScale: 2, fixedDecimalScale: true})}
                {getDataRow('Shape', ((props.shapeList || []).find(sh => sh.shapeCode==stone1?.shapeCode) || {})[stone1?.cut=='BL' ? 'blDescription' : 'description'] || stone1?.shapeCode)}
                {getDataRow('Colour', stone1?.colour)}
                {getDataRow('Clarity', stone1?.clarity)}
                {getDataRow('Fluor', stone1?.fluorescence)}
                {getDataRow('Cut', stone1?.cut)}
                {getDataRow('Polish', stone1?.polish)}
                {getDataRow('Symmetry', stone1?.symmetry)}
            </div>
            <div className='similar--stone_group'>{stone2?.parcelName ? <>
                <div className='row section-header gridspan'>Stone Properties<hr/></div>
                {!stone2?.labGrown && stone2?.tier ? <div className='row gridspan'>Dialog {stone2?.tier}</div> : null}
                {getDataRow('Weight', stone2?.weight, {decimalScale: 2, fixedDecimalScale: true})}
                {getDataRow('Shape', ((props.shapeList || []).find(sh => sh.shapeCode==stone2?.shapeCode) || {})[stone2?.cut=='BL' ? 'blDescription' : 'description'] || stone2?.shapeCode)}
                {getDataRow('Colour', stone2?.colour)}
                {getDataRow('Clarity', stone2?.clarity)}
                {getDataRow('Fluor', stone2?.fluorescence)}
                {getDataRow('Cut', stone2?.cut)}
                {getDataRow('Polish', stone2?.polish)}
                {getDataRow('Symmetry', stone2?.symmetry)}
            </> : null}</div>
            <div className='similar--stone_group'>
                <div className='row section-header gridspan'>Measurements<hr/></div>
                {getDataRow('Length', stone1?.length, {})}
                {getDataRow('Width', stone1?.width, {})}
                {getDataRow('Height', stone1?.height, {})}
                {getDataRow('Table', stone1?.tablePct, {suffix: '%'})}
                {getDataRow('Depth', stone1?.depthPct, {suffix: '%'})}
                {getDataRow('Girdle', stone1?.girdlePct, {suffix: '%'})}
                {getDataRow('Crown Height', stone1?.crownHeight, {suffix: '%'})}
                {getDataRow('Crown Angle', stone1?.crownAngle, {suffix: '°'})}
                {getDataRow('Pavilion Depth', stone1?.pavilionDepth, {suffix: '%'})}
                {getDataRow('Pavilion Angle', stone1?.pavilionAngle, {suffix: '°'})}
                {getDataRow('Star Halves', stone1?.starHalves, {suffix: '%'})}
                {getDataRow('Lower Half', stone1?.pavilionHalves, {suffix: '%'})}
            </div>
            <div className='similar--stone_group'>{stone2?.parcelName ? <>
                <div className='row section-header gridspan'>Measurements<hr/></div>
                {getDataRow('Length', stone2?.length, {})}
                {getDataRow('Width', stone2?.width, {})}
                {getDataRow('Height', stone2?.height, {})}
                {getDataRow('Table', stone2?.tablePct, {suffix: '%'})}
                {getDataRow('Depth', stone2?.depthPct, {suffix: '%'})}
                {getDataRow('Girdle', stone2?.girdlePct, {suffix: '%'})}
                {getDataRow('Crown Height', stone2?.crownHeight, {suffix: '%'})}
                {getDataRow('Crown Angle', stone2?.crownAngle, {suffix: '°'})}
                {getDataRow('Pavilion Depth', stone2?.pavilionDepth, {suffix: '%'})}
                {getDataRow('Pavilion Angle', stone2?.pavilionAngle, {suffix: '°'})}
                {getDataRow('Star Halves', stone2?.starHalves, {suffix: '%'})}
                {getDataRow('Lower Half', stone2?.pavilionHalves, {suffix: '%'})}
            </> : null}</div>
            {
                stone1?.photoUrl ? 
                <div className='stone-details__section'>
                    {
                        /(\.jpg|\.jpeg|\.png)$/i.test(stone1.photoUrl) ? 
                        <img src={stone1.photoUrl} alt='Image of diamond' className='row' /> :
                        <div className={`video-container${stone1.photoUrl.toLowerCase().includes('vision360.html') ? '' : (stone1.photoUrl.toLowerCase().includes('dnadiamond.net') || stone1.photoUrl.toLowerCase().includes('/dnadata/') ? '-dnad' : '-old')}`}>
                            <iframe
                                src={stone1.photoUrl}
                                scrolling="no" seamless="seamless" 
                                className={`video-iframe${stone1.photoUrl.toLowerCase().includes('vision360.html') ? '' : (stone1.photoUrl.toLowerCase().includes('dnadiamond.net') || stone1.photoUrl.toLowerCase().includes('/dnadata/') ? '-dnad' : '-old')}`}
                            />
                        </div>
                    }
                </div>
                : <div/>
            }
            {
                stone2?.photoUrl ? 
                <div className='stone-details__section'>
                    {
                        /(\.jpg|\.jpeg|\.png)$/i.test(stone2.photoUrl) ? 
                        <img src={stone2.photoUrl} alt='Image of diamond' className='row' /> :
                        <div className={`video-container${stone2.photoUrl.toLowerCase().includes('vision360.html') ? '' : (stone2.photoUrl.toLowerCase().includes('dnadiamond.net') || stone2.photoUrl.toLowerCase().includes('/dnadata/') ? '-dnad' : '-old')}`}>
                            <iframe
                                src={stone2.photoUrl}
                                scrolling="no" seamless="seamless" 
                                className={`video-iframe${stone2.photoUrl.toLowerCase().includes('vision360.html') ? '' : (stone2.photoUrl.toLowerCase().includes('dnadiamond.net') || stone2.photoUrl.toLowerCase().includes('/dnadata/') ? '-dnad' : '-old')}`}
                            />
                        </div>
                    }
                </div>
                : <div/>
            }
        </>
    }

    return (
        <Modal 
            open={!!stone?.parcelName} 
            onClose={onClose} 
            styles={{modal: {maxWidth: '90vw', width: '70%', padding: '1rem'}}}
            center
        >
            <div className='similar--main' style={{width: '100%'}}>
                {renderLeftBar()}
                <div className='similar--pair'>
                    {renderStones(stone, selectedStone)}
                </div>
                {/*renderInventory()*/}
            </div>
        </Modal>
    )
}

export default connect(null, actions)(SimilarStones)