import React from 'react'
import PropTypes from 'prop-types'
import {ReactComponent as Archive} from '../../assets/icons/archive.svg'
import {ReactComponent as Archive3} from '../../assets/icons/archive-3.svg'
import {ReactComponent as Archive11} from '../../assets/icons/archive-11.svg'
import {ReactComponent as Barcode} from '../../assets/icons/barcode.svg'
import {ReactComponent as Certificate} from '../../assets/icons/certificate-7.svg'
import {ReactComponent as CheckMark} from '../../assets/icons/check-mark.svg'
import {ReactComponent as CreditCard5} from '../../assets/icons/credit-card-5.svg'
import {ReactComponent as Diamond3} from '../../assets/icons/diamond-3.svg'
import {ReactComponent as Diamond4} from '../../assets/icons/diamond-4.svg'
import {ReactComponent as Diamond4Alt} from '../../assets/icons/diamond-4-alt.svg'
import {ReactComponent as Diamond7} from '../../assets/icons/diamond-7.svg'
import {ReactComponent as Diamond8} from '../../assets/icons/diamond-8.svg'
import {ReactComponent as Diamond9} from '../../assets/icons/diamond-9.svg'
import {ReactComponent as Gear} from '../../assets/icons/gear.svg'
import {ReactComponent as Plus} from '../../assets/icons/plus.svg'
import {ReactComponent as PowerOn} from '../../assets/icons/power-on-off.svg'
import {ReactComponent as Shop3} from '../../assets/icons/shop-3.svg'
import {ReactComponent as ShoppingCart} from '../../assets/icons/shopping-cart.svg'
import {ReactComponent as User} from '../../assets/icons/user.svg'
import {ReactComponent as User6} from '../../assets/icons/user-6.svg'
import {ReactComponent as User29} from '../../assets/icons/user-29.svg'
import {ReactComponent as Customer9} from '../../assets/icons/customer-9.svg'
import {ReactComponent as XMark} from '../../assets/icons/x-mark.svg'
import {ReactComponent as Sync} from '../../assets/icons/sync.svg'
import {ReactComponent as Earrings} from '../../assets/icons/drop-earrings.svg'
import {ReactComponent as HeartsArrows} from '../../assets/icons/hearts-arrows.svg'
import {ReactComponent as HRA} from '../../assets/icons/hra.svg'
import {ReactComponent as Dialog} from '../../assets/vendorIcons/Dialog-icon-D.svg'
//import {ReactComponent as Disons} from '../../assets/vendorIcons/Disons_2.svg'
import disonsLink from '../../assets/vendorIcons/disons.png'

const getIconComponent = function(name) { 
    if (name === 'archive') return <Archive/>
    if (name === 'archive-3') return <Archive3/>
    if (name === 'archive-11') return <Archive11/>
    if (name === 'barcode') return <Barcode/>
    if (name === 'certificate') return <Certificate/>
    if (name === 'check-mark') return <CheckMark/>
    if (name === 'credit-card-5') return <CreditCard5/>
    if (name === 'diamond-3') return <Diamond3/>
    if (name === 'diamond-4') return <Diamond4/>
    if (name === 'diamond-4-alt') return <Diamond4Alt/>
    if (name === 'diamond-7') return <Diamond7/>
    if (name === 'diamond-8') return <Diamond8/>
    if (name === 'diamond-9') return <Diamond9/>
    if (name === 'gear') return <Gear/>
    if (name === 'plus') return <Plus/>
    if (name === 'power-on') return <PowerOn/>
    if (name === 'shop-3') return <Shop3/>
    if (name === 'shopping-cart') return <ShoppingCart/>
    if (name === 'user') return <User/>
    if (name === 'user-6') return <User6/>
    if (name === 'user-29') return <User29/>
    if (name === 'customer-9') return <Customer9/>
    if (name === 'x-mark') return <XMark/>
    if (name === 'sync') return <Sync/>
    if (name === 'drop-earrings') return <Earrings/>
    if (name === 'hearts-arrows') return <HeartsArrows/>
    if (name === 'hra') return <HRA/>
    if (name === 'vendor-0') return <Dialog/>
    if (name === 'vendor-1') return <img src={disonsLink}/>
    
    if (/^fa-/.test(name)) return <i className={`fas ${name}`} />

    return null;
}

function ListIcon(props) {
    return (
        <div className={`list-icon ${props.selected ? 'selected' : ''}${props.className ? ' '+props.className : ''}`} >
           {getIconComponent(props.icon)}
        </div>
    )
}

ListIcon.propTypes = {
    icon: PropTypes.string,
}

ListIcon.defaultProps = {
    icon: '',
}

export default ListIcon


