import React, {Component} from 'react'
import {connect} from 'react-redux'
import ReactTable from 'react-table-6'
import NumberFormat from 'react-number-format'
import * as actions from '../../actions'

class UserList extends Component {

    componentDidMount() {
        this.props.getAllUsers();
    }

    defaultFilterMethod = (filter, row, column) => {
        const rowVal = row[filter.pivotId || filter.id]
        if (column.altFilters) {
            let rowVals = [rowVal, ...column.altFilters.map(id => row[id] || (row._original && row._original[id]))];
            return rowVals.some(val => val !== undefined ? String(val).toLowerCase().includes(String(filter.value).toLowerCase()) : false)
                || rowVals.every(val => val===undefined);
        } else {
            return rowVal !== undefined ? String(rowVal).toLowerCase().includes(String(filter.value).toLowerCase()) : true
        }
    }

    numericSort = (a,b) => {
        a = Number(a); b = Number(b);
        return a-b;
    }

    render() {
        return <ReactTable
            data={this.props.userList}
            filterable={Array.isArray(this.props.userList) && this.props.userList.length > 5}
            resizable={false}
            className={'-highlight'}
            NoDataComponent={() => "0 users found"}
            defaultFilterMethod={this.defaultFilterMethod}
            minRows={0}
            //Can enable paging by commenting out the next line
            showPagination={false} pageSize={(this.props.userList).length}
            defaultSorted={[
                {id: "username", desc: false},
            ]}
            getTrProps={(state, row) => ({onClick: this.props.viewUser(row.original.username)})}
            columns={[
                {Header: 'User Name', accessor: 'username', minWidth: 90},
                {Header: 'First Name', accessor: 'firstName', minWidth: 140},
                {Header: 'Last Name', accessor: 'lastName', minWidth: 140},
                {Header: 'Email', accessor: 'email', minWidth: 130}
            ]}
        />
    }
}

function mapStateToProps(state) {
    return {
        userList: state.admin.userList || []
    }
}

export default connect(mapStateToProps, actions)(UserList);