import {
    SET_ALL_ATTRIBUTES
} from './types'

export default function(state={}, action) {
    switch (action.type) {
        case SET_ALL_ATTRIBUTES: {
            return {...state, ...action.payload}
        }
        default: {
            return state;
        }
    }
}