import React, { useEffect, useMemo, useState } from "react";
import Modal from "react-responsive-modal";

export default function CertModal({report}) {

    const [open, setOpen] = useState(false)
    const [visibleCertIndex, setVisibleCertIndex] = useState(0)
    useEffect(() => {
        setOpen(Boolean(report))
        setVisibleCertIndex(0)
    }, [report])

    const certs = useMemo(() => {
        const certs = []
        if (report?.pdfUrl) {
            certs.push({url: report.pdfUrl+'#zoom=FitH&navpanes=0', name: (report.lab ? report.lab+' ' : '')+'Certificate'})
        }
        if (report?.agsUrl) {
            certs.push({url: report.agsUrl+'#zoom=FitH&navpanes=0', name: 'AGS Addendum'})
        }
        return certs
    }, [report])

    const certSrc = visibleCertIndex<certs.length ? certs[visibleCertIndex].url : null

    return <Modal 
        open={open} 
        onClose={() => setOpen(false)}
        showCloseIcon={true}
        classNames={{modal: 'cert-embed'+(certs.length>1 ? ' multi' : ''), closeButton: 'modal-close-button'}}
        center
    >
        <div className='row'>
            {certs.length < 2 ? null : certs.map((cert,i) => {
                let className='btn cert-toggle'
                if (i!==visibleCertIndex) className += ' btn-secondary'
                if (i === 0) className += ' first-toggle'
                else if (i === certs.length-1) className += ' last-toggle'
                return <button key={cert.url} className={className} onClick={() => setVisibleCertIndex(i)}>{cert.name}</button>
            })}
        </div>
        {certSrc ? <iframe src={certSrc}/> : null}
    </Modal>
}