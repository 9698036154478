import React, {Component} from 'react'
import {connect} from 'react-redux'
import * as actions from '../../actions'
import UserList from './user_list'
import UserForm from './user_form'

class UserBase extends Component {

    state = {
        username: null,
        newUser: false
    }

    viewUser = (username) => (
        () => this.setState({username, newUser: false})
    )

    newUser = (user) => {
        this.props.createUser(user)
        .then(() => this.setState({username: null, newUser: false}))
    }

    editUser = (user) => {
        this.props.editUser(user)
        .then(() => this.setState({username: null, newUser: false}))
    }

    render() {
        if (this.state.username) {
            return (
                <UserForm 
                    username={this.state.username} 
                    onCancel={() => this.setState({username: null, newUser: false})} 
                    onSave={this.editUser} 
                />
            )
        } else if (this.state.newUser) {
            return (
                <UserForm 
                    onCancel={() => this.setState({username: null, newUser: false})} 
                    onSave={this.newUser} 
                />
            )
        } else {
            return (
                <>
                    <div className='row spaced'>
                        <button className='btn' onClick={() => this.setState({newUser: true, username: null})}>New</button>
                    </div>
                    <div className='row spaced'>
                        <UserList viewUser={this.viewUser} />
                    </div>
                </>
            )
        }
    }
}

export default connect(null, actions)(UserBase);