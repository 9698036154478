import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as actions from '../../actions'
import {success, error} from '../../components/common/toast'
import Loading from '../common/loading'
import LoginHeader from './loginHeader'
import LoginFooter from './loginFooter';

class Login extends Component {
    constructor() {
        super();
        this.state = {
            username: '',
            password: ''
        };
    }

    componentDidMount() {
        this.props.clearLoading()
    }

    handleChange = (e) => {
        this.setState({[e.target.name]: e.target.value});
    }

    signin = () => {
        this.props.signinUser(this.state.username, this.state.password)
        .then(() => this.props.history.push('/'))
        .catch(err => {
            error('Login Failed')
        })
    }

    keyUp = (e) => {
        if (e.keyCode === 13) {
            e.preventDefault();
            this.signin();
        }
    }

    render() {
        return (
            <div className='login-wrapper'>
                <LoginHeader {...this.props} />
                <div className='login-login-body'>
                    <div style={{width: '13.5rem', paddingBottom: '1.5rem'}}>
                        <label>Username</label>
                        <input name='username' className='login-control' onChange={this.handleChange} value={this.state.username} onKeyUp={this.keyUp} />
                    </div>
                    <div style={{width: '13.5rem', paddingBottom: '1.5rem'}}>
                        <label>Password</label>
                        <input name='password' type='password' className='login-control' onChange={this.handleChange} value={this.state.password} onKeyUp={this.keyUp} />
                    </div>
                    <div style={{paddingBottom: '1.5rem', paddingRight: '1.5rem'}}>
                        <button className='btn login-control' onClick={this.signin}>Sign In</button>
                    </div>
                </div>
                <LoginFooter />
                <Loading />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {}
}

export default connect(mapStateToProps, actions)(Login)