import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as actions from '../../actions'
import StoneTable, { formatStatus } from '../common/stone_table';
import ConfirmStonesModal from '../common/confirm_stones_modal'
import {OptionsFilter, filterOptions, NumberFilter, filterNumber} from '../common/filters';

class MyInventory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedStones: {},
            returnRemarks: null,
            saleRemarks: null
        };
    }

    componentDidMount() {
        this.refresh();
    }

    refresh = () => {
        this.props.getCurrentInventory()
        this.props.getDesires('open')
        .then(desires => desires.forEach(d => this.props.priceDesire(d)))
    }

    handleRowSelect = (selected) => {
        this.setState({selectedStones: selected});
    }

    getSelectedStones = () => {
        if (!this.state.selectedStones) return [];
        return Object.keys(this.state.selectedStones).filter(parcelName => this.state.selectedStones[parcelName]);
    }

    removeSelectedStone = parcelName => {
        this.setState({selectedStones: {...this.state.selectedStones, [parcelName]: false}})
    }

    returnStones = () => {
        const {stones, desires} = this.getSelectedStones().reduce(({stones,desires},parcelName) => {
            if (parcelName.startsWith('desire-')) return {stones, desires: [...desires, Number(parcelName.substring(7))]};
            else return {stones: [...stones, parcelName], desires}
        }, {stones: [], desires: []});
        if (!stones.length && !desires.length) return null;
        const promises = [];
        if (stones.length) {
            promises.push(this.props.returnStones(stones, this.state.returnRemarks))
        }
        if (desires.length) {
            const oldDesires = this.props.desires.filter(d => desires.some(desId => desId==d.desire.desireId))
            const newDesires = oldDesires.map(d => ({...d.desire, status: 'CLOSED'}));
            promises.push(this.props.updateDesire(newDesires))
        }
        return Promise.all(promises)
        .then(() => this.setState({returnRemarks: null, selectedStones: {}}))
        .then(this.refresh)
    }

    sellStones = () => {
        const selected = this.getSelectedStones();
        if (!selected.length) return;
        return this.props.sellStones(selected, this.state.saleRemarks)
        .then(() => this.setState({saleRemarks: null, selectedStones: {}}))
        .then(this.refresh)
    }

    StatusFilter = props => <OptionsFilter data={this.props.inventory || []} {...props} />

    renderInventory = () => {
        const selectedStones = this.getSelectedStones();
        const inventory = this.props.inventory || [];
        const desires = this.props.desires || [];
        return (
            <div>
                <div className='row spaced'>
                    <ConfirmStonesModal
                        confirm={this.returnStones} 
                        title='Confirm Return'
                        message={`Review ${selectedStones.length} stones and click "Return Stones" to confirm.`} 
                        confirmCaption='Return Stones'
                        deleteStone={this.removeSelectedStone}
                        trigger={onClick => <button className='btn' onClick={onClick} disabled={selectedStones.length==0}>Return</button>}
                        stones={
                            inventory.filter(stone => selectedStones.includes(stone.parcelName))
                            .concat(desires.filter(d => selectedStones.includes(d.parcelName)))
                        }
                        comment={this.state.returnRemarks}
                        updateComment={(e) => this.setState({returnRemarks: e.target.value})}
                    />
                    &nbsp;
                    <ConfirmStonesModal
                        confirm={this.sellStones} 
                        title='Confirm Sale'
                        message={`Review ${selectedStones.length} stones and click "Sell Stones" to confirm.`} 
                        confirmCaption='Sell Stones'
                        deleteStone={this.removeSelectedStone}
                        trigger={onClick => <button className='btn' onClick={onClick} disabled={selectedStones.length==0 || selectedStones.some(p => p.startsWith('desire-'))}>Sold</button>}
                        stones={inventory.filter(stone => selectedStones.includes(stone.parcelName))}
                        comment={this.state.saleRemarks}
                        updateComment={(e) => this.setState({saleRemarks: e.target.value})}
                    />
                </div>
                <StoneTable 
                    data={inventory.concat(desires)} 
                    extraColumns={[
                        {
                            Header: 'Status', 
                            id: 'status', 
                            accessor: formatStatus,
                            minWidth: 80,
                            Filter: this.StatusFilter, 
                            filterMethod: filterOptions,
                        },
                        /*{
                            Header: 'Days On Hand',
                            accessor: 'daysOnHand',
                            minWidth: 80,
                            Filter: NumberFilter,
                            filterMethod: filterNumber,
                            includeExcel: true
                        }*/
                    ]}
                    selectedRows={this.state.selectedStones}
                    onSelectRows={this.handleRowSelect}
                    exporter={this.props.excelExport}
                    tableId='current'
                />
            </div>
        )
    }

    render() {
        return (
            <div style={{width: '100%'}}>
                {this.renderInventory()}
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        inventory: state.inventory.my,
        desires: state.inventory.open_desires
    }
}

export default connect(mapStateToProps, actions)(MyInventory)