import React from 'react';
//import YouTube from 'react-youtube'
import LoginHeader from './loginHeader';
import LoginFooter from './loginFooter';

function News(props) {
    return <div className='login-wrapper'>
        <LoginHeader {...props} />
        <iframe className='static-page-frame' src='/files/news.html'/>
        <LoginFooter />
    </div>
}

export default News