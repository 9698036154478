import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as actions from '../../actions'
import {success, error} from '../../components/common/toast'
import Loading from '../common/loading'

class Login extends Component {
    constructor() {
        super();
        this.state = {
            username: '',
            password: ''
        };
    }

    componentDidMount() {
        this.props.clearLoading()
    }

    handleChange = (e) => {
        this.setState({[e.target.name]: e.target.value});
    }

    signin = () => {
        this.props.signinUser(this.state.username, this.state.password)
        .then(() => window.top.location.href = '/')
        .catch(err => {
            error('Login Failed')
        })
    }

    keyUp = (e) => {
        if (e.keyCode === 13) {
            e.preventDefault();
            this.signin();
        }
    }

    render() {
        return (
            <div className='login-form-wrapper'>
                <div className='login-login-body'>
                    <div>
                        <input name='username' placeholder='Username' className='login-control' onChange={this.handleChange} value={this.state.username} onKeyUp={this.keyUp} />
                    </div>
                    <div>
                        <input name='password' placeholder='Password' type='password' className='login-control' onChange={this.handleChange} value={this.state.password} onKeyUp={this.keyUp} />
                    </div>
                    <div>
                        <button className='btn login-control' onClick={this.signin}>Sign In</button>
                    </div>
                </div>
                <Loading />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {}
}

export default connect(mapStateToProps, actions)(Login)