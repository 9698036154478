import {
    ADD_STONE_TAB,
    CLOSE_TAB,
    SELECT_TAB,
    SELECT_EMBEDDED_TAB,
    SAVE_FILTERS
} from '../reducers/types'

export function addTab(stone) {
    return function(dispatch) {
        if (!stone.virtual) {
            dispatch({type: ADD_STONE_TAB, payload: {parcelName: stone.parcelName, header: stone.shortCertNo || stone.certNo}});
        }
    }
}

export function addJTab(jwl) {
    return function(dispatch) {
        dispatch({type: ADD_STONE_TAB, payload: {parcelName: jwl.parcelName, header: jwl.parcelName, type: 'J'}})
    }
}

export function addLGTab(jwl) {
    return function(dispatch) {
        dispatch({type: ADD_STONE_TAB, payload: {parcelName: jwl.parcelName, header: jwl.parcelName, type: 'LG'}})
    }
}

export function removeTab(parcelName) {
    return function(dispatch) {
        dispatch({type: CLOSE_TAB, payload: parcelName});
    }
}

export function selectTab(tabName) {
    return function(dispatch) {
        dispatch({type: SELECT_TAB, payload: tabName});
    }
}

export function selectEmbeddedTab(path) {
    return function(dispatch) {
        dispatch({type: SELECT_EMBEDDED_TAB, payload: path})
    }
}

export function setSavedFilters(id, filters) {
    return function(dispatch) {
        dispatch({type: SAVE_FILTERS, payload: {id, filters}})
    }
}