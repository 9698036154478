import React from 'react'
import NumberFormat from 'react-number-format'
import {ReactComponent as Earrings} from '../../assets/icons/drop-earrings.svg'

export default function ModelCard({jwl, deleteStone}) {

    const getIcon = () => {
        if (jwl.stillPhotoUrl) return <img className='photo-icon' src={jwl.stillPhotoUrl} />
        else return <Earrings style={{verticalAlign: 'middle'}} />
    }

    return (
        <div className='confirmation-container border stone-card confirmation-text'>
            <div className='row even-split' style={{marginBottom: '0.5rem', color: 'var(--primary-colour)'}}>
                <div className='align-top bold'>
                    {getIcon()}
                    <span>&nbsp;{jwl.name}</span>
                </div>
                <div className='right align-top semibold'>
                    <NumberFormat 
                        displayType='text'
                        decimalScale={2}
                        fixedDecimalScale={true} 
                        thousandSeparator={true}
                        prefix='$'
                        value={jwl.total}
                    />
                </div>
            </div>
            <div className='row'>
                <div className='caption20'>
                    <div className='align-top semibold'>Model:</div>
                    <div className='align-top'>{jwl.description}</div>
                </div>
            </div>
            <div className='row even-split'>
                <div className='caption40'>
                    <div className='align-top semibold'>Type:</div>
                    <div className='align-top'>{jwl.type}</div>
                </div>
                <div className='caption40'>
                    <div className='align-top semibold'>Style:</div>
                    <div className='align-top'>{jwl.subType}</div>
                </div>
            </div>
            <div className='row even-split'>
                <div className='caption40'>
                    <div className='align-top semibold'>Finish:</div>
                    <div className='align-top'>{jwl.finish}</div>
                </div>
                <div style={{verticalAlign: 'bottom'}}>
                    {
                        deleteStone
                        ? <button className='tab-button right' onClick={() => deleteStone(jwl.name)}><i className='fas fa-times-circle'/></button>
                        : null
                    }
                </div>
            </div>
        </div>
    )
}