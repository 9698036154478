import React, {Component} from 'react'
import {connect} from 'react-redux'
import ReactTable from 'react-table-6'
import moment from 'moment-timezone'
import Modal from 'react-responsive-modal'
import InvoiceModal from './invoice_modal'
import * as actions from '../../actions'

class BillingList extends Component {

    state = {
        month: null,
        pastMonth: false,
        uploadModal: false,
        invoicesOpen: false,
        billUploadFile: null,
        sendUploadEmail: false,
        billAllCustomersModalOpen: false,
        overridesFile: null
    };

    handleMonthChange = (e) => {
        const month = e.target.value;
        if (month) {
            this.props.getBillsByMonth(month.slice(0,4), month.slice(4));
        }
        this.setState({[e.target.name]: month, pastMonth: month && month<moment().format('YYYYMM')});
    }
    handleInvoiceFileChange = (e) => {
        this.setState({[e.target.name]: e.target.files[0], billUploadFileName: null})
    }
    handleChange = (e) => {
        this.setState({[e.target.name]: e.target.value})
    }
    handleCheckboxChange = (e) => {
        this.setState({[e.target.value]: e.target.checked})
    }

    defaultFilterMethod = (filter, row, column) => {
        const rowVal = row[filter.pivotId || filter.id]
        if (column.altFilters) {
            let rowVals = [rowVal, ...column.altFilters.map(id => row[id] || (row._original && row._original[id]))];
            return rowVals.some(val => val !== undefined ? String(val).toLowerCase().includes(String(filter.value).toLowerCase()) : false)
                || rowVals.every(val => val===undefined);
        } else {
            return rowVal !== undefined ? String(rowVal).toLowerCase().includes(String(filter.value).toLowerCase()) : true
        }
    }

    numericSort = (a,b) => {
        a = Number(a); b = Number(b);
        return a-b;
    }

    getMonthBills = () => ((this.state.month && this.props.bills[this.state.month]) || []).reduce((customers,bill) => (
        {...customers, [bill.customerId]: (customers[bill.customerId] || []).concat([bill])}
    ), {});

    renderMonthOptions = (pastOnly=false) => {
        const startDate = moment((this.props.customer && this.props.customer.firstAction) ? this.props.customer.firstAction : '2017-01-01').startOf('month');
        const endDate = pastOnly ? moment().add(-1, 'months') : moment();
        let date = startDate;
        const results = [];
        while (date < endDate) {
            results.push(<option key={date.unix()} value={date.format('YYYYMM')}>{date.format('MMM YYYY')}</option>)
            date = date.add(1, 'months');
        }
        return results.reverse();
    }

    create = (month, customerId) => {
        this.props.createBill(month.slice(0,4), month.slice(4), customerId);
    }

    downloadBill = (month, customerId) => {
        this.setState({invoicesOpen: customerId})
    }

    openUpload = (month, customerId) => {
        this.setState({uploadModal: customerId})
    }

    uploadBill = () => {
        if (this.state.month && this.state.uploadModal && this.state.billUploadFile) {
            this.props.uploadBill(this.state.month.slice(0,4), this.state.month.slice(4), this.state.uploadModal, this.state.billUploadFile, this.state.billUploadFileName || this.state.billUploadFile.name, this.state.sendUploadEmail)
            .then(() => {
                this.props.getBillsByMonth(this.state.month.slice(0,4), this.state.month.slice(4));
                this.setState({billUploadFile: null, uploadModal: false})
            })
        }
    }

    openHistory = (month, customerId) => {
        this.setState({historyModal: customerId})
    }

    handleOverridesFileChange = (e) => {
        const target = e.currentTarget || e.target
        if (target.files.length && target.files[0]) this.setState({overridesFile: target.files[0]})
        else this.setState({overridesFile: null})
    }

    getAllBilling = () => {
        this.props.generateAllCustomers(this.state.month.slice(0,4), this.state.month.slice(4), this.state.overridesFile)
        .then(() => this.setState({billAllCustomersModalOpen: false}))
    }

    render() {
        const monthBills = this.getMonthBills();
        return <>
            <div className='row spaced'>
                <select name='month' style={{height: '2rem'}} onChange={this.handleMonthChange} value={this.state.month ?? ''}>
                    <option value=''>Select a Month...</option>
                    {this.renderMonthOptions()}
                </select>
                <button className='btn' style={{marginLeft: '1rem'}} onClick={() => this.setState({billAllCustomersModalOpen: true})}>Generate All</button>
            </div>
            <ReactTable
                data={this.props.customerList}
                filterable={Array.isArray(this.props.customerList) && this.props.customerList.length > 5}
                resizable={false}
                className={'-highlight'}
                NoDataComponent={() => "0 customers found"}
                defaultFilterMethod={this.defaultFilterMethod}
                minRows={0}
                //Can enable paging by commenting out the next line
                showPagination={false} pageSize={(this.props.customerList).length}
                defaultSorted={[
                    {id: "name", desc: false},
                ]}
                columns={[
                    {Header: 'ID', accessor: 'customerId', minWidth: 40},
                    {Header: 'Name', accessor: 'name', minWidth: 140},
                    {
                        Header: 'Create', id: 'new', width: 75, filterable: false,
                        Cell: (rowInfo) => (<button className='list-button' disabled={!this.state.month} title='Create Bill' onClick={() => this.create(this.state.month, rowInfo.row.customerId)}><i className="fas fa-plus-square"/></button>)
                    },
                    {
                        Header: 'Save', id: 'upload', width: 75, filterable: false,
                        Cell: (rowInfo) => (<button className='list-button' disabled={!this.state.month} title='Upload Invoice' onClick={() => this.openUpload(this.state.month, rowInfo.row.customerId)}><i className="fas fa-upload"/></button>)
                    },
                    {
                        Header: 'Download', id: 'latest', width: 85, filterable: false,
                        Cell: (rowInfo) => (<button className='list-button' disabled={!this.state.month || !monthBills[rowInfo.row.customerId]} title='Download Invoice' onClick={() => this.downloadBill(this.state.month, rowInfo.row.customerId)}><i className="fas fa-file-download"/></button>)
                    }
                ]}
            />
            <Modal 
                open={Boolean(this.state.uploadModal)}
                onClose={() => this.setState({uploadModal: false, billUploadFile: null})}
                styles={{modal: {width: '35rem', padding: '1rem'}}}
                center
            >
                {
                    this.state.month && this.state.uploadModal ? 
                    <>
                        <div className='row spaced'>
                            <h4>
                                Upload a bill for customer{' '}
                                {(this.props.customerList.find(c => c.customerId==this.state.uploadModal) || {name: this.state.uploadModal}).name}
                                {' '}in the month{' '}
                                {moment([this.state.month.slice(0,4), this.state.month.slice(4)-1, 1]).format("MMMM YYYY")}?
                            </h4>
                        </div>
                        <div className='row spaced'>
                            <input type='file' name='billUploadFile' style={{width: '90%'}} onChange={this.handleInvoiceFileChange} />
                        </div>
                        <div className='row spaced'>
                            <input 
                                type='text' 
                                name='billUploadFileName' 
                                style={{width: '90%'}}
                                onChange={this.handleChange} 
                                disabled={!this.state.billUploadFile?.name}
                                value={this.state.billUploadFileName || this.state.billUploadFile?.name || ''}
                            />
                        </div>
                        <div className='row spaced'>
                            <input
                                type='checkbox'
                                value='sendUploadEmail'
                                onChange={this.handleCheckboxChange}
                                checked={this.state.sendUploadEmail}
                            />
                            Email Customer
                        </div>
                        <div className='row spaced'>
                            <button className='btn' onClick={this.uploadBill}>
                                {
                                    (monthBills[this.state.uploadModal] || []).some(i => i.fileName===(this.state.billUploadFileName || this.state.billUploadFile?.name))
                                    ? 'Replace Invoice' 
                                    : 'Save New Invoice'
                                }
                            </button>
                        </div>
                    </>
                    : null
                }
            </Modal>
            <Modal
                open={!!this.state.billAllCustomersModalOpen}
                onClose={() => this.setState({billAllCustomersModalOpen: false})}
                style={{modal: {width: '60%', padding: '1rem'}}}
                center
            >
                <div className='row spaced'>
                    <select name='month' style={{height: '2rem'}} onChange={this.handleMonthChange} value={this.state.month ?? ''}>
                        <option value=''>Select a Month...</option>
                        {this.renderMonthOptions()}
                    </select>
                </div>
                <div className='row spaced'>
                    Upload Overrides&emsp;
                    <input type="file" onChange={this.handleOverridesFileChange} />
                </div>
                <div className='row spaced'>
                    <button className='btn' disabled={this.state.overridesFile==null || !this.state.month} onClick={this.getAllBilling}>Generate</button>
                </div>
            </Modal>
            <Modal 
                open={!!this.state.invoicesOpen} 
                onClose={() => this.setState({invoicesOpen: false})} 
                styles={{modal: {width: '60%', padding: '1rem'}}}
                center
            >
                <InvoiceModal customer={this.props.customerList.find(c => c.customerId == this.state.invoicesOpen)} initialDate={this.state.month} />
            </Modal>
            {/*<Modal
                open={Boolean(this.state.historyModal)}
                onClose={() => this.setState({historyModal: false})}
                styles={{modal: {width: '75%', padding: '1rem'}}}
                center
            >
                {
                    this.state.month && this.state.historyModal && monthBills[this.state.historyModal] && monthBills[this.state.historyModal].length ? 
                    <>
                        <h4>
                            <div className='row spaced'>
                                {(this.props.customerList.find(c => c.customerId==this.state.historyModal) || {name: 'Customer '+this.state.historyModal}).name}
                                {' '}bills for the month{' '}
                                {moment([this.state.month.slice(0,4), this.state.month.slice(4)-1, 1]).format("MMMM YYYY")}:
                            </div>
                        </h4>
                        <ReactTable
                            data={monthBills[this.state.historyModal]}
                            filterable={false}
                            resizable={false}
                            className={'-highlight'}
                            NoDataComponent={() => "0 bills found"}
                            defaultFilterMethod={this.defaultFilterMethod}
                            minRows={0}
                            //Can enable paging by commenting out the next line
                            showPagination={false} pageSize={(this.props.customerList).length}
                            defaultSorted={[
                                {id: "invoiceId", desc: false},
                            ]}
                            columns={[
                                {Header: 'ID', accessor: 'invoiceId', minWidth: 40},
                                {Header: 'Upload Date', accessor: 'uploadDate', minWidth: 140, Cell: rowInfo => moment(rowInfo.value).format('MMM DD, YYYY h:mm A')},
                                {Header: 'Current', accessor: 'active', minWidth: 50, Cell: rowInfo => rowInfo.value ? 'Yes' : 'No'},
                                {
                                    Header: 'Download', id: 'button', width: 50, filterable: false,
                                    Cell: (rowInfo) => (<button className='list-button' onClick={() => this.props.specificBill(rowInfo.row.invoiceId)}><i className="fas fa-file-download"/></button>)
                                }
                            ]}
                        />
                    </>
                    : null
                }
            </Modal>*/}
        </>
    }
}

function mapStateToProps(state) {
    return {
        customerList: state.admin.customerList || [],
        bills: state.billing.bills || {}
    }
}

export default connect(mapStateToProps, actions)(BillingList);