import React from 'react';
//import YouTube from 'react-youtube'
import diamondLogo from '../../assets/diamond-rose.png'
import LoginHeader from './loginHeader'
import LoginFooter from './loginFooter'

function Home(props) {
    
    return <div classNme='login-wrapper'>
        <LoginHeader {...props} />
        {/*<iframe className='static-page-frame' src='http://dialogvault.com/files/home.html'/>*/}
        <div style={{
            width: '100%', 
            marginTop: '1rem',
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'column',
            justifyContent: 'start',
            alignItems: 'center',
            alignContent: 'center'
        }}>
            <div className='login-twocol first-block' style={{
                gridTemplateColumns: '1fr 65%',
                background: 'var(--primary-colour)',
            }}>
                <div style={{padding: '1rem 0', overflow: 'hidden', width: '100%', height: '100%'}}>
                    <img src={diamondLogo} style={{width: '117%', position: 'relative', left: '-20%', height: '100%', objectFit: 'contain'}} alt='' />
                </div>
                <div className='main-login-text login-block-beside-image right-col' style={{
                    color: 'white',
                    margin: '0'
                }}>
                    <div style={{margin: '1.5rem', fontSize: '2.7rem'}}>The world's first inventory subscription service</div>
                    <div style={{fontSize: 'large', fontWeight: '400', margin: '1.5rem'}}>Dialog is here to help you simplify your inventory so that you can focus on growing your business and connecting with your customers.</div>
                    <div style={{margin: '1.5rem', fontSize: 'large'}}>
                        <a href="mailto:info@dialogvault.com?subject=Requesting a demo of Dialog&body=Hello,%0A%0AI'd like to learn more about Dialog.%0A%0APlease get in touch with me about a demo." className='login-control btn' style={{background: 'white', color: 'var(--primary-colour)'}} target='_blank'>
                            Request a Demo
                        </a>
                    </div>
                </div>
            </div>

            <div className='login-twocol' style={{
                gridTemplateColumns: '60% 1fr'
            }}>
                <div className='main-login-text login-block-beside-image left-col' style={{
                    color: 'var(--primary-colour)'
                }}>
                    <div style={{margin: '1.5rem', fontWeight: '400'}}>Our mission is to acquire beautiful diamonds and make them available to the best jewellers in the world.</div>
                    <div style={{fontSize: 'large', fontWeight: '400', margin: '1.5rem'}}>We invest our capital in the right inventory, at the right time, at the right value. You simply subscribe to Dialog, choose your inventory levels, and let us merchandise your stores with no risk to you.</div>
                </div>
                <div style={{margin: '0', overflow: 'hidden', width: '100%', height: '100%', position: 'relative'}}>
                    <img src='http://files.hragroup.com/stock-photo-1.jpeg' style={{
                        position: 'absolute',
                        height: '100%', 
                        top: '0',
                        width: '70%',
                        right: '0',
                        objectFit: 'contain'
                    }} alt='' />
                    <img src='http://files.hragroup.com/Dialog-Diamond-Chrome-V3.gif' style={{
                        position: 'absolute', 
                        height: '100%', 
                        top: '0',
                        width: '60%',
                        left: '0',
                        objectFit: 'contain'
                    }} alt='' />
                </div>
            </div>

            <div className='login-twocol' style={{
                gridTemplateColumns: '1fr 65%',
                background: 'var(--alt-background)',
            }}>
                <div style={{margin: '0', overflow: 'hidden', width: '100%', height: '100%', position: 'relative'}}>
                    <img src='http://files.hragroup.com/stock-photo-2.jpeg' style={{
                        position: 'absolute',
                        height: '100%', 
                        top: '0',
                        width: '80%',
                        left: '0',
                        objectFit: 'contain'
                    }} alt='' />
                    <img src='http://files.hragroup.com/Dialog-List-Chrome.png' style={{
                        position: 'absolute', 
                        height: '100%', 
                        top: '0',
                        width: '70%',
                        right: '0',
                        objectFit: 'contain'
                    }} alt='' />
                </div>
                <div className='main-login-text login-block-beside-image right-col' style={{
                    color: 'var(--primary-colour)',
                }}>
                    <div style={{margin: '1.5rem', fontWeight: '400'}}>We encourage returns, exchanges and feedback.</div>
                    <div style={{fontSize: 'large', fontWeight: '400', margin: '1.5rem'}}>Our product is here to serve you and your customers. By transferring the inventory cost and risk to Dialog, you can deploy your funds towards higher yielding initiatives and unlock capital for other opportunities.</div>
                </div>
            </div>

            <div className='main-login-text' style={{
                width: '100%',
                margin: '0',
                padding: '0',
                textAlign: 'center',
                background: 'var(--lighter-background)'
            }}>
                <div style={{margin: '5rem 0 1.5rem 0', fontSize: 'larger', fontWeight: '400'}}>We invite you to join us.</div>
                <div style={{margin: '1.5rem 0 5rem 0', fontSize: 'initial'}}>
                    <a href="mailto:info@dialogvault.com" className='btn login-control' target='_blank'>
                        Get in Touch
                    </a>
                </div>
            </div>
            
        </div>
        <LoginFooter />
    </div>
}

export default Home