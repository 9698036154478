import React, {Component} from 'react'
import {connect} from 'react-redux';
import * as actions from '../../actions'
import NumberFormat from 'react-number-format'
import StoneTable from '../common/stone_table'

class ReplenishInventory extends Component {

    addToCart = (parcelName) => {
        this.props.addToCart(parcelName)
        .then(() => this.props.getAvailableInventory())
    }

    renderInventory = () => {
        const inventory = this.props.inventory || [];
        const stone = this.props.stone || {};
        const label = inventory.length ? 'Replacement options' : 'There are no close replacements'
        
        return <>
            <div className='replenish__header'>
                <div className='row replenish__header--title'>{`${label} for`} <span className="semibold">{`${stone.parcelName}`}</span></div>
                <div className='row'>
                    <span className="replenish__header--spec">
                        <NumberFormat displayType='text' value={stone.weight} suffix='ct' decimalScale={2} fixedDecimalScale={true} />
                    </span>
                    <span className="replenish__header--spec">{stone.colour}</span>
                    <span className="replenish__header--spec">{stone.clarity}</span>
                    <span className="replenish__header--spec">{((this.props.shapes||[]).find(s => s.shapeCode==stone.shapeCode)||{})[stone.cut=='BL' ? 'blDescription' : 'description'] || stone.shapeCode}</span>
                </div>
            </div>
            <StoneTable 
                data={inventory}
                extraColumns={[
                    {
                        Header: '',
                        id: 'cartBtn',
                        width: 50,
                        sortable: false,
                        filterable: false,
                        Cell: (row) => row.original ? (
                            <button
                                title="Add to Cart"
                                disabled={row.original.inCart}
                                className="list-button"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    this.addToCart(row.original.parcelName);
                                }}
                            >{
                                row.original.inCart ?
                                <i className='fa fa-check-circle' /> : 
                                <i className='fa fa-shopping-cart' />
                            }</button>
                        ) : null
                    }
                ]}
                hideTier={true}
                exporter={this.props.excelExport}
                extraTableProps={{
                    defaultSorted: [],
                    sortable: false
                }}
            />
        </>
    }

    render() {
        return (
            <div style={{width: '100%'}}>
                {this.renderInventory()}
            </div>
        )
    }
}

const weights = {
    shape: 1000,
    lab: 2.5,
    weight: 30,
    colour: 1,
    clarity: 1,
    fluor: 0.5,
    cut: 0.5,
    polish: 0.5,
    symmetry: 0.5,
    price: 0.001
}
const MAX_DIFF = 10;

function mapStateToProps(state, {stone}) {
    const {colours, clarities, fluorescences, grades} = state.attributes;

    if (Array.isArray(state.inventory.available) && stone && colours && clarities && fluorescences && grades) {
        const colour = colours.find(c => c.colour==stone.colour) || {};
        const clarity = clarities.find(c => c.clarity==stone.clarity) || {};
        const fluor = fluorescences.find(f => f.fluorescence==stone.fluorescence) || {};
        const cut = grades.find(g => g.grade==stone.cut) || {};
        const pol = grades.find(g => g.grade==stone.polish) || {};
        const sym = grades.find(g => g.grade==stone.symmetry) || {};

        function diffRank(s) {
            if (!s) return Number.MAX_SAFE_INTEGER;
            let diff = 0;//console.log(0,s.parcelName)
            if (s.shapeCode != stone.shapeCode) diff += weights.shape;//console.log(1,diff)
            if (diff > MAX_DIFF) return diff;
            if (s.lab != stone.lab) diff += weights.lab;//console.log(2,diff)
            if (diff > MAX_DIFF) return diff;
            diff += Math.abs(stone.weight-s.weight)/stone.weight*weights.weight;//console.log(3,diff)
            if (diff > MAX_DIFF) return diff;
            const scol = colours.find(c => c.colour==s.colour) || {};
            if (scol.isFancy!=colour.isFancy || (scol.isFancy && scol.colour!=colour.colour)) diff += colours.length*weights.colour;
            else if (!scol.isFancy) diff += Math.abs(scol.order-colour.order)*weights.colour;//console.log(4,diff)
            if (diff > MAX_DIFF) return diff;
            const sclr = clarities.find(c => c.clarity==s.clarity) || {};
            if (sclr.isFancy!=clarity.isFancy || (sclr.isFancy && sclr.clarity!=clarity.clarity)) diff += clarities.length*weights.clarity;
            else if (!sclr.isFancy) diff += Math.abs(sclr.order-clarity.order)*weights.clarity;//console.log(5,diff)
            if (diff > MAX_DIFF) return diff;
            const sflr = fluorescences.find(f => f.fluorescence==s.fluorescence) || {};
            if (sflr.isFancy!=fluor.isFancy || (sflr.isFancy && sflr.fluorescence!=fluor.fluorescence)) diff += fluorescences.length*weights.fluor;
            else if (!sflr.isFancy) diff += Math.abs(sflr.order - fluor.order)*weights.fluor;//console.log(6,diff)
            if (diff > MAX_DIFF) return diff;
            const scut = grades.find(g => g.grade==s.cut) || {};
            if (scut.gradeSet!=cut.gradeSet) diff += grades.length*weights.cut;
            else if (Object.keys(scut).length && Object.keys(cut).length) diff += Math.abs(scut.order-cut.order)*weights.cut;//console.log(7,diff)
            if (diff > MAX_DIFF) return diff;
            const spol = grades.find(g => g.grade==s.polish) || {};
            if (spol.gradeSet!=pol.gradeSet) diff += grades.length*weights.polish;
            else diff += Math.abs(spol.order-pol.order)*weights.polish;//console.log(8,diff)
            if (diff > MAX_DIFF) return diff;
            const ssym = grades.find(g => g.grade==s.symmetry) || {};
            if (ssym.gradeSet!=sym.gradeSet) diff += grades.length*weights.symmetry;
            else diff += Math.abs(ssym.order-sym.order)*weights.symmetry;//console.log(9,diff)
            if (diff > MAX_DIFF) return diff;
            diff += Math.abs(stone.total-s.total)*weights.price;//console.log(10,diff)
            return diff;
        }
        function sortFunc(x, y) {
            return diffRank(x) - diffRank(y);
        }

        const inventory = state.inventory.available.filter(s => diffRank(s) < MAX_DIFF);
        inventory.sort(sortFunc);
        return {
            inventory,
            shapes: state.attributes.shapes
        }
    } else return {
        inventory: state.inventory.available,
        shapes: state.attributes.shapes
    }
}

export default connect(mapStateToProps, actions)(ReplenishInventory)