import {
    SET_AVAILABLE,
    SET_MY_INVENTORY,
    SET_PAST_INVENTORY,
    SET_CART,
    ADD_DETAILS,
    SET_CART_DESIRES,
    SET_OPEN_DESIRES,
    SET_CLOSED_DESIRES,
    ADD_DESIRE_PRICE
} from './types'

export default function(state={}, action) {
    switch (action.type) {
        /*case ALL_INVENTORY: {
            const {available, my} = action.payload.reduce(({available,my},stone) => {
                if (stone.customerId==null) return {available: available.concat([stone]), my};
                else return {available, my: my.concat([stone])};
            }, {available: [], my: []});
            return {...state, available, my}
        }*/
        case SET_AVAILABLE: {
            return {...state, available: action.payload}
        }
        case SET_MY_INVENTORY: {
            return {...state, my: action.payload}
        }
        case SET_PAST_INVENTORY: {
            return {...state, past: action.payload}
        }
        case SET_CART: {
            return {...state, cart: action.payload}
        }
        case ADD_DETAILS: {
            if (!action.payload || !action.payload.parcelName) return state;
            return {...state, details: {...state.details, [action.payload.parcelName]: action.payload}}
        }
        case SET_CART_DESIRES: {
            return {...state, cart_desires: action.payload}
        }
        case SET_OPEN_DESIRES: {
            return {...state, open_desires: action.payload}
        }
        case SET_CLOSED_DESIRES: {
            return {...state, closed_desires: action.payload}
        }
        case ADD_DESIRE_PRICE: {
            if (!action.record || !action.record.desire) return state;
            const arrayName = action.record.desire.status==='CART' ? 'cart_desires' : (action.record.desire.status==='ACTIVE' ? 'open_desires' : 'closed_desires');
            const foundRecord = (state[arrayName] || []).indexOf(action.record);
            if (foundRecord < 0) return state;
            const arr = state[arrayName].slice();
            if (action.payload.error) arr[foundRecord] = {...state[arrayName][foundRecord], total: 'Unknown'}
            else arr[foundRecord] = {...state[arrayName][foundRecord], total: `${action.payload.minimum || ''} - ${action.payload.maximum || ''}`}
            return {...state, [arrayName]: arr}
        }
        default: {
            return state;
        }
    }
}