import {
    GET_MONTH_BILLS
} from './types'

export default function(state={}, action) {
    switch (action.type) {
        case GET_MONTH_BILLS: {
            return {...state, bills: {...state.bills, [action.payload.month]: action.payload.data}}
        }
        default: {
            return state;
        }
    }
}