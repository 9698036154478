import React from 'react'
import whiteDLogo from '../../assets/dialogd-white.png'

function LoginFooter() {
    return (
        <div className='main-login-text' className='login-footer'>
            <span className='login-footer-logo'><img src={whiteDLogo} /></span>
            <div className='login-footer-about mobile-only'>
                The Company<br/>
                <a className='login-footer-link' href='/about'>About</a><br/>
                <a className='login-footer-link' href='/news'>News</a>
            </div>
            <div className='login-footer-connect'>
                Connect with us &nbsp;
                <br className='desktop-only'/>
                <a className='login-footer-link' href='https://www.facebook.com/dialogvault'><i className="fab fa-facebook"/></a>&nbsp;
                <a className='login-footer-link' href='https://www.instagram.com/dialogvault/'><i className="fab fa-instagram"/></a>&nbsp;
                <a className='login-footer-link' href='https://www.linkedin.com/company/dialogvault/'><i className="fab fa-linkedin-in"/></a>
            </div>
            <span className='login-footer-copyright'>&#169; Dialog Solutions Inc.</span>
        </div>
    )
}

export default LoginFooter
