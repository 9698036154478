import axios from 'axios'
import Excel from 'exceljs'
import FileSaver from 'file-saver'
import {
    START_LOADING,
    STOP_LOADING,
    SET_AVAILABLE_J,
    SET_J_MODELS,
    SET_ALL_J_MODELS,
    SET_MY_J,
    SET_PAST_J,
    SET_CART_J,
    ADD_DETAILS_J,
    SET_MODEL_REQUESTS
} from '../reducers/types'
import {success, error} from '../components/common/toast'
import {ROOT_URL} from './config'

export function getAvailableJewellery() {
    return function(dispatch) {
        dispatch({type: START_LOADING, payload: 'Retrieving Jewellery'})
        return axios({
            method: 'POST',
            url: `${ROOT_URL}/jewellery/available`
        })
        .finally(() => dispatch({type: STOP_LOADING, payload: 'Retrieving Jewellery'}))
        .then(res => {
            dispatch({type: SET_AVAILABLE_J, payload: res.data});
        })
        .catch(err => {
            error(err)
            return Promise.reject(err);
        })
    }
}

export function getCurrentJewellery() {
    return function(dispatch) {
        dispatch({type: START_LOADING, payload: 'Retrieving Jewellery'})
        return axios({
            method: 'POST',
            url: `${ROOT_URL}/jewellery/customer`
        })
        .finally(() => dispatch({type: STOP_LOADING, payload: 'Retrieving Jewellery'}))
        .then(res => {
            dispatch({type: SET_MY_J, payload: res.data});
        })
        .catch(err => {
            error(err)
            return Promise.reject(err);
        })
    }
}

export function getPastJewellery() {
    return function(dispatch) {
        dispatch({type: START_LOADING, payload: 'Retrieving Jewellery'})
        return axios({
            method: 'POST',
            url: `${ROOT_URL}/jewellery/past`
        })
        .finally(() => dispatch({type: STOP_LOADING, payload: 'Retrieving Jewellery'}))
        .then(res => {
            dispatch({type: SET_PAST_J, payload: res.data});
        })
        .catch(err => {
            error(err)
            return Promise.reject(err);
        })
    }
}

export function addToJewelleryCart(parcelNames) {
    return function(dispatch) {
        dispatch({type: START_LOADING, payload: 'Updating Jewellery'})
        return axios({
            method: 'POST',
            url: `${ROOT_URL}/jewellery/cart_stones`,
            data: {parcelNames: Array.isArray(parcelNames) ? parcelNames : [parcelNames]}
        })
        .finally(() => dispatch({type: STOP_LOADING, payload: 'Updating Jewellery'}))
        .catch(err => {
            error(err);
            return Promise.reject(err);
        })
    }
}

export function getJewelleryCart() {
    return function(dispatch) {
        dispatch({type: START_LOADING, payload: 'Retrieving Cart'})
        return axios({
            method: 'POST',
            url: `${ROOT_URL}/jewellery/cart`
        })
        .finally(() => dispatch({type: STOP_LOADING, payload: 'Retrieving Cart'}))
        .then(res => {
            dispatch({type: SET_CART_J, payload: res.data});
        })
        .catch(err => {
            error(err)
            return Promise.reject(err);
        })
    }
}

export function removeFromJewelleryCart(parcelNames) {
    return function(dispatch) {
        dispatch({type: START_LOADING, payload: 'Updating Cart'})
        return axios({
            method: 'POST',
            url: `${ROOT_URL}/jewellery/uncart_stones`,
            data: {parcelNames: Array.isArray(parcelNames) ? parcelNames : [parcelNames]}
        })
        .finally(() => dispatch({type: STOP_LOADING, payload: 'Updating Cart'}))
        .catch(err => {
            error(err);
            return Promise.reject(err);
        })
    }
}

export function requestJewellery(parcelNames, remarks) {
    return function(dispatch) {
        dispatch({type: START_LOADING, payload: 'Sending Request'})
        return axios({
            method: 'POST',
            url: `${ROOT_URL}/jewellery/request_stones`,
            data: {
                parcelNames: Array.isArray(parcelNames) ? parcelNames : [parcelNames],
                remarks: remarks ? remarks : undefined
            }
        })
        .finally(() => dispatch({type: STOP_LOADING, payload: 'Sending Request'}))
        .catch(err => {
            error(err);
            return Promise.reject(err);
        })
    }
}

export function returnJewellery(parcelNames, remarks) {
    return function(dispatch) {
        dispatch({type: START_LOADING, payload: 'Signalling Return'})
        return axios({
            method: 'POST',
            url: `${ROOT_URL}/jewellery/return`,
            data: {
                parcelNames: Array.isArray(parcelNames) ? parcelNames : [parcelNames],
                remarks: remarks ? remarks : undefined
            }
        })
        .finally(() => dispatch({type: STOP_LOADING, payload: 'Signalling Return'}))
        .catch(err => {
            error(err);
            return Promise.reject(err);
        })
    }
}

export function sellJewellery(parcelNames, remarks) {
    return function(dispatch) {
        dispatch({type: START_LOADING, payload: 'Making Sale'})
        return axios({
            method: 'POST',
            url: `${ROOT_URL}/jewellery/sale`,
            data: {
                parcelNames: Array.isArray(parcelNames) ? parcelNames : [parcelNames],
                remarks: remarks ? remarks : undefined
            }
        })
        .finally(() => dispatch({type: STOP_LOADING, payload: 'Making Sale'}))
        .catch(err => {
            error(err);
            return Promise.reject(err);
        })
    }
}

export function getJewelleryDetails(parcelName) {
    return function(dispatch) {
        dispatch({type: START_LOADING, payload: 'Retrieving Jewellery'})
        return axios({
            method: 'POST',
            url: `${ROOT_URL}/jewellery/details`,
            data: {parcelName}
        })
        .finally(() => dispatch({type: STOP_LOADING, payload: 'Retrieving Jewellery'}))
        .then(res => {
            dispatch({type: ADD_DETAILS_J, payload: res.data});
        })
        .catch(err => {
            error(err);
            return Promise.reject(err);
        })
    }
}

export function deleteLastJewelleryHistory(historyRow) {
    return function(dispatch) {
        dispatch({type: START_LOADING, payload: 'Cancelling History'})
        return axios({
            method: 'POST',
            url: `${ROOT_URL}/jewellery/undo`,
            data: {
                parcelName: historyRow.parcelName,
                action: historyRow.type
            }
        })
        .finally(() => dispatch({type: STOP_LOADING, payload: 'Cancelling History'}))
        .then(res => {
            dispatch({type: ADD_DETAILS_J, payload: res.data});
        })
        .catch(err => {
            error(err);
            return Promise.reject(err);
        })
    }
}

export function backdateJewelleryHistory(historyRow, newDate) {
    return function(dispatch) {
        dispatch({type: START_LOADING, payload: 'Changing Date'})
        return axios({
            method: 'POST',
            url: `${ROOT_URL}/jewellery/change_date`,
            data: {
                parcelName: historyRow.parcelName,
                action: historyRow.type,
                newDate
            }
        })
        .finally(() => dispatch({type: STOP_LOADING, payload: 'Changing Date'}))
        /*.then(res => {
            dispatch({type: ADD_DETAILS, payload: res.data});
        })*/
        .catch(err => {
            error(err);
            return Promise.reject(err);
        })
    }
}

export function getAvailableJewelleryModels() {
    return function(dispatch) {
        dispatch({type: START_LOADING, payload: 'Retrieving Jewellery'})
        return axios({
            method: 'POST',
            url: `${ROOT_URL}/jewellery/available_models`
        })
        .finally(() => dispatch({type: STOP_LOADING, payload: 'Retrieving Jewellery'}))
        .then(res => {
            if (Array.isArray(res.data) && res.data.length) res.data.sort((a,b) => (a.availableTotalQty===0) - (b.availableTotalQty===0))
            dispatch({type: SET_J_MODELS, payload: res.data});
        })
        .catch(err => {
            error(err)
            return Promise.reject(err);
        })
    }
}

export function getJewelleryModels() {
    return function(dispatch) {
        dispatch({type: START_LOADING, payload: 'Retrieving Jewellery'})
        return axios({
            method: 'POST',
            url: `${ROOT_URL}/jewellery/all_models`
        })
        .finally(() => dispatch({type: STOP_LOADING, payload: 'Retrieving Jewellery'}))
        .then(res => {
            if (Array.isArray(res.data) && res.data.length) res.data.sort((a,b) => (a.availableTotalQty===0) - (b.availableTotalQty===0))
            dispatch({type: SET_ALL_J_MODELS, payload: res.data});
        })
        .catch(err => {
            error(err)
            return Promise.reject(err);
        })
    }
}

export function requestJewelleryModels(models, remarks) {
    return function(dispatch) {
        dispatch({type: START_LOADING, payload: 'Requesting Jewellery'})
        return axios({
            method: 'POST',
            url: `${ROOT_URL}/jewellery/request_models`,
            data: {remarks, parcelNames: (Array.isArray(models) ? models : [models]).map(model => typeof model === 'string' ? model : model?.name)}
        })
        .finally(() => dispatch({type: STOP_LOADING, payload: 'Requesting Jewellery'}))
        .catch(err => {
            error(err);
            return Promise.reject(err);
        })
    }
}

export function editJewelleryModel(model) {
    return function(dispatch) {
        dispatch({type: START_LOADING, payload: 'Updating Model'})
        return axios({
            method: 'POST',
            url: `${ROOT_URL}/jewellery/update_model`,
            data: model
        })
        .finally(() => dispatch({type: STOP_LOADING, payload: 'Updating Model'}))
        .catch(err => {
            error(err);
            return Promise.reject(err);
        })
    }
}

export function setModelsRequestQty(requestQty) {
    return function(dispatch) {
        dispatch({type: SET_MODEL_REQUESTS, payload: requestQty})
    }
}

export function excelJExport(jwls, extraColumns) {
    return function(dispatch) {
        const workbook = new Excel.Workbook();
        workbook.creator = 'Dialog';
        workbook.created = new Date();
        const sheet = workbook.addWorksheet('Dialog Jewellery Inventory');
        let columns = [
            {header: 'Parcel Name', key: 'parcelName', width: 19},
            //{header: 'Model', key: 'model', width: 15},
            {header: 'Model', key: 'modelDescription', width: 21},
            {header: 'Type', key: 'modelType', width: 15},
            {header: 'Finish', key: 'modelFinish', width: 15},
            {header: 'Total Weight', key: 'weight', width: 8, style: {numFmt: '0.00#'}},
            {header: 'Price', key: 'total', width: 12, style: {numFmt: '$#,##0.00'}},
            {header: 'Status', key: 'status', width: 12}
        ]
        if (Array.isArray(extraColumns) && extraColumns.length) {
            columns = columns.concat(extraColumns.map(col => ({
                header: col.Header,
                key: col.accessor,
                width: col.minWidth / 5,
                excelCell: col.excelCell
            })))
        }
        sheet.columns = columns;
        sheet.addRows(jwls);

        for (let col of columns.filter(c => c.excelCell)) {
            const fileColumn = sheet.getColumn(col.key);
            if (!fileColumn) continue;
            fileColumn.eachCell((cell, row) => {
                if (row > 1 && cell.value) cell.value = col.excelCell(cell.value);
            })
        }

        return workbook.xlsx.writeBuffer()
        .then(buffer => {
            FileSaver.saveAs(new Blob([buffer], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"}), `Dialog Export.xlsx`);
        })
    }
}