import axios from 'axios'
import Cookies from 'js-cookie'
import {store, persistor} from '../store'
import {
    START_LOADING,
    STOP_LOADING,
    SET_USER,
    SET_AUTH_TIME,
    LOG_OUT,
    SET_CUSTOMER
} from '../reducers/types'
import {success, error, unblock401, clearAlerts} from '../components/common/toast'
import {ROOT_URL} from './config'

export function signinUser(username, password) {
    return function(dispatch) {
        unblock401();
        dispatch({type: START_LOADING, payload: 'Signing In'})
        return axios({
            method: 'post',
            url: `${ROOT_URL}/login`,
            data: {username, password}
        })
        .finally(() => dispatch({type: STOP_LOADING, payload: 'Signing In'}))
        .then(res => {
            Cookies.set('session', res.data.token);
            dispatch({type: SET_AUTH_TIME});
            dispatch({type: SET_USER, payload: res.data.user});
            clearAlerts();
        })
        .catch(err => {
            console.log(err)
            dispatch({type: LOG_OUT})
            return Promise.reject(err);
        })
    }
}

export function confirmSignin() {
    return function(dispatch) {
        dispatch({type: START_LOADING, payload: 'Confirming Session'})
        return axios({
            method: 'POST',
            url: `${ROOT_URL}/login/check`
        })
        .finally(() => dispatch({type: STOP_LOADING, payload: 'Confirming Session'}))
        .then(res => {
            dispatch({type: SET_USER, payload: res.data});
        })
        .catch(err => {
            console.log(err)
            return Promise.reject(err);
        })
    }
}

export function signoutUser() {
    return function(dispatch) {
        Cookies.remove('session');
        persistor.purge();
        window.location = '/login.html'
    }
}

export function changePassword(data) {
    return function(dispatch) {
        dispatch({type: START_LOADING, payload: 'Changing Password'})
        return axios({
            method: 'POST',
            url: `${ROOT_URL}/login/changepassword`,
            data
        })
        .finally(() => dispatch({type: STOP_LOADING, payload: 'Changing Password'}))
        .then(res => {
            dispatch({type: SET_USER, payload: res.data});
        })
        .catch(err => {
            error(err);
            return Promise.reject(err);
        })
    }
}

export function getCustomer() {
    return function(dispatch) {
        dispatch({type: START_LOADING, payload: 'Retrieving Customer'})
        return axios({
            method: 'POST',
            url: `${ROOT_URL}/login/customer`
        })
        .finally(() => dispatch({type: STOP_LOADING, payload: 'Retrieving Customer'}))
        .then(res => {
            dispatch({type: SET_CUSTOMER, payload: res.data});
        })
        .catch(err => {
            error(err);
            return Promise.reject(err);
        })
    }
}