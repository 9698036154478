import React, {Component} from 'react'
import {connect} from 'react-redux'
import * as actions from '../../actions'

class ChangePassword extends Component {

    state = {
        oldPassword: '',
        newPassword: '',
        newPassword2: ''
    }

    passwordChecks = [
        /.{8,}/,
        /.*[a-z].*/,
        /.*[A-Z].*/,
        /.*[0-9].*/,
        /.*\W.*/
    ]

    handleChange = (e) => {
        this.setState({[e.target.name]: e.target.value});
    }

    handleSubmit = () => {
        this.props.changePassword({username: this.props.username, oldPassword: this.state.oldPassword, newPassword: this.state.newPassword})
    }

    render() {
        const problems = [];
        if (!this.state.oldPassword) problems.push("Please enter your old password.");
        if (!this.passwordChecks[0].test(this.state.newPassword)) problems.push("New password must have at least 8 characters.");
        if (!this.passwordChecks[1].test(this.state.newPassword)) problems.push("New password must contain a lower case letter.");
        if (!this.passwordChecks[2].test(this.state.newPassword)) problems.push("New password must contain an upper case letter.");
        if (!this.passwordChecks[3].test(this.state.newPassword)) problems.push("New password must contain a number.");
        if (!this.passwordChecks[4].test(this.state.newPassword)) problems.push("New password must contain a special character.");
        if (this.state.newPassword !== this.state.newPassword2) problems.push("Repeated passwords don't match.");
        return (
            <div style={{width: '25rem'}}>
                <div className='row spaced caption40'>
                    <div>User Name:</div>
                    <div>{this.props.username}</div>
                </div>
                <div className='row spaced caption40'>
                    <div>Old Password:</div>
                    <div><input name='oldPassword'
                        type='password'
                        value={this.state.oldPassword}
                        onChange={this.handleChange}
                    /></div>
                </div>
                <div className='row spaced caption40'>
                    <div>New Password:</div>
                    <div><input name='newPassword'
                        type='password'
                        value={this.state.newPassword}
                        onChange={this.handleChange}
                    /></div>
                </div>
                <div className='row spaced caption40'>
                    <div>Repeat Password:</div>
                    <div><input name='newPassword2'
                        type='password'
                        value={this.state.newPassword2}
                        onChange={this.handleChange}
                    /></div>
                </div>
                <div className='row spaced caption40'>
                    <div style={{verticalAlign: 'top'}}>
                        <button 
                            className='btn' 
                            onClick={this.handleSubmit} 
                            disabled={problems.length}
                        >
                            Change Password
                        </button>
                    </div>
                    <div style={{display: problems.length ? undefined : 'none'}}>
                        {problems[0]}
                    </div>
                </div>
            </div>
        )
    }
}

export default ChangePassword;