import axios from 'axios'
import {
    START_LOADING,
    STOP_LOADING,
    SET_ALL_ATTRIBUTES
} from '../reducers/types'
import {success, error} from '../components/common/toast'
import {ROOT_URL} from './config'

export function getAttributes() {
    return function(dispatch) {
        dispatch({type: START_LOADING, payload: 'Retrieving Stone Properties'})
        return axios({
            method: 'POST',
            url: `${ROOT_URL}/attributes/all`
        })
        .finally(() => dispatch({type: STOP_LOADING, payload: 'Retrieving Stone Properties'}))
        .then(res => {
            dispatch({type: SET_ALL_ATTRIBUTES, payload: res.data});
        })
        .catch(err => {
            error(err)
            return Promise.reject(err);
        })
    }
}