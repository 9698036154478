import React, {Component} from 'react'
import {connect} from 'react-redux'
import * as actions from '../../actions'
import NumberFormat from 'react-number-format'

class AvailableAccounts extends Component {
    state = {
        accountCodes: []
    }

    componentDidMount() {
        this.props.getAvailableAccountCodes()
        .then(accountCodes => {
            this.setState({accountCodes: accountCodes.map((code,i) => ({
                id: i+1, 
                ...code, 
                docType: Array.isArray(code.docType) ? code.docType.join() : code.docType
            }))})
        })
    }

    handleAccountChange = (e) => {
        const id = e.target.getAttribute('account_id');
        const codes = this.state.accountCodes.slice();
        const index = codes.findIndex(code => code.id==id);
        if (index < 0) return;
        codes[index] = {...this.state.accountCodes[index], [e.target.name]: e.target.value};
        this.setState({accountCodes: codes})
    }

    save = () => {
        const codes = this.state.accountCodes.map(account => ({
            ...account,
            docType: account.docType.split(','),
            id: undefined
        }))
        const deleteCodes = this.props.availableAccountList.filter(account => (
            codes.every(code => account.accountCode!=code.accountCode || account.dxInstance!=code.dxInstance)
        ))
        Promise.all([
            codes.length && this.props.updateAvailableAccounts(codes),
            deleteCodes.length && this.props.deleteAvailableAccounts(deleteCodes)
        ])
        .then(() => this.props.onCancel())
    }

    addAccount = () => {
        this.setState({accountCodes: [...this.state.accountCodes, {
            dxInstance: 'DX_DIALOG',
            docType: 'MEMO',
            customerId: null,
            id: this.state.accountCodes.reduce((max,code) => Math.max(code.id, max), 0) + 1
        }]})
    }
    deleteAccount = (id) => {
        let codes = this.state.accountCodes.slice();
        let index = codes.findIndex(code => code.id==id);
        if (index < 0) return;
        codes.splice(index, 1);
        this.setState({accountCodes: codes})
    }

    renderAccounts = () => {
        if (!this.state.accountCodes || !this.state.accountCodes.length)
            return (
                <div className='row spaced'>No DX Accounts Linked</div>
            )
        return this.state.accountCodes.map((account,i) => (
            <div key={account.id} className='row spaced'>
                <div className='inline-col'>
                    DX &nbsp;
                    <select account_id={account.id} name='dxInstance' value={account.dxInstance} onChange={this.handleAccountChange}>
                        <option value='DXDB_HRA'>HRA</option>
                        <option value='DXDB_Crossworks'>CW</option>
                        <option value='DX_DIALOG'>DIALOG</option>
                        <option value='DX_LABGROWN'>Plasmability</option>
                    </select>
                </div>
                <div className='inline-col'>
                    Account Code &nbsp;
                    <NumberFormat
                        account_id={account.id} 
                        name='accountCode' 
                        displayType='input'
                        value={account.accountCode} 
                        onChange={this.handleAccountChange}
                        format='##-##-####'
                    />
                </div>
                <div className='inline-col'>
                    Check For &nbsp;
                    <select
                        account_id={account.id}
                        name='docType'
                        value={account.docType}
                        readOnly
                    >
                        <option value='MEMO'>Memos</option>
                        <option value='INVOICE'>Invoices</option>
                        <option value='MEMO,INVOICE'>Both Memos and Invoices</option>
                    </select>
                </div>
                <div className='inline-col clickable-icon'>
                    <i className="fas fa-minus-circle" onClick={() => this.deleteAccount(account.id)} />
                </div>
            </div>
        ))
    }

    render() {
        return (
            <>
                <div className='row spaced'>
                    <button className='btn btn-secondary' onClick={this.props.onCancel}>Cancel</button>
                    &nbsp;
                    <button className='btn' onClick={this.save}>Save</button>
                </div>
                <div className='row'>DX Accounts to search for available stones. Stones at these acounts are considered to be available for customers to request.</div>
                {this.renderAccounts()}
                <button className='btn btn-secondary' onClick={this.addAccount}>Add Account</button>
            </>
        )
    }
}

function mapStateToProps(state) {
    return {availableAccountList: state.admin.availableAccountList};
}

export default connect(mapStateToProps, actions)(AvailableAccounts);