import {
    ADD_STONE_TAB,
    CLOSE_TAB,
    SELECT_TAB,
    START_LOADING,
    STOP_LOADING,
    CLEAR_LOADING,
    SAVE_FILTERS,
    SELECT_EMBEDDED_TAB
} from './types'

export default function(state={list: [], loading: {}, savedFilters: {}}, action) {
    switch (action.type) {
        case ADD_STONE_TAB: {
            if (Array.isArray(state.list) && state.list.some(tab => tab.parcelName===action.payload.parcelName)) return state;
            else return {...state, list: (Array.isArray(state.list) ? state.list : []).concat(action.payload)};
        }
        case CLOSE_TAB: {
            return {...state, list: state.list.filter(tab => tab.parcelName!==action.payload)};
        }
        case SELECT_TAB: {
            return {...state, selected: action.payload, embeddedFilePath: null};
        }
        case SELECT_EMBEDDED_TAB: {
            return {...state, selected: 'EMBEDDED_PAGE', embeddedFilePath: action.payload}
        }
        case START_LOADING: {
            return {...state, loading: {...state.loading, [action.payload]: true}}
        }
        case STOP_LOADING: {
            return {...state, loading: {...state.loading, [action.payload]: false}}
        }
        case CLEAR_LOADING: {
            return {...state, loading: {}}
        }
        case SAVE_FILTERS: {
            return {...state, savedFilters: {...state.savedFilters, [action.payload.id]: action.payload.filters}}
        }
        default: {
            return state;
        }
    }
}