import React, {Component} from 'react'
import Modal from 'react-responsive-modal'

class ConfirmModal extends Component {

    static defaultProps = {
        message: 'Are you sure?',
        trigger: (onClick) => (
            <button className='btn' onClick={onClick} >Submit</button>
        ),
        stones: null
    };

    state = {
        modalOpen: false
    }

    render() {
        return <>
            {this.props.trigger(() => this.setState({modalOpen: true}))}
            <Modal 
                open={this.state.modalOpen} 
                onClose={() => this.setState({modalOpen: false})}
                showCloseIcon={false}
                styles={{modal: {width: '35rem', padding: '1rem'}}}
                center
            >
                <div className='row spaced' style={{textAlign: 'center'}}><h5 style={{fontSize: 'large'}}>{this.props.message}</h5></div>
                {
                    this.props.updateComment && this.props.comment !== undefined ? 
                    <div className='row spaced caption25'>
                        <div>Add a comment?</div>
                        <div>
                            <input style={{width: '90%'}} value={this.props.comment==null ? '' : this.props.comment} onChange={this.props.updateComment} />
                        </div>
                    </div>
                    : null
                }
                <div className='row spaced'>
                    <button className='btn btn-secondary' style={{float: 'left'}} onClick={() => this.setState({modalOpen: false})}>No</button>
                    <button className='btn' style={{float: 'right'}} onClick={(e) => {
                        return this.props.confirm(e)
                        .then(() => this.setState({modalOpen: false}))
                    }}>Yes</button>
                </div>
            </Modal>
        </>
    }
}

export default ConfirmModal