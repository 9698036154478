import React, {Component} from 'react'
import {connect} from 'react-redux'
import * as actions from '../../actions'
import NumberFormat from 'react-number-format'

class UserForm extends Component {
    state = {
        user: {
            active: true,
            administrator: false,
            salesRep: false,
            showPrices: 'WHOLESALE'
        }
    }

    componentDidMount() {
        if (this.props.user) {
            let user = this.props.user;
            this.setState({user});
        }
        else if (this.props.username) console.log("Error, user not found")
    }

    handleChange = (e) => {
        this.setState({user: {...this.state.user, [e.target.name]: e.target.value}})
    }
    handleCheckbox = (e) => {
        this.setState({user: {...this.state.user, [e.target.name]: !this.state.user[e.target.name]}})
    }

    save = () => {
        this.props.onSave(this.state.user)
    }

    resetPassword = () => {
        if (!this.props.username) return;
        this.props.resetPassword(this.props.username);
    }

    renderCustomerOptions = () => {
        return (
            this.props.customerList.map(customer => (
                <option key={customer.customerId} value={customer.customerId}>{customer.name}</option>
            ))
        )
    }

    render() {
        return (
            <>
                <div className='row spaced'>
                    <button className='btn btn-secondary' onClick={this.props.onCancel}>Cancel</button>
                    &nbsp;
                    <button className='btn' onClick={this.save}>Save</button>
                </div>
                <div className='row spaced caption25'>
                    <div>User Name</div>
                    <div>
                        {
                            this.props.username ?
                            this.state.user.username :
                            <input name='username' onChange={this.handleChange} value={this.state.user.username || ''} />
                        }
                    </div>
                </div>
                <div className='row spaced caption25'>
                    <div>First Name</div>
                    <div>
                        <input name='firstName' onChange={this.handleChange} value={this.state.user.firstName || ''} />
                    </div>
                </div>
                <div className='row spaced caption25'>
                    <div>Last Name</div>
                    <div>
                        <input name='lastName' onChange={this.handleChange} value={this.state.user.lastName || ''} />
                    </div>
                </div>
                <div className='row spaced caption25'>
                    <div>Email</div>
                    <div>
                        <input name='email' onChange={this.handleChange} value={this.state.user.email || ''} />
                    </div>
                </div>
                <div className='row spaced caption25'>
                    <div>Customer</div>
                    <div>
                        {
                            this.props.username ? 
                            this.state.user.customerId + (this.props.customerList ? ` - ${(this.props.customerList.find(c => c.customerId==this.state.user.customerId) || {}).name}` : '') :
                            <select name='customerId' onChange={this.handleChange} value={this.state.user.customerId || ''}>
                                <option value=''>Select a Customer...</option>
                                {this.renderCustomerOptions()}
                            </select>
                        }
                    </div>
                </div>
                <div className='row spaced caption25'>
                    <div>Can Log In</div>
                    <div>
                        <input type='checkbox' name='active' onChange={this.handleCheckbox} checked={this.state.user.active || false} />
                    </div>
                </div>
                <div className='row spaced caption25'>
                    <div>Sales Rep</div>
                    <div>
                        <input type='checkbox' name='salesRep' onChange={this.handleCheckbox} checked={this.state.user.salesRep || false} />
                    </div>
                </div>
                <div className='row spaced caption25'>
                    <div>Administrator</div>
                    <div>
                        <input type='checkbox' name='administrator' onChange={this.handleCheckbox} checked={this.state.user.administrator || false} />
                    </div>
                </div>
                <div className='row spaced caption25'>
                    <div>Show Prices</div>
                    <div>
                        <select name='showPrices' onChange={this.handleChange} value={this.state.user.showPrices || ''}>
                            <option value='WHOLESALE'>Wholesale</option>
                            <option value='RETAIL'>Retail</option>
                            <option value='NONE'>Hide</option>
                        </select>
                    </div>
                </div>
                {
                    this.props.username ? 
                    <div className='row spaced caption25'>
                        <div>Password</div>
                        <div>
                            <button className='btn btn-secondary' onClick={this.resetPassword}>Reset</button>&emsp;Sends an email with a random password to {this.props.user.email}.
                        </div>
                    </div>
                    : null
                }
            </>
        )
    }
}

function mapStateToProps(state, ownProps) {
    const customerList = state.admin.customerList || [];
    if (!ownProps.username || !state.admin.userList) return {customerList};
    return {
        user: state.admin.userList.find(u => u.username==ownProps.username),
        customerList
    }
}

export default connect(mapStateToProps, actions)(UserForm);