import React, {PureComponent} from 'react'
import Modal from 'react-responsive-modal'
import StoneCard from './stone_card'
import NumberFormat from 'react-number-format';

class ConfirmStonesModal extends PureComponent {
    static defaultProps = {
        message: 'Are you sure?',
        trigger: (onClick) => (
            <button className='btn' onClick={onClick} >Submit</button>
        ),
        stones: null
    };

    state = {
        modalOpen: false
    }

    componentDidUpdate() {
        if (this.state.modalOpen && (!Array.isArray(this.props.stones) || !this.props.stones.length))
            this.setState({modalOpen: false});
    }
    
    render() {
        if (Array.isArray(this.props.stones) && this.props.stones.length) {
            this.props.stones.sort((x,y) => (
                Boolean(x.virtual)-Boolean(y.virtual)
                || x.weight-y.weight
                || x.total-y.total
            ))
        }
        
        return <>
            {this.props.trigger(() => this.setState({modalOpen: true}))}
            <Modal 
                open={this.state.modalOpen} 
                onClose={() => this.setState({modalOpen: false})}
                showCloseIcon={true}
                classNames={{modal: 'wide-confirm', closeButton: 'modal-close-button'}}
                center
            >
                <div className='confirmation-container outer border'>
                    <div className='confirmation-column' style={{width: '70%'}}>
                        {
                            this.props.title ? <div className='confirmation-heading container-h-margin'>{this.props.title}</div> : null
                        }
                        <div className='confirmation-text container-h-margin'>{this.props.message}<hr/></div>
                        <div className='confirmation-heading container-h-margin'>Review Stones</div>
                        <div className='confirmation-container flexible scrollable card-container'>
                            {(this.props.stones||[]).map(stone => (<StoneCard key={stone.parcelName} stone={stone} deleteStone={this.props.deleteStone}/>))}
                        </div>
                    </div>
                    <div className='confirmation-column' style={{width: '30%', height: '100%'}}>
                        <div className='confirmation-container flexible-shrinkonly border'>
                            <div className='confirmation-heading'>Summary</div>
                            <div className='confirmation-container scrollable no-padding confirmation-text summaryheightlimit'>
                                {(this.props.stones||[]).map(stone => (
                                    <div className='row' key={stone.parcelName}>
                                        {stone.certNo}
                                        <NumberFormat 
                                            displayType='text' 
                                            className='right' 
                                            value={stone.virtual ? stone.total.slice(0, stone.total.indexOf(' - ')) : stone.total} 
                                            decimalScale={2} 
                                            fixedDecimalScale={true} 
                                            thousandSeparator={true}
                                            prefix='$' 
                                            suffix={stone.virtual ? '+' : undefined}
                                        />
                                    </div>
                                ))}
                            </div>
                            <hr/>
                            <div className='confirmation-container no-padding confirmation-text bold'>
                                Total
                                <NumberFormat
                                    displayType='text'
                                    className='right'
                                    decimalScale={2}
                                    fixedDecimalScale={true} 
                                    thousandSeparator={true}
                                    prefix='$'
                                    {...(this.props.stones||[]).reduce((tot,stone) => {
                                        if (stone.virtual) return {value: tot.value+Number(stone.total.slice(0, stone.total.indexOf(' - '))), suffix: '+'};
                                        else return {value: tot.value+Number(stone.total), suffix: tot.suffix};
                                    }, {value: 0, suffix: undefined})}
                                />
                            </div>
                            <button className='btn' style={{width: '100%'}} onClick={(e) => {
                                return this.props.confirm(e)
                                .then(() => this.setState({modalOpen: false}))
                            }}>{this.props.confirmCaption || 'Yes'}</button>
                        </div>
                        {
                            this.props.updateComment && this.props.comment !== undefined ?
                                <div className='confirmation-container border'>
                                    <textarea 
                                        style={{width: 'calc(100% - 0.4rem)', height: '3rem'}} 
                                        value={this.props.comment==null ? '' : this.props.comment} 
                                        onChange={this.props.updateComment} 
                                        placeholder='Include a comment... (optional)'
                                    />
                                </div>
                            : null
                        }
                        <div className='flexbox-filler' />
                    </div>
                </div>
            </Modal>
        </>
    }
}

export default ConfirmStonesModal