import React, {useEffect, useState, useMemo} from 'react'
import {connect} from 'react-redux';
import * as actions from '../../actions'
import Modal from 'react-responsive-modal'
import JewelleryModelTable from './jewellery_model_table';
import NumberFormat from 'react-number-format';
import { parseModelFile, exportToExcel } from './modelExcelReader';
import { tierList } from '../common/tierList';

function ModelManagement({open, onClose, models, ...props}) {
    useEffect(() => {
        if (open) props.getJewelleryModels()
    }, [open])

    const [file, setFile] = useState(null)
    const changeFile = (e) => {
        const target = e.currentTarget || e.target
        setFile(target.files[0])
    }
    const parseFile = async () => {
        const models = await parseModelFile(file)
        console.log('parsed', models)
        submitChanges(models)
    }

    const [editModel, setEditModel] = useState(null)
    const [editState, setEditState] = useState({})
    const selectedModel = useMemo(() => {
        if (!editModel || !models.length) {
            setEditState({})
            return null
        } else {
            const foundModel = models.find(model => model.name === editModel)
            setEditState(foundModel ?? {})
            return foundModel
        }
    }, [editModel, models])
    const onIndivChange = e => {
        const target = e.currentTarget || e.target
        const newState = {...editState}
        if (target.type === 'checkbox') newState[target.name] = target.checked
        else newState[target.name] = target.value
        console.log('changed to', newState)
        setEditState(newState)
    }
    const submitIndivModel = () => {
        submitChanges([editState])
    }

    const submitChanges = models => {
        console.log('submit', models)
        Promise.all(models.map(model => props.editJewelleryModel(model)))
        .finally(() => {
            props.getJewelleryModels()
            props.getAvailableJewelleryModels()
        })
    }

    return (
        <Modal
            open={open}
            onClose={onClose}
            showCloseIcon={true}
            classNames={{modal: '', closeButton: 'modal-close-button'}}
            styles={{modal: {maxWidth: '1000px'}}}
            center
        >
            <div className='row spaced'>
                <input type='file' onChange={changeFile} />
                &nbsp;
                <button className='btn' onClick={parseFile}>Upload</button>
            </div>
            <JewelleryModelTable
                data={models}
                tableId='manageModels'
                extraColumns={[{
                    Header: 'Manage',
                    id: 'manage',
                    sortable: false,
                    filterable: false,
                    Cell: row => (
                        <button className='btn' onClick={() => setEditModel(row.original.name)}>Edit</button>
                    )
                }]}
                exporter={rows => exportToExcel(rows, true)}
            />
            <Modal
                open={Boolean(editModel)}
                onClose={() => setEditModel(null)}
                showCloseIcon={true}
                classNames={{modal: '', closeButton: 'modal-close-button'}}
                styles={{modal: {width: '40rem'}}}
                center
            >
                <div className='row spaced'>
                    <button className='btn btn-secondary' onClick={() => setEditModel(null)}>Cancel</button>
                    &nbsp;
                    <button className='btn' onClick={submitIndivModel}>Save</button>
                </div>
                <div className='row spaced caption25'>
                    <div>Model</div>
                    <div>
                        <input className='row' name='name' disabled={true} value={editState?.name || ''} />
                    </div>
                </div>
                <div className='row spaced caption25'>
                    <div>Description</div>
                    <div>
                        <input className='row' name='description' disabled={true} value={editState?.description || ''} />
                    </div>
                </div>
                <div className='row spaced caption25'>
                    <div>Type</div>
                    <div>
                        <input className='row' name='type' disabled={true} value={editState?.type || ''} />
                    </div>
                </div>
                <div className='row spaced caption25'>
                    <div>Style</div>
                    <div>
                        <input className='row' name='subType' disabled={true} value={editState?.subType || ''} />
                    </div>
                </div>
                <div className='row spaced caption25'>
                    <div>Finish</div>
                    <div>
                        <input className='row' name='finish' disabled={true} value={editState?.finish || ''} />
                    </div>
                </div>
                <div className='row spaced caption25'>
                    <div>Price</div>
                    <div>
                        <NumberFormat className='row' name='total' displayType='input' disabled={true} value={editState?.total || ''} fixedDecimalScale={2} />
                    </div>
                </div>
                <div className='row spaced caption25'>
                    <div>DX Available</div>
                    <div>
                        <NumberFormat className='row' name='availableQuantity' displayType='input' disabled={true} value={editState?.availableQuantity || 0} decimalScale={0} />
                    </div>
                </div>
                <div className='row spaced caption25'>
                    <div>Extra Qty</div>
                    <div>
                        <NumberFormat className='row' name='additionalQuantity' onChange={onIndivChange} value={editState?.additionalQuantity || 0} decimalScale={0} />
                    </div>
                </div>
                <div className='row spaced caption25'>
                    <div>Video</div>
                    <div>
                        <input className='row' name='photoUrl' onChange={onIndivChange} value={editState?.photoUrl || ''} />
                    </div>
                </div>
                <div className='row spaced caption25'>
                    <div>Still</div>
                    <div>
                        <input className='row' name='stillPhotoUrl' onChange={onIndivChange} value={editState?.stillPhotoUrl || ''} />
                    </div>
                </div>
                <div className='row spaced caption25'>
                    <div>Tier</div>
                    <div>
                        <select className='row' name='tier' onChange={onIndivChange} value={editState?.tier || ''}>
                            {tierList.map(tier => (<option key={tier.value}>{tier.value}</option>))}
                        </select>
                    </div>
                </div>
                <div className='row spaced caption25'>
                    <div>Lab Grown</div>
                    <div>
                        <input name='labGrown' type='checkbox' disabled={true} checked={editState?.labGrown || false} />
                    </div>
                </div>
                <div className='row spaced caption25'>
                    <div>Hidden</div>
                    <div>
                        <input name='hide' type='checkbox' onChange={onIndivChange} checked={editState?.hide || false} />
                    </div>
                </div>
            </Modal>
        </Modal>
    )
}

function mapStateToProps(state) {
    return {
        models: state.jewellery.allModels || []
    }
}

export default connect(mapStateToProps, actions)(ModelManagement)