import React, {useState} from 'react'
import logo from '../../assets/login-banner.png'
import Modal from 'react-responsive-modal'

function LoginHeader({location: {pathname}}) {
    const pages = [
        {path: '/home.html', label: 'Home'},
        {path: '/our-model.html', label: 'Our Model'},
        {path: '/our-diamonds.html', label: 'Our Diamonds'},
        {path: '/analytics.html', label: 'Analytics'},
        {path: '/about.html', label: 'About'},
        {path: '/contact.html', label: 'Contact'},
        // {path: '/news', label: 'News'},
        {path: '/login', label: 'Log In', primary: true}
    ]
    const [mobileMenu, setMobileMenu] = useState(false)

    const toggleMobileMenu = () => {
        setMobileMenu(!mobileMenu)
    }

    return (
        <div className='login-header'>
            <div><img src={logo} style={{height: '5rem', padding: '1.5rem'}} alt='' /></div>
            <div style={{flexGrow: '1'}}/>
            {pages.map(page => (
                <a 
                    key={page.path} 
                    className={`btn login-control${page.primary ? ' ' : ' btn-secondary'}${pathname===page.path ? ' selected' : ''} desktop-only`}
                    href={page.path}
                >
                    {page.label}
                </a>
            ))}
            <div className='mobile-only' style={{fontSize: 'xx-large', margin: '2rem'}} onClick={toggleMobileMenu}><i className='fas fa-bars' /></div>
            <Modal 
                open={mobileMenu} 
                onClose={() => setMobileMenu(false)}
                styles={{modal: {padding: '3rem 3rem 3rem 3rem'}}}
                showCloseIcon={true}
                focusTrapped={false}
                center
            >
                <div className='mobile-menu'>
                    {[pages.map(page => (    
                        <a 
                            key={page.path}
                            className={`btn login-control${page.primary ? ' ' : ' btn-secondary'}${pathname===page.path ? ' selected' : ''}`}
                            href={page.path}
                        >
                            {page.label}
                        </a>
                    ))]}
                </div>
            </Modal>
        </div>
    )
}

export default LoginHeader