import React, {useState, useMemo, useEffect} from 'react'
import {usePopper} from 'react-popper'
import Slider from 'react-slider'
import NumberFormat from 'react-number-format'
import ClickAwayListener from 'react-click-away-listener'
import moment from 'moment-timezone'
import Calendar from 'react-calendar'

const FilterPopup = ({filterCaption, children}) => {
    const [displayElement, setDisplayElement] = useState(null);
    const [popupElement, setPopupElement] = useState(null);
    const [visible, setVisible] = useState(false);
    const {styles, attributes} = usePopper(displayElement, popupElement, {
        strategy: 'fixed', 
        modifiers: [
            {name: 'offset', options: {offset: [0, visible ? 0 : -35]}},
        ]
    })
    const toggle = (e) => {
        e.stopPropagation();
        setVisible(prevValue => !prevValue);
    }
    return (
        <ClickAwayListener onClickAway={() => setVisible(false)}>
            <div ref={setDisplayElement} className='filter-header' onClick={toggle} showpopup={visible ? '' : undefined}>
                {filterCaption}
            </div>
            <div ref={setPopupElement} 
                className='filter-popup' 
                style={styles.popper} {...attributes.popper} 
                showpopup={visible ? '' : undefined}
            >
                {children}
            </div>
        </ClickAwayListener>
    )
}

const TextFilter = ({filter, onChange}) => {
    const filterValue = (filter || {}).value || '';
    return (
        <FilterPopup filterCaption={filterValue==='' ? 'All' : filterValue}>
            <input value={filterValue} onChange={(e) => onChange(e.target.value)} />
        </FilterPopup>
    )
}

const filterText = (filter, row, column) => {
    if (row._original.virtual) return true;
    const rowVal = row[filter.pivotId || filter.id]
    if (column.altFilters) {
        let rowVals = [rowVal, ...column.altFilters.map(id => row[id] || (row._original && row._original[id]))];
        return rowVals.some(val => val !== undefined ? String(val).toLowerCase().includes(String(filter.value).toLowerCase()) : false)
            || rowVals.every(val => val===undefined);
    } else {
        return rowVal !== undefined ? String(rowVal).toLowerCase().includes(String(filter.value).toLowerCase()) : true
    }
}

const NumberFilter = ({filter, onChange}) => {
    let filterValue = (filter||{}).value;
    if (filter==null || filter.value==null) filterValue = {min: '', max: ''}
    else if (Number.isFinite(Number(filter.value))) filterValue = {min: filter.value, max: filter.value}

    return (
        <FilterPopup filterCaption={filterValue.min===filterValue.max ? (filterValue.min=='' ? 'All' : `${filterValue.min}`) : `${filterValue.min} \u2013 ${filterValue.max}`}>
            <NumberFormat displayType='input' 
                value={filterValue.min} 
                onChange={(e) => {
                    if (!e.target.value && !filterValue.max) onChange('')
                    else onChange({min: e.target.value, max: filterValue.max})
                }} 
                decimalScale={2}
                style={{width: '5rem'}} 
            />
            &nbsp;&ndash;&nbsp;
            <NumberFormat displayType='input' 
                value={filterValue.max} 
                onChange={(e) => {
                    if (!e.target.value && !filterValue.min) onChange('')
                    else onChange({min: filterValue.min, max: e.target.value})
                }} 
                decimalScale={2}
                style={{width: '5rem'}}
            />
        </FilterPopup>
    )
}

const filterNumber = (filter, row, column) => {
    if (filter==null || filter.value==null) return true;
    if (row._original.virtual) return true;
    const rowVal = row[filter.pivotId || filter.id]
    const {min, max} = filter.value;
    return (min==='' || min==null || rowVal>=min) && (max==='' || max==null || rowVal<=max);
}

//const dateFormat = 'MMM DD, YY';
const DateFilter = ({filter, onChange}) => {
    const filterValue = useMemo(() => {
        if (!filter || filter?.value==null) return {min: null, max: null}
        const normalize = (val) => {
            if (val == null) return null
            else if (moment.isMoment(val)) return val.toDate()
            else if (moment.isDate(val)) return val
            else {
                const val2 = moment(val)
                if (val2.isValid()) return val2.toDate()
                else return null
            }
        }
        return {min: normalize(filter.value.min), max: normalize(filter.value.max)}
    }, [filter, filter?.value])

    return (
        <FilterPopup filterCaption={
            (filterValue.min===filterValue.max) 
            ? (!filterValue.min ? 'All' : <i className="far fa-calendar-alt" title={filterValue.min}/>) 
            : [
                moment.isDate(filterValue.min) ? <i key="left" className="far fa-calendar-alt" title={filterValue.min}/> : '\u00A0', 
                '\u00A0\u2013\u00A0', 
                moment.isDate(filterValue.max) ? <i key="right" className="far fa-calendar-alt" title={filterValue.max}/> : '\u00A0',
                <button key="clear" style={{fontSize: '12px', verticalAlign: 'top', float: 'right'}} className='tab-button' onClick={(e) => {e.stopPropagation(); onChange(null)}}><i className="fas fa-times-circle"/></button>
            ]
        }><div style={{display: 'flex', alignItems: 'center'}}>
            <Calendar 
                selectRange={false} minDetail="month" calendarType="US"
                value={filterValue.min} 
                onChange={(date) => onChange({min: date, max: filterValue.max})} 
            />
            &emsp;&mdash;&emsp;
            <Calendar 
                selectRange={false} minDetail="month" calendarType="US"
                value={filterValue.max} 
                onChange={(date) => onChange({min: filterValue.min, max: date})} 
            /></div>
        </FilterPopup>
    )
}

const filterDate = (filter, row, column) => {
    if (filter==null || filter.value==null) return true;
    const rowVal = moment(row[filter.pivotId || filter.id]);
    const {min, max} = filter.value;
    return (min==null | rowVal.isSameOrAfter(min, 'day')) && (max==null || rowVal.isSameOrBefore(max, 'day'));
}

const toggleArrayItem = (array, item) => {
    const arr = array.slice();
    const idx = array.indexOf(item);
    if (idx < 0) arr.push(item);
    else arr.splice(idx, 1);
    if (!arr.length) return '';
    else return arr;
}
const reduceOptionsData = (data, col) => {
    if (!Array.isArray(data)) return null;
    const acc = typeof col.accessor === 'function' ? (row) => col.accessor(row) : (row) => row[col.accessor]
    try {
        return [...data.filter(row => !row.virtual).reduce((set,row) => set.add(JSON.stringify(acc(row) ?? null)), new Set())].map(opt => JSON.parse(opt)).sort();
    } catch (err) {
        console.error(err)
        return []
    }
}
const OptionsFilter = ({data, options, filter, column, optionKey, onChange}) => {
    const dataOpts = useMemo(() => reduceOptionsData(data, column), [data, column.accessor]);
    if (dataOpts !== null && !Array.isArray(options)) options = dataOpts;
    else if (dataOpts !== null) options = options.filter(opt => dataOpts.some(dataOpt => {
        const optOpt = optionKey ? opt[optionKey] : opt
        return optOpt == dataOpt || (dataOpt == null && optOpt === '')
    }));
    const filterValue = (filter && Array.isArray(filter.value)) ? 
        filter.value.filter(filt => options.some(opt => optionKey ? opt[optionKey]==filt : (opt==null ? filt=='' : opt==filt))) : [];
    if (!Array.isArray(options)) return null;
    return (
        <FilterPopup filterCaption={filterValue.length ? filterValue.join() : 'All'}>
            {options.map(opt => {
                const optValue = optionKey ? opt[optionKey] : (opt==null ? '' : opt);
                const description = (opt && opt.description) || (optValue==='' ? '(blank)' : optValue);
                return (
                    <div key={optValue} className='row'>
                        <input type='checkbox' value={optValue}
                            checked={filterValue.includes(optValue)}
                            onChange={e => onChange(toggleArrayItem(filterValue, e.target.value))}
                        />
                        &nbsp;
                        {description}
                        {opt?.infoTip ? <>
                            &nbsp;
                            <span data-tip={opt.infoTip}><i className="fas fa-info-circle"/></span>
                        </> : null}
                    </div>
                )
            })}
        </FilterPopup>
    )
}

const filterOptions = (filter, row, column) => {
    if (filter==null || !Array.isArray(filter.value) || !filter.value.length) return true;
    if (row._original.virtual) return true;
    return filter.value.map(x => x==='' ? null : x).includes(row[filter.pivotId || filter.id]);
}

//colour, clarity, fluorescence
const getObjectFromOrder = (options, order) => options.find(opt => opt.order==order) || {};
const AttributeFilter = ({options, optionKey, filter, column, onChange}) => {
    if (!Array.isArray(options)) return null;
    const {sliderOptions, checkOptions} = options.reduce(({sliderOptions, checkOptions},opt) => {
        if (opt.isFancy) checkOptions.push(opt);
        if (opt.order != null) sliderOptions.push(opt);
        return {sliderOptions, checkOptions};
    }, {sliderOptions: [], checkOptions: []})
    const {minOrder, maxOrder} = sliderOptions.reduce(({minOrder,maxOrder},opt) => ({
        minOrder: minOrder==null || opt.order<minOrder ? opt.order : minOrder, 
        maxOrder: maxOrder==null || opt.order>maxOrder ? opt.order : maxOrder
    }), {})
    const filterValue = filter && filter.value || {min: minOrder, max: maxOrder, include: []};
    let filterCaption = 'All'
    if (filterValue != null && (filterValue.min!=minOrder || filterValue.max!=maxOrder || filterValue.include.length<checkOptions.length)) {
        filterCaption = getObjectFromOrder(options, filterValue.min)[optionKey] + ' \u2013 ' + getObjectFromOrder(options, filterValue.max)[optionKey];
        if (filterValue.include.length)
            filterCaption += ', ' + filterValue.include.join(', ');
    }
    return (
        <FilterPopup filterCaption={filterCaption}>
            {!sliderOptions.length ? null : 
                <Slider className='horizontal-slider'
                    thumbClassName='slider-handle'
                    trackClassName='slider-track'
                    min={minOrder} max={maxOrder}
                    renderThumb={(props, state) => <div {...props}><div className='handle-caption'>{sliderOptions.find(opt => opt.order==state.valueNow)[optionKey]}</div></div>}
                    pearling
                    minDistance={0}
                    value={[filterValue.min, filterValue.max]}
                    onChange={value => onChange({...filterValue, min: value[0], max: value[1]})}
                />
            }
            {!checkOptions.length ? null : 
                <>
                    {checkOptions.map(opt => {
                        const optValue = optionKey ? opt[optionKey] : opt;
                        const description = opt.description || optValue;
                        return (
                            <div key={optValue} className='row'>
                                <input type='checkbox' value={optValue}
                                    checked={filterValue.include.includes(optValue)}
                                    onChange={e => onChange({...filterValue, include: toggleArrayItem(filterValue.include, e.target.value)})}
                                />
                                &nbsp;
                                {description}
                            </div>
                        )
                    })}
                </>
            }
        </FilterPopup>
    )
}

const filterAttribute = (options, optionKey, filter, row, column) => {
    if (filter==null) return true;
    if (filter.value.include.includes(row[filter.pivotId || filter.id])) return true;
    if (row._original.virtual) return true;
    const rowAttribute = options.find(opt => opt[optionKey]==row[filter.pivotId || filter.id]);
    if (!rowAttribute) return false;
    return filter.value.min <= rowAttribute.order && rowAttribute.order <= filter.value.max;
}

const filterJewelleryCerts = (filter, row, column) => {
    if (row._original.virtual) return true;
    const rowVal = row[filter.pivotId || filter.id]
        .reduce((str, stn) => (str ? str + ' ' : '') + stn.lab + ' ' + stn.certNo, null)
    return rowVal !== undefined ? String(rowVal).toLowerCase().includes(String(filter.value).toLowerCase()) : true
}

export {TextFilter, filterText, NumberFilter, filterNumber, DateFilter, filterDate, OptionsFilter, filterOptions, AttributeFilter, filterAttribute, filterJewelleryCerts};