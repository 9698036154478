import {
    SET_AVAILABLE_LG,
    SET_MY_LG,
    SET_PAST_LG,
    SET_CART_LG,
    ADD_DETAILS_LG
} from './types'

export default function(state={}, action) {
    switch (action.type) {
        /*case ALL_INVENTORY: {
            const {available, my} = action.payload.reduce(({available,my},stone) => {
                if (stone.customerId==null) return {available: available.concat([stone]), my};
                else return {available, my: my.concat([stone])};
            }, {available: [], my: []});
            return {...state, available, my}
        }*/
        case SET_AVAILABLE_LG: {
            return {...state, available: action.payload}
        }
        case SET_MY_LG: {
            return {...state, my: action.payload}
        }
        case SET_PAST_LG: {
            return {...state, past: action.payload}
        }
        case SET_CART_LG: {
            return {...state, cart: action.payload}
        }
        case ADD_DETAILS_LG: {
            if (!action.payload || !action.payload.parcelName) return state;
            return {...state, details: {...state.details, [action.payload.parcelName]: action.payload}}
        }
        default: {
            return state;
        }
    }
}