import React, {Component} from 'react'
import {connect} from 'react-redux'
import NumberFormat from 'react-number-format'
import Modal from 'react-responsive-modal';

class CustomerForm extends Component {
    state = {
        details: false,
        customer: {
            allowLogin: true,
            allowActions: false,
            minInventory: 0,
            maxInventory: 0,
            fmAllowed: false,
            lgAllowed: false,
            accountCodes: []
        },
        retailModalOpen: false
    }

    componentDidMount() {
        if (this.props.customer) {
            let customer = this.props.customer;
            if (!customer.accountCodes) customer = {...customer, accountCodes: []};
            else customer.accountCodes = customer.accountCodes.map(code => ({
                ...code, docType: Array.isArray(code.docType) ? code.docType.join() : code.docType
            }))
            this.setState({customer});
        }
        else if (this.props.customerId) console.log("Error, customer not found")
    }

    handleChange = (e) => {
        this.setState({customer: {...this.state.customer, [e.target.name]: e.target.value}})
    }
    handleCheckbox = (e) => {
        this.setState({customer: {...this.state.customer, [e.target.name]: !this.state.customer[e.target.name]}})
    }
    handleAccountChange = (e) => {
        const index = e.target.getAttribute('account_index');
        const codes = [...this.state.customer.accountCodes];
        codes[index] = {...this.state.customer.accountCodes[index], [e.target.name]: e.target.value};
        this.setState({customer: {...this.state.customer, accountCodes: codes}})
    }

    save = () => {
        this.props.onSave({
            ...this.state.customer,
            accountCodes: this.state.customer.accountCodes.map(account => ({
                ...account,
                docType: account.docType.split(',')
            }))
        })
    }

    openRetail = () => {
        this.props.getMarkups(this.props.customerId)
        .then(markupData => {
            this.setState({markups: markupData.map(markup => ({...markup, markupType: (markup.minValue!=null || markup.maxValue!=null) ? 'value' : 'weight'}))})
        })
        this.setState({retailModalOpen: true})
    }

    addMarkupLine = () => {
        this.setState(oldState => ({markups: (oldState.markups || []).concat({})}))
    }
    deleteMarkupLine = (markup) => {
        if (!this.state.markups) return
        this.setState(oldState => {
            const idx = oldState.markups.indexOf(markup)
            if (idx >= 0) return {markups: oldState.markups.toSpliced(idx, 1)}
            else return {}
        })
    }

    markupInputRegexp = /^(\w+)\|(\d+)$/
    handleMarkupChange = e => {
        const target = e.currentTarget || e.target
        const match = target.name.match(this.markupInputRegexp)
        if (!match) return
        this.setState(oldState => {
            const mkp = oldState.markups[match[2]]
            mkp[match[1]] = target.value
            return oldState.markups.splice(match[2], 1, mkp)
        })
    }
    handleMarkupCheckbox = e => {
        const target = e.currentTarget || e.target
        const match = target.name.match(this.markupInputRegexp)
        if (!match) return
        this.setState(oldState => {
            const mkp = oldState.markups[match[2]]
            if (!mkp.productTypes) mkp.productTypes = []
            const vIdx = mkp.productTypes.indexOf(target.value)
            if (vIdx >= 0) mkp.productTypes = mkp.productTypes.toSpliced(vIdx, 1)
            else mkp.productTypes.push(target.value)
            return oldState.markups.toSpliced(match[2], 1, mkp)
        })
    }

    saveMarkups = () => {
        if (!this.state.markups) return
        const mkps = this.state.markups.map(mkp => {
            const valueType = mkp.markupType === 'value'
            return {
                minWeight: valueType ? null : mkp.minWeight,
                maxWeight: valueType ? null : mkp.maxWeight,
                minValue: valueType ? mkp.minValue : null,
                maxValue: valueType ? mkp.maxValue : null,
                productTypes: mkp.productTypes,
                markup: mkp.markup
            }
        })
        this.props.saveMarkups(this.props.customerId, mkps)
        .then(() => this.setState({retailModalOpen: false}))
    }

    addAccount = () => {
        this.setState({customer: {...this.state.customer, accountCodes: [...this.state.customer.accountCodes, {
            dxInstance: 'DX_DIALOG',
            docType: 'MEMO'
        }]}})
    }
    deleteAccount = (index) => {
        let codes = this.state.customer.accountCodes.slice();
        codes.splice(index, 1);
        this.setState({customer: {...this.state.customer, accountCodes: codes}})
    }

    renderAccounts = () => {
        if (!this.state.customer.accountCodes || !this.state.customer.accountCodes.length)
            return (
                <div className='row spaced'>No DX Accounts Linked</div>
            )
        return this.state.customer.accountCodes.map((account,i) => (
            <div key={i} className='row spaced'>
                <div className='inline-col'>
                    DX &nbsp;
                    <select account_index={i} name='dxInstance' value={account.dxInstance} onChange={this.handleAccountChange}>
                        <option value='DXDB_HRA'>HRA</option>
                        <option value='DXDB_Crossworks'>CW</option>
                        <option value='DX_DIALOG'>DIALOG</option>
                        <option value='DX_LABGROWN'>Plasmability</option>
                    </select>
                </div>
                <div className='inline-col'>
                    Account Code &nbsp;
                    <NumberFormat
                        account_index={i} 
                        name='accountCode' 
                        displayType='input'
                        value={account.accountCode} 
                        onChange={this.handleAccountChange}
                        format='##-##-####'
                    />
                </div>
                <div className='inline-col'>
                    Check For &nbsp;
                    <select
                        account_index={i}
                        name='docType'
                        value={account.docType}
                        onChange={this.handleAccountChange}
                    >
                        <option value='MEMO'>Memos</option>
                        <option value='INVOICE'>Invoices</option>
                        <option value='MEMO,INVOICE'>Both Memos and Invoices</option>
                    </select>
                </div>
                <div className='inline-col clickable-icon'>
                    <i className="fas fa-minus-circle" onClick={() => this.deleteAccount(i)} />
                </div>
            </div>
        ))
    }

    render() {
        return (
            <>
                <div className='row spaced'>
                    <button className='btn btn-secondary' onClick={this.props.onCancel}>Cancel</button>
                    &nbsp;
                    <button className='btn' onClick={this.save}>Save</button>
                    &nbsp;
                    <button className={`btn btn-secondary${this.props.customerId ? '' : ' no-render'}`} onClick={this.openRetail}>Retail Markups</button>
                </div>
                <div className='row spaced caption25'>
                    <div>Name</div>
                    <div>
                        <input name='name' onChange={this.handleChange} value={this.state.customer.name || ''} />
                    </div>
                </div>
                <div className='row spaced caption25'>
                    <div>Legal Name</div>
                    <div>
                        <input name='legalName' onChange={this.handleChange} value={this.state.customer.legalName || ''} />
                    </div>
                </div>
                <div className='row spaced caption25'>
                    <div>Email</div>
                    <div>
                        <input name='email' onChange={this.handleChange} value={this.state.customer.email || ''} />
                    </div>
                </div>
                <div className='row spaced caption25'>
                    <div>Sales Rep Email</div>
                    <div>
                        <input name='salesRepEmail' onChange={this.handleChange} value={this.state.customer.salesRepEmail || ''} />
                    </div>
                </div>
                <div className='row spaced caption25'>
                    <div>Minimum Inventory</div>
                    <div>
                        <NumberFormat displayType='input' name='minInventory' onChange={this.handleChange} value={this.state.customer.minInventory || 0} />
                    </div>
                </div>
                <div className='row spaced caption25'>
                    <div>Maximum Inventory</div>
                    <div>
                        <NumberFormat displayType='input' name='maxInventory' onChange={this.handleChange} value={this.state.customer.maxInventory || 0} />
                    </div>
                </div>
                <div className='row spaced caption25'>
                    <div>Can Log In</div>
                    <div>
                        <input type='checkbox' name='allowLogin' onChange={this.handleCheckbox} checked={this.state.customer.allowLogin || false} />
                    </div>
                </div>
                <div className='row spaced caption25'>
                    <div>Can Make Changes</div>
                    <div>
                        <input type='checkbox' name='allowActions' onChange={this.handleCheckbox} checked={this.state.customer.allowActions || false} />
                    </div>
                </div>
                <div className='row spaced caption25'>
                    <div>FM Allowed</div>
                    <div>
                        <input type='checkbox' name='fmAllowed' onChange={this.handleCheckbox} checked={this.state.customer.fmAllowed || false} />
                    </div>
                </div>
                <div className='row spaced caption25'>
                    <div>LG Allowed</div>
                    <div>
                        <input type='checkbox' name='lgAllowed' onChange={this.handleCheckbox} checked={this.state.customer.lgAllowed || false} />
                    </div>
                </div>
                <hr />
                <div className='row'>DX Accounts:</div>
                {this.renderAccounts()}
                <button className='btn btn-secondary' onClick={this.addAccount}>Add Account</button>
                <hr />
                {
                    this.props.customerId ? 
                    <div className='row'><div onClick={() => this.setState({details: !this.state.details})}>
                        <i className={`fas fa-caret-right${this.state.details ? ' fa-rotate-90' : ''}`}/>&nbsp;More
                    </div></div>
                    : null
                }
                {
                    this.state.details ? 
                    <>
                        <div className='row spaced caption25'>
                            <div>Dialog Legend</div>
                            <div>
                                <input type='checkbox' name='legend' onChange={this.handleCheckbox} checked={this.state.customer.legend || false} />
                            </div>
                        </div>
                        <div className='row spaced caption25'>
                            <div>GIA Round Discount</div>
                            <div>
                                <NumberFormat displayType='input' name='giaRoundDiscount' onChange={this.handleChange} value={this.state.customer.giaRoundDiscount || 0} />
                            </div>
                        </div>
                        <div className='row spaced caption25'>
                            <div>Payment Terms (Days)</div>
                            <div>
                                <NumberFormat displayType='input' name='paymentDays' onChange={this.handleChange} value={this.state.customer.paymentDays || 0} />
                            </div>
                        </div>
                        <div className='row spaced caption25'>
                            <div>Standard Rebate</div>
                            <div>
                                <NumberFormat displayType='input' name='normalRebate' onChange={this.handleChange} value={this.state.customer.normalRebate || 0} />
                            </div>
                        </div>
                        <div className='row spaced caption25'>
                            <div>Fancy H&A Rebate</div>
                            <div>
                                <NumberFormat displayType='input' name='hafRebate' onChange={this.handleChange} value={this.state.customer.hafRebate || 0} />
                            </div>
                        </div>
                        <div className='row spaced caption25'>
                            <div>Lab Grown Rebate</div>
                            <div>
                                <NumberFormat displayType='input' name='lgRebate' onChange={this.handleChange} value={this.state.customer.lgRebate || 0} />
                            </div>
                        </div>
                        <div className='row spaced caption25'>
                            <div>Base Fee Rate</div>
                            <div>
                                <NumberFormat displayType='input' name='feeRate' onChange={this.handleChange} value={this.state.customer.feeRate || 0} />
                            </div>
                        </div>
                        <div className='row spaced caption25'>
                            <div>Percentage Fancy H&A to Reduce Fees 10%</div>
                            <div>
                                <NumberFormat displayType='input' name='hafPct1' onChange={this.handleChange} value={this.state.customer.hafPct1 || 0} />
                            </div>
                        </div>
                        <div className='row spaced caption25'>
                            <div>Percentage Fancy H&A to Reduce Fees 20%</div>
                            <div>
                                <NumberFormat displayType='input' name='hafPct2' onChange={this.handleChange} value={this.state.customer.hafPct2 || 0} />
                            </div>
                        </div>
                        <div className='row spaced caption25'>
                            <div>Lab Grown Fee Rate</div>
                            <div>
                                <NumberFormat displayType='input' name='lgFeeRate' onChange={this.handleChange} value={this.state.customer.lgFeeRate || 0} />
                            </div>
                        </div>
                        <div className='row spaced caption25'>
                            <div>Name on BIG Report</div>
                            <div>
                                <input name='bigName' onChange={this.handleChange} value={this.state.customer.bigName || ""} />
                            </div>
                        </div>
                    </>
                    : null
                }
                {/*Retail Markup section*/}
                <Modal
                    open={this.state.retailModalOpen}
                    onClose={() => this.setState({retailModalOpen: false})}
                    showCloseIcon={true}
                    classNames={{modal: '', closeButton: 'modal-close-button'}}
                    center
                >
                    <div className='row spaced markup-row'>
                        <span>$</span>
                        <span>cts</span>
                        <span/>
                        <span>Nat</span>
                        <span>Mtd</span>
                        <span>LG</span>
                        <span/>
                        <span>Min</span>
                        <span>Max</span>
                        <span>Markup</span>
                    </div>
                    {(this.state.markups || []).map((markup, i) => (
                        <div key={i} className='row spaced markup-row'>
                            <input type='radio' name={`markupType|${i}`} onChange={this.handleMarkupChange} value='value' checked={markup.markupType==='value'} />
                            <input type='radio' name={`markupType|${i}`} onChange={this.handleMarkupChange} value='weight' checked={markup.markupType==='weight'} />
                            <span/>
                            <input type='checkbox' name={`loose|${i}`} onChange={this.handleMarkupCheckbox} value='LOOSE' checked={markup.productTypes?.includes('LOOSE')} />
                            <input type='checkbox' name={`mounted|${i}`} onChange={this.handleMarkupCheckbox} value='MOUNTED' checked={markup.productTypes?.includes('MOUNTED')} />
                            <input type='checkbox' name={`lab_grown|${i}`} onChange={this.handleMarkupCheckbox} value='LAB_GROWN' checked={markup.productTypes?.includes('LAB_GROWN')} />
                            <span/>
                            <NumberFormat className={markup.markupType==='weight' ? '' : 'no-render'} displayType='input' name={`minWeight|${i}`} onChange={this.handleMarkupChange} value={markup.minWeight || ''} />
                            <NumberFormat className={markup.markupType==='weight' ? '' : 'no-render'} displayType='input' name={`maxWeight|${i}`} onChange={this.handleMarkupChange} value={markup.maxWeight || ''} />
                            <NumberFormat className={markup.markupType==='value' ? '' : 'no-render'} displayType='input' name={`minValue|${i}`} onChange={this.handleMarkupChange} value={markup.minValue || ''} />
                            <NumberFormat className={markup.markupType==='value' ? '' : 'no-render'} displayType='input' name={`maxValue|${i}`} onChange={this.handleMarkupChange} value={markup.maxValue || ''} />
                            <NumberFormat className={markup.markupType==null ? 'no-render' : ''} displayType='input' name={`markup|${i}`} onChange={this.handleMarkupChange} value={markup.markup || 0} />
                            <span/>
                            <div className='inline-col clickable-icon'>
                                <i className="fas fa-minus-circle" onClick={() => this.deleteMarkupLine(markup)} />
                            </div>
                        </div>
                    ))}
                    <div className='row spaced'>
                        <button className='btn btn-secondary' onClick={() => this.setState({retailModalOpen: false})}>Cancel</button>
                        &nbsp;
                        <button className='btn' onClick={this.saveMarkups}>Save</button>
                        &nbsp;
                        <button className='btn' onClick={this.addMarkupLine}>Add a Row</button>
                    </div>
                </Modal>
            </>
        )
    }
}

function mapStateToProps(state, ownProps) {
    if (!ownProps.customerId || !state.admin.customerList) return {};
    return {
        customer: state.admin.customerList.find(c => c.customerId==ownProps.customerId)
    }
}

export default connect(mapStateToProps, null)(CustomerForm);