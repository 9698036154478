import {
    SET_USER,
    SET_CUSTOMER
} from './types'

export default function(state={}, action) {
    switch (action.type) {
        case SET_USER: {
            return {...state, currentUser: action.payload}
        }
        case SET_CUSTOMER: {
            return {...state, customer: action.payload}
        }
        default: {
            return state;
        }
    }
}