import React, {useMemo} from 'react'
import {connect} from 'react-redux'
import { getBinRanges, saveDialogIdealFlat, templateSplitter } from './ideal_utils'

function IdealAvailable({template, bins, ...props}) {
    const {attributes, neededValues: idealInventory} = useMemo(() => {
        if (!template || !bins) return {attributes: [], neededValues: {}}
        return getBinRanges(template, bins)
    }, [template, bins])

    const binMap = useMemo(() => {
        const map = new Map()
        for (const bin of (bins ?? [])) {
            map.set(`{${bin.storeId}}|${bin.binSpec}`, bin)
        }
        return map;
    }, [template, bins])

    const downloadExcel = () => {
        const writeableBins = bins.map(bin => {
            const binValues = [...bin.binSpec.matchAll(templateSplitter)].map(res => res[1])
            return {
                ...bin, 
                ...binValues.reduce((obj,val,i) => ({...obj, [attributes[i+1]]: val}), {}),
                ...bin.history.reduce((obj,yearVal) => {
                    const yr = new Date(yearVal.dateRange.minimum).getFullYear()
                    return {...obj, [`${yr}Avg`]: yearVal.qtyAverage, [`${yr}New`]: yearVal.qtyNew, [`${yr}Sold`]: yearVal.qtySold, [`${yr}Ret`]: yearVal.qtyReturned}
                }, {})
            }
        })
        saveDialogIdealFlat(writeableBins)
    }

    const showBinValue = bin => {
        if (!bin) return '- / -'
        let target = '0'
        if (bin.minQty!=null && bin.maxQty!=null) target = `${bin.minQty} - ${bin.maxQty}`
        else if (bin.targetQty!=null) target = bin.targetQty
        if (bin.targetValue!=null) target = `${target} (${bin.targetValue})`
        let av = bin?.stones?.length ?? 0
        return `${av} / ${target}`
    }

    const renderCrosstabs = (attributes, opts, prefix) => {
        switch (attributes.length) {
            case 0: return null
            case 1: return (
                <div className='reassort_ideal--list'>{
                    opts[attributes[0]].map(opt => <React.Fragment key={opt}>
                        <div>{opt}</div><div>{showBinValue(binMap?.get(prefix ? `${prefix}|{${opt}}` : `{${opt}}`))}</div>
                    </React.Fragment>)
                }</div>
            )
            case 2: 
                const [vAtt, hAtt, swap] = opts[attributes[0]].length > opts[attributes[1]].length ? [0, 1, false] : [1, 0, true]
                return (
                    <div className='reassort_ideal--wccgrid'>
                        {opts[attributes[hAtt]].map((opt1, i) => {
                            const displayOpt = opt1
                            return <div key={opt1} className='reassort_ideal--upperheader' style={{gridColumnStart: i+2, gridColumnEnd: 'span 1', borderLeft: i===0 ? '1px solid' : undefined}}>{displayOpt}</div>
                        })}
                        {opts[attributes[vAtt]].map((opt0, r) => {
                            const displayOpt = opt0
                            return <React.Fragment key={opt0}>
                                <div className='reassort_ideal--colheader' style={{gridRowStart: r+2, borderTop: r===0 ? '1px solid' : undefined}}>{displayOpt}</div>
                                {opts[attributes[hAtt]].map((opt1, i) => (
                                    <div key={i} className='reassort_ideal--cell' style={{gridRowStart: r+2, gridColumnStart: i+2}}>
                                        {showBinValue(binMap?.get((prefix ? `${prefix}|` : '') + `{${swap ? opt1 : opt0}}|{${swap ? opt0 : opt1}}`))}
                                    </div>
                                ))}
                            </React.Fragment>
                        })}
                    </div>
                )
            case 3: 
                return <div className='reassort_ideal--wccgrid'>
                    {opts[attributes[2]].map((opt2, j) => <React.Fragment key={opt2}>
                        <div className='reassort_ideal--upperheader' style={{gridColumnStart: j*opts[attributes[1]].length + 2, gridColumnEnd: `span ${opts[attributes[1]].length}`, borderLeft: j===0 ? '1px solid' : undefined}}>{opt2}</div>
                        {opts[attributes[1]].map((opt1, i) => <div key={opt1} className='reassort_ideal--lowerheader' style={{gridColumnStart: i + j*opts[attributes[1]].length + 2, borderLeft: (i===0 && j===0) ? '1px solid' : undefined}}>{opt1}</div>)}
                    </React.Fragment>)}
                    {opts[attributes[0]].map((opt0, r) => <React.Fragment key={opt0}>
                        <div className='reassort_ideal--colheader' style={{gridRowStart: r+3, borderTop: r===0 ? '1px solid' : undefined}}>{opt0}</div>
                        {opts[attributes[1]].map((opt1, i) => (
                            opts[attributes[2]].map((opt2, j) => (
                                <div key={`${i}|${j}`} className='reassort_ideal--cell' style={{gridRowStart: r+3, gridColumnStart: i + j*opts[attributes[1]].length + 2}}>
                                    {showBinValue(binMap?.get((prefix ? `${prefix}|` : '') + `{${opt0}}|{${opt1}}|{${opt2}}`))}
                                </div>
                            ))
                        ))}
                    </React.Fragment>)}
                </div>
            default: 
                const nextLevel = attributes.slice(1)
                return opts[attributes[0]].map(option => {
                    const displayOpt = option
                    return <div key={option}>
                        <span>{displayOpt}</span>
                        <div className='reassort_ideal--group'>
                            {renderCrosstabs(nextLevel, opts, prefix ? `${prefix}|{${option}}` : `{${option}}`)}
                        </div>
                    </div>
                })
        }
    }

    if (!bins?.length) return null
    else return <div>
        <div className='row spaced'><button className='btn' onClick={downloadExcel}>Download</button></div>
        {renderCrosstabs(attributes, idealInventory, '')}
    </div>
}

function mapStateToProps(state) {
    return {
        //binTemplates: state.admin.binTemplates || [],
        attributes: state.attributes,
        isAdmin: Boolean(state.user?.currentUser?.administrator)
    }
}

export default connect(mapStateToProps)(IdealAvailable)