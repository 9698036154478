import React from 'react'
import NumberFormat from 'react-number-format'
import { bottom } from '@popperjs/core'

const splitPrice = (price) => {
    if (price==null || price==='') return [null];
    const idx = price.indexOf(' - ');
    if (idx < 0) return (
        <NumberFormat 
            displayType='text'
            decimalScale={2}
            fixedDecimalScale={true} 
            thousandSeparator={true}
            prefix='$'
            value={price}
        />
    )
    return <>
        <NumberFormat
            displayType='text'
            decimalScale={2}
            fixedDecimalScale={true} 
            thousandSeparator={true}
            prefix='$'
            value={price.slice(0, idx)}
        />
        -
        <NumberFormat
            displayType='text'
            decimalScale={2}
            fixedDecimalScale={true} 
            thousandSeparator={true}
            prefix='$'
            value={price.slice(idx+3)}
        />
    </>
}

export default function StoneCard({stone, deleteStone}) {
    return (
        <div className='confirmation-container border stone-card confirmation-text'>
            <div className='row even-split' style={{marginBottom: '0.5rem', color: 'var(--primary-colour)'}}>
                <div className='align-top bold'>
                    <i className={`fa${stone.virtual ? 'r' : 's'} fa-gem`} />&nbsp;
                    {stone.certNo}
                </div>
                <div className='right align-top semibold'>
                    {
                        stone.virtual
                        ? splitPrice(stone.total)
                        : <NumberFormat 
                            displayType='text'
                            decimalScale={2}
                            fixedDecimalScale={true} 
                            thousandSeparator={true}
                            prefix='$'
                            value={stone.total}
                        />
                    }
                </div>
            </div>
            <div className='row even-split'>
                <div className='caption40'>
                    <div className='align-top semibold'>Shape:</div>
                    <div className='align-top'>{stone.shapeCode}</div>
                </div>
                <div className='caption40'>
                    <div className='align-top semibold'>Weight:</div>
                    <div className='align-top'>{stone.virtual ? stone.weight : <NumberFormat displayType='text' decimalScale={2} fixedDecimalScale={true} value={stone.weight}/>}</div>
                </div>
            </div>
            <div className='row even-split'>
                <div className='caption40'>
                    <div className='align-top semibold'>Colour:</div>
                    <div className='align-top'>{stone.colour}</div>
                </div>
                <div className='caption40'>
                    <div className='align-top semibold'>Lab:</div>
                    <div className='align-top'>{stone.lab}</div>
                </div>
            </div>
            <div className='row even-split'>
                <div className='caption40'>
                    <div className='align-top semibold'>Clarity:</div>
                    <div className='align-top'>{stone.clarity}</div>
                </div>
                <div style={{verticalAlign: 'bottom'}}>
                    {
                        deleteStone
                        ? <button className='tab-button right' onClick={() => deleteStone(stone.parcelName)}><i className='fas fa-times-circle'/></button>
                        : null
                    }
                </div>
            </div>
        </div>
    )
}