import axios from 'axios'
import FileSaver from 'file-saver'
import {success, error} from '../components/common/toast'
import {
    START_LOADING,
    STOP_LOADING,
    GET_MONTH_BILLS
} from '../reducers/types';
import {ROOT_URL} from './config'

export function getBill(year, month, customerId) {
    return function(dispatch) {
        dispatch({type: START_LOADING, payload: 'Retrieving Invoice'})
        return axios({
            method: 'POST',
            url: `${ROOT_URL}/billing/current`,
            data: {year, month},
            ...(customerId ? {headers: {'X-DIALOG-CUSTOMER': customerId}} : {}),
            responseType: 'arraybuffer'
        })
        .finally(() => dispatch({type: STOP_LOADING, payload: 'Retrieving Invoice'}))
        .then(res => {
            let filename = null;
            const disposition = res.headers['content-disposition'];
            if (disposition && disposition.indexOf('attachment') !== -1) {
                const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                var matches = filenameRegex.exec(disposition);
                if (matches != null && matches[1]) { 
                    filename = matches[1].replace(/['"]/g, '');
                }
            }
            FileSaver.saveAs(new Blob([res.data], {type: res.headers['content-type']}), filename || "Dialog Invoice.xlsx");
        })
        .catch(err => {
            error(err)
            return Promise.reject(err);
        })
    }
}

export function specificBill(invoiceId) {
    return function(dispatch) {
        dispatch({type: START_LOADING, payload: 'Retrieving Invoice'})
        return axios({
            method: 'POST',
            url: `${ROOT_URL}/billing/specific`,
            data: {invoiceId},
            responseType: 'arraybuffer'
        })
        .finally(() => dispatch({type: STOP_LOADING, payload: 'Retrieving Invoice'}))
        .then(res => {
            let filename = null;
            const disposition = res.headers['content-disposition'];
            if (disposition && disposition.indexOf('attachment') !== -1) {
                const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                var matches = filenameRegex.exec(disposition);
                if (matches != null && matches[1]) { 
                    filename = matches[1].replace(/['"]/g, '');
                }
            }
            FileSaver.saveAs(new Blob([res.data], {type: res.headers['content-type']}), filename || "Dialog Invoice.xlsx");
        })
        .catch(err => {
            error(err)
            return Promise.reject(err);
        })
    }
}

export function createBill(year, month, customerId) {
    return function(dispatch) {
        dispatch({type: START_LOADING, payload: 'Retrieving Invoice'})
        return axios({
            method: 'POST',
            url: `${ROOT_URL}/billing/create`,
            data: {year, month},
            headers: {'X-DIALOG-CUSTOMER': customerId},
            responseType: 'arraybuffer'
        })
        .finally(() => dispatch({type: STOP_LOADING, payload: 'Retrieving Invoice'}))
        .then(res => {
            let filename = null;
            const disposition = res.headers['content-disposition'];
            if (disposition && disposition.indexOf('attachment') !== -1) {
                const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                var matches = filenameRegex.exec(disposition);
                if (matches != null && matches[1]) { 
                    filename = matches[1].replace(/['"]/g, '');
                }
            }
            FileSaver.saveAs(new Blob([res.data], {type: res.headers['content-type']}), filename || "Dialog Invoice.xlsx");
        })
        .catch(err => {
            error(err)
            return Promise.reject(err);
        })
    }
}

//TODO add an option to send the customer an email if the admin wants to
export function uploadBill(year, month, customerId, file, fileName, sendEmail) {
    return function(dispatch) {
        dispatch({type: START_LOADING, payload: 'Uploading Invoice'})
        return new Promise((resolve,reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result);
            reader.onerror = (err) => reject(err);
            reader.readAsDataURL(file);
        })
        .then(dataUrl => {
            let splitString = dataUrl.split(/[\:\,]/);
            let data = splitString[splitString.length-1];
            return axios({
                method: 'POST',
                url: `${ROOT_URL}/billing/save`,
                data: {
                    month: {year, month},
                    customerId,
                    fileName: fileName,
                    fileBody: data,
                    sendEmail
                }
            })
            .catch(err => {
                error(err);
                return Promise.reject(err);
            })
        })
        .finally(() => dispatch({type: STOP_LOADING, payload: 'Uploading Invoice'}))
    }
}

export function getBillsByMonth(year, month) {
    return function(dispatch) {
        dispatch({type: START_LOADING, payload: 'Retrieving Invoices'})
        return axios({
            method: 'POST',
            url: `${ROOT_URL}/billing/month`,
            data: {year, month}
        })
        .finally(() => dispatch({type: STOP_LOADING, payload: 'Retrieving Invoices'}))
        .then(res => {
            dispatch({type: GET_MONTH_BILLS, payload: {month: year+''+month, data: res.data}})
        })
        .catch(err => {
            error(err);
            return Promise.reject(err);
        })
    }
}

export function allMonthBills(year, month, customerId) {
    return function(dispatch) {
        dispatch({type: START_LOADING, payload: 'Retrieving Invoice'})
        return axios({
            method: 'POST',
            url: `${ROOT_URL}/billing/all`,
            data: {year, month},
            headers: {'X-DIALOG-CUSTOMER': customerId},
            responseType: 'arraybuffer'
        })
        .finally(() => dispatch({type: STOP_LOADING, payload: 'Retrieving Invoice'}))
        .then(res => {
            let filename = null;
            const disposition = res.headers['content-disposition'];
            if (disposition && disposition.indexOf('attachment') !== -1) {
                const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                var matches = filenameRegex.exec(disposition);
                if (matches != null && matches[1]) { 
                    filename = matches[1].replace(/['"]/g, '');
                }
            }
            FileSaver.saveAs(new Blob([res.data], {type: res.headers['content-type']}), filename || "Dialog Invoice.zip");
        })
        .catch(err => {
            error(err)
            return Promise.reject(err);
        })
    }
}

export function generateAllCustomers(year, month, excelFile) {
    return function(dispatch) {
        dispatch({type: START_LOADING, payload: 'Generating Monthly Fees'})
        return new Promise((resolve,reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result);
            reader.onerror = (err) => reject(err);
            reader.readAsDataURL(excelFile);
        })
        .then(dataUrl => {
            let splitString = dataUrl.split(/[\:\,]/);
            let data = splitString[splitString.length-1];
            return axios({
                method: 'POST',
                url: `${ROOT_URL}/billing/createall`,
                data: {year, month, base64Overrides: data},
                responseType: 'arraybuffer'
            })
            .finally(() => dispatch({type: STOP_LOADING, payload: 'Generating Monthly Fees'}))
            .then(res => {
                let filename = null;
                const disposition = res.headers['content-disposition'];
                if (disposition && disposition.indexOf('attachment') !== -1) {
                    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                    var matches = filenameRegex.exec(disposition);
                    if (matches != null && matches[1]) { 
                        filename = matches[1].replace(/['"]/g, '');
                    }
                }
                FileSaver.saveAs(new Blob([res.data], {type: res.headers['content-type']}), filename || "Dialog Fees.zip");
                console.log(res)
            })
        })
        .catch(err => {
            error(err)
            return Promise.reject(err);
        })
    }
}