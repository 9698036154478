import {toast} from 'react-toastify'
import React from 'react'
import 'react-toastify/dist/ReactToastify.css'

const successOpts = {pauseOnHover: true, autoClose: 4000, closeOnClick: true, hideProgressBar: true};
const errorOpts = { closeOnClick: true, autoClose: false, hideProgressBar: true };
let blocked = false;
let blocked401 = false;

const simpleBody = (icon, text) => {
    return () => (
        <div className='toast-notification-container-sm'>
            <span className='toast-notification-icon'><i className={icon} /></span>
            <span className="toast-notification-content">{text}</span>
        </div>
    )
}

export function success(message) {
    if (blocked) return;
    toast.success(simpleBody('fas fa-check-circle', message), successOpts)
}

export function error(err) {
    if (blocked) return;
    if (err) {
        if (Array.isArray(err.constructor)) {
            return err.map(e => error(e));
        }
        if (typeof err === 'string') {
            return toast.error(simpleBody('fas fa-exclamation-circle', err), errorOpts);
        }
        if (err instanceof Object || err instanceof Error) {
            const errOpts = errorOpts;
            if (err.response && err.response.status==402) errOpts.className = 'happier'
            if (err.response && err.response.status==401) {
                if (!blocked401) {
                    blocked401 = true;
                    return toast.error(
                        simpleBody('fas fa-exclamation-circle', 'Your session has expired. Please log in again.'), 
                        {...errOpts, onClose: (() => {blocked401 = false})}
                    );
                } else {
                    return;
                }
            }
            else if (err.response && err.response.data && err.response.data.message) {
                return toast.error(simpleBody('fas fa-exclamation-circle', err.response.data.message), errOpts);
            }
            else if (err.response && err.response.data && typeof err.response.data==='string' && !err.response.data.startsWith('<html>') && !err.response.data.startsWith('<!doctype html>')) {
                return toast.error(simpleBody('fas fa-exclamation-circle', err.response.data), errOpts);
            }
            else if (err.message) {
                return toast.error(simpleBody('fas fa-exclamation-circle', err.message), errOpts);
            }
        }
        return toast.error(simpleBody('fas fa-exclamation-circle', "An error occurred."), errorOpts);
    }
}

export function blockAll() {
    toast.dismiss();
    blocked = true;
}
export function unblockAll() {
    blocked = false;
}
export function clearAlerts() {
    toast.dismiss();
}
export function unblock401() {
    blocked401 = false;
}