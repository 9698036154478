import React, {useMemo, useState} from 'react'
import {connect} from 'react-redux'
import NumberFormat from 'react-number-format'
import Modal from 'react-responsive-modal'
import StoneDetails from '../inventory/details-2'
import * as actions from '../../actions'
import {success} from './toast'
import {ReactComponent as Certificate} from '../../assets/icons/certificate-7.svg'
import CertModal from './cert_modal'

const Gallery = function({visible, stones, fluorescences, shapes, ...props}) {
    const photoStones = useMemo(() => {
        return stones.map(x => x._original).filter(x => x.stillPhotoUrl)
    }, [stones])

    const fluorMap = useMemo(() => {
        return fluorescences.reduce((obj,fl) => ({...obj, [fl.fluorescence]: fl.description}), {})
    }, [fluorescences])

    const addToCart = (parcelName) => {
        props.addToCart(parcelName)
        .then(() => {
            success('Added to Cart')
            props.getAvailableInventory()
        })
    }

    const useDefaultImage = (e) => {
        e.currentTarget.onerror = '';
        e.currentTarget.src = 'http://files.hragroup.com/DialogDiamondIcon.png'
    }

    const [openDetails, setOpenDetails] = useState(null)
    const [openPdfUrl, setOpenPdfUrl] = useState(null)
    const [mousedStone, setMousedStone] = useState(null)
    const shapeMap = useMemo(() => shapes.reduce((map,shape) => map.set(shape.shapeCode, shape.description), new Map()))

    const handleMouseOnPhoto = stone => {
        if (stone.photoUrl.toLowerCase().includes('vision360.html') && (
            stone.photoUrl.startsWith('https://s3.us-west-2.amazonaws.com/v360.videos/')
            || stone.photoUrl.startsWith('https://rydiam.azureedge.net/stones/v360/')
        )) {
            setMousedStone(stone.parcelName)
        }
    }
    const deButtonV360 = (url) => {
        let surlIndex = url.lastIndexOf('&surl=')
        if (surlIndex < 0) return url + '&btn=0&&zoomslide=0&sv=0&isBorderRadius=0'
        else return url.substring(0, surlIndex) + '&btn=0&&zoomslide=0&sv=0&isBorderRadius=0' + url.substring(surlIndex)
    }

    const showCert = async (stone) => {
        if (stone.lab === 'FM') {
            const foundUrl = await props.fmCertExists(stone.shortCertNo)
            if (foundUrl) {
                console.log('cert', foundUrl)
                setOpenPdfUrl({pdfUrl: foundUrl})
                return
            }
        } else if (stone.labGrown) {
            props.getLabGrownCert(stone.parcelName)
            .then(certData => {
                setOpenPdfUrl(certData)
            })
        } else {
            props.getCert(stone.parcelName)
            .then(certData => {
                setOpenPdfUrl(certData)
            })
        }
    }

    const hidePrice = props.priceMode === 'NONE'

    if (!visible || !photoStones?.length) return null
    return <>
        <div className='gallery-component'>
            {photoStones.map(stone => (
                <div key={stone.parcelName} className='gallery-card' onClick={() => setOpenDetails(stone.parcelName)}>
                    <div
                        className='gallery-photo' 
                        onMouseEnter={() => handleMouseOnPhoto(stone)}
                        onMouseLeave={() => setMousedStone(null)}
                    >
                        <img className={mousedStone===stone.parcelName ? 'no-render' : ''} src={stone.stillPhotoUrl} loading='lazy' onError={useDefaultImage} />
                        {
                            mousedStone===stone.parcelName ? 
                            <iframe
                                src={deButtonV360(stone.photoUrl)}
                                scrolling="no" seamless="seamless" 
                                className={`video-iframe${stone.photoUrl.toLowerCase().includes('vision360.html') ? '' : (stone.photoUrl.toLowerCase().includes('dnadiamond.net') || stone.photoUrl.toLowerCase().includes('/dnadata/') ? '-dnad' : '-old')}`}
                            />
                            : null
                        }
                    </div>
                    <div className='gallery-caption'>
                        <span className='tier'>{stone.tier ? `Dialog ${stone.tier}` : '\xa0'}</span>
                        <span className='basic-properties'>{shapeMap.get(stone.shapeCode) ?? stone.shapeCode} {stone.weight}ct {stone.colour} {stone.clarity}</span>
                        <span className='minor-properties'>{stone.cut || '-'}/{stone.polish}/{stone.symmetry} {fluorMap[stone.fluorescence] ?? stone.fluorescence}</span>
                        <span className='cert'>{stone.lab} {stone.certNo}</span>
                        <span className='partial-price'>
                            {!hidePrice && <>
                                {stone.displayRapPercent ? <>
                                    <NumberFormat value={stone.displayRapPercent} displayType='text' suffix='%' decimalScale={2} fixedDecimalScale={false} />
                                    &nbsp;|&nbsp;
                                </> : null}
                                <NumberFormat value={stone.ppc} displayType='text' thousandSeparator={true} prefix='$ ' decimalScale={2} fixedDecimalScale={true} /> P/C
                            </>}
                        </span>
                        <span className='total-price'><NumberFormat value={stone.total} displayType={'text'} thousandSeparator={true} prefix={'$ '} decimalScale={2} fixedDecimalScale={true} /></span>
                        <button 
                            className={`list-button cart-in-caption${hidePrice ? '-2' : ''}`}
                            onClick={(e) => {
                                e.stopPropagation()
                                addToCart(stone.parcelName)
                            }}
                        >{
                            stone.inCart ?
                            <i className='fa fa-check-circle' /> : 
                            <i className='fa fa-shopping-cart' />
                        }</button>
                        <button 
                            className={`list-button cert-in-caption${hidePrice ? '-2' : ''}${stone.lab==='GIA' || stone.lab==='GIA-LG' || (stone.lab==='FM' && stone.shortCertNo?.length && stone.shortCertNo.length < 10) ? '' : ' no-render'}`}
                            onClick={(e) => {
                                e.stopPropagation()
                                showCert(stone)
                            }} 
                        >
                            <Certificate />
                        </button>
                    </div>
                </div>
            ))}
        </div>
        <Modal
            open={Boolean(openDetails)} 
            onClose={() => setOpenDetails(null)}
            showCloseIcon={true}
            styles={{modal: {padding: '3rem 3rem 2rem 3rem', maxWidth: 'unset', maxHeight: '90vh', overflowY: 'scroll'}}}
            focusTrapped={false}
            center
        >
            <StoneDetails parcelName={openDetails} />
        </Modal>
        <CertModal report={openPdfUrl}/>
    </>
}

function mapStateToProps(store) {
    return {
        fluorescences: store.attributes.fluorescences || [],
        priceMode: store?.user?.currentUser?.showPrices || 'NONE',
        shapes: store.attributes.shapes || []
    }
}

export default connect(mapStateToProps, actions)(Gallery)