import React, {useState, useMemo, useEffect} from 'react'
import {connect} from 'react-redux'
import * as actions from '../../actions'
import moment from 'moment-timezone'

const InvoiceModal = ({customer, bills, getBillsByMonth, specificBill, allMonthBills, initialDate}) => {
    const [date, setDate] = useState(initialDate || "")

    useEffect(() => {
        if (date && date.length===6) getBillsByMonth(date.slice(0,4), date.slice(4))
    }, [date, customer?.customerId])

    if (!customer || customer.customerId==null) return null

    const showBills = (bills[date] || []).filter(b => b.customerId == customer.customerId).sort((a,b) => a.uploadDate-b.uploadDate)

    const downloadBill = (id) => {
        specificBill(id)
    }
    const wholeMonth = () => {
        const year = date.slice(0,4), month = date.slice(4)
        allMonthBills(year, month, customer.customerId)
    }

    const renderMonthOptions = (pastOnly=false) => {
        const startDate = moment((customer && customer.firstAction) ? customer.firstAction : '2017-01-01').startOf('month');
        const endDate = pastOnly ? moment().add(-1, 'months') : moment();
        let date = startDate;
        const results = [];
        while (date < endDate) {
            results.push(<option key={date.unix()} value={date.format('YYYYMM')}>{date.format('MMM YYYY')}</option>)
            date = date.add(1, 'months');
        }
        return results.reverse();
    }

    const handleChange = e => {
        setDate(e.target.value)
    }

    return (
        <>
            <div className="inv_modal--download-bill">
                <span className="inv_modal--download-bill__title">Invoices</span>
                <select 
                    name='billDownloadSelection' 
                    className='inv_modal--month_select' 
                    onChange={handleChange}
                    value={date}
                >
                    <option value=''>Select a Month...</option>
                    {renderMonthOptions()}
                </select>
            </div>
            <hr/>
            {
                showBills.length ? null : 
                <div>No invoices for the selected month.</div>
            }
            {
                showBills.map(bill => (
                    <div key={bill.invoiceId} className='row spaced inv_modal--row'>
                        <div>
                            <button 
                                className={`list-button${bill.active ? '' : ' semi-disabled'}`}
                                onClick={() => downloadBill(bill.invoiceId)}
                            >
                                    <i className='fas fa-file-download'/>
                            </button>
                        </div>
                        <div>{bill.fileName}</div>
                    </div>
                ))
            }
            {
                showBills.length > 1 ? 
                <>
                    <hr/>
                    <div className='row spaced inv_modal--row'>
                        <div>
                            <button className='list-button' onClick={wholeMonth}>
                                <i className='fas fa-file-download'/>
                            </button>
                        </div>
                        <div>All (zip)</div>
                    </div>
                </>
                : null
            }
        </>
    )
}

function mapStateToProps(state) {
    return {
        bills: state.billing.bills || {}
    }
}

export default connect(mapStateToProps, actions)(InvoiceModal);