import axios from 'axios'
import Excel from 'exceljs'
import FileSaver from 'file-saver'
import {
    START_LOADING,
    STOP_LOADING,
    SET_AVAILABLE_LG,
    SET_MY_LG,
    SET_PAST_LG,
    SET_CART_LG,
    ADD_DETAILS_LG
} from '../reducers/types'
import {success, error} from '../components/common/toast'
import {ROOT_URL} from './config'

export function getAvailableLabGrown() {
    return function(dispatch) {
        dispatch({type: START_LOADING, payload: 'Retrieving Lab Grown Inventory'})
        return axios({
            method: 'POST',
            url: `${ROOT_URL}/labgrown/available`
        })
        .finally(() => dispatch({type: STOP_LOADING, payload: 'Retrieving Lab Grown Inventory'}))
        .then(res => {
            dispatch({type: SET_AVAILABLE_LG, payload: res.data});
        })
        .catch(err => {
            error(err)
            return Promise.reject(err);
        })
    }
}

export function getCurrentLabGrown() {
    return function(dispatch) {
        dispatch({type: START_LOADING, payload: 'Retrieving Lab Grown Inventory'})
        return axios({
            method: 'POST',
            url: `${ROOT_URL}/labgrown/customer`
        })
        .finally(() => dispatch({type: STOP_LOADING, payload: 'Retrieving Lab Grown Inventory'}))
        .then(res => {
            dispatch({type: SET_MY_LG, payload: res.data});
        })
        .catch(err => {
            error(err)
            return Promise.reject(err);
        })
    }
}

export function getPastLabGrown() {
    return function(dispatch) {
        dispatch({type: START_LOADING, payload: 'Retrieving Lab Grown Inventory'})
        return axios({
            method: 'POST',
            url: `${ROOT_URL}/labgrown/past`
        })
        .finally(() => dispatch({type: STOP_LOADING, payload: 'Retrieving Lab Grown Inventory'}))
        .then(res => {
            dispatch({type: SET_PAST_LG, payload: res.data});
        })
        .catch(err => {
            error(err)
            return Promise.reject(err);
        })
    }
}

export function addToLabGrownCart(parcelNames) {
    return function(dispatch) {
        dispatch({type: START_LOADING, payload: 'Updating Lab Grown'})
        return axios({
            method: 'POST',
            url: `${ROOT_URL}/labgrown/cart_stones`,
            data: {parcelNames: Array.isArray(parcelNames) ? parcelNames : [parcelNames]}
        })
        .finally(() => dispatch({type: STOP_LOADING, payload: 'Updating Lab Grown'}))
        .catch(err => {
            error(err);
            return Promise.reject(err);
        })
    }
}

export function getLabGrownCart() {
    return function(dispatch) {
        dispatch({type: START_LOADING, payload: 'Retrieving Cart'})
        return axios({
            method: 'POST',
            url: `${ROOT_URL}/labgrown/cart`
        })
        .finally(() => dispatch({type: STOP_LOADING, payload: 'Retrieving Cart'}))
        .then(res => {
            dispatch({type: SET_CART_LG, payload: res.data});
        })
        .catch(err => {
            error(err)
            return Promise.reject(err);
        })
    }
}

export function removeFromLabGrownCart(parcelNames) {
    return function(dispatch) {
        dispatch({type: START_LOADING, payload: 'Updating Cart'})
        return axios({
            method: 'POST',
            url: `${ROOT_URL}/labgrown/uncart_stones`,
            data: {parcelNames: Array.isArray(parcelNames) ? parcelNames : [parcelNames]}
        })
        .finally(() => dispatch({type: STOP_LOADING, payload: 'Updating Cart'}))
        .catch(err => {
            error(err);
            return Promise.reject(err);
        })
    }
}

export function requestLabGrown(parcelNames, remarks) {
    return function(dispatch) {
        dispatch({type: START_LOADING, payload: 'Sending Request'})
        return axios({
            method: 'POST',
            url: `${ROOT_URL}/labgrown/request_stones`,
            data: {
                parcelNames: Array.isArray(parcelNames) ? parcelNames : [parcelNames],
                remarks: remarks ? remarks : undefined
            }
        })
        .finally(() => dispatch({type: STOP_LOADING, payload: 'Sending Request'}))
        .catch(err => {
            error(err);
            return Promise.reject(err);
        })
    }
}

export function returnLabGrown(parcelNames, remarks) {
    return function(dispatch) {
        dispatch({type: START_LOADING, payload: 'Signalling Return'})
        return axios({
            method: 'POST',
            url: `${ROOT_URL}/labgrown/return`,
            data: {
                parcelNames: Array.isArray(parcelNames) ? parcelNames : [parcelNames],
                remarks: remarks ? remarks : undefined
            }
        })
        .finally(() => dispatch({type: STOP_LOADING, payload: 'Signalling Return'}))
        .catch(err => {
            error(err);
            return Promise.reject(err);
        })
    }
}

export function sellLabGrown(parcelNames, remarks) {
    return function(dispatch) {
        dispatch({type: START_LOADING, payload: 'Making Sale'})
        return axios({
            method: 'POST',
            url: `${ROOT_URL}/labgrown/sale`,
            data: {
                parcelNames: Array.isArray(parcelNames) ? parcelNames : [parcelNames],
                remarks: remarks ? remarks : undefined
            }
        })
        .finally(() => dispatch({type: STOP_LOADING, payload: 'Making Sale'}))
        .catch(err => {
            error(err);
            return Promise.reject(err);
        })
    }
}

export function getLabGrownDetails(parcelName) {
    return function(dispatch) {
        dispatch({type: START_LOADING, payload: 'Retrieving Lab Grown'})
        return axios({
            method: 'POST',
            url: `${ROOT_URL}/labgrown/details`,
            data: {parcelName}
        })
        .finally(() => dispatch({type: STOP_LOADING, payload: 'Retrieving Lab Grown'}))
        .then(res => {
            dispatch({type: ADD_DETAILS_LG, payload: res.data});
        })
        .catch(err => {
            error(err);
            return Promise.reject(err);
        })
    }
}

export function getLabGrownCert(parcelName) {
    return function(dispatch) {
        dispatch({type: START_LOADING, payload: 'Retrieving Cert'})
        return axios({
            method: 'POST',
            url: `${ROOT_URL}/labgrown/cert`,
            data: {parcelName}
        })
        .finally(() => dispatch({type: STOP_LOADING, payload: 'Retrieving Cert'}))
        .then(res => {
            return res.data
        })
        .catch(err => {
            error(err);
            return Promise.reject(err);
        })
    }
}

export function deleteLastLabGrownHistory(historyRow) {
    return function(dispatch) {
        dispatch({type: START_LOADING, payload: 'Cancelling History'})
        return axios({
            method: 'POST',
            url: `${ROOT_URL}/labgrown/undo`,
            data: {
                parcelName: historyRow.parcelName,
                action: historyRow.type
            }
        })
        .finally(() => dispatch({type: STOP_LOADING, payload: 'Cancelling History'}))
        .then(res => {
            dispatch({type: ADD_DETAILS_LG, payload: res.data});
        })
        .catch(err => {
            error(err);
            return Promise.reject(err);
        })
    }
}

export function backdateLabGrownHistory(historyRow, newDate) {
    return function(dispatch) {
        dispatch({type: START_LOADING, payload: 'Changing Date'})
        return axios({
            method: 'POST',
            url: `${ROOT_URL}/labgrown/change_date`,
            data: {
                parcelName: historyRow.parcelName,
                action: historyRow.type,
                newDate
            }
        })
        .finally(() => dispatch({type: STOP_LOADING, payload: 'Changing Date'}))
        /*.then(res => {
            dispatch({type: ADD_DETAILS, payload: res.data});
        })*/
        .catch(err => {
            error(err);
            return Promise.reject(err);
        })
    }
}

export function lgSimilarStones(params) {
    return function(dispatch) {
        dispatch({type: START_LOADING, payload: 'Checking similar diamonds'})
        return axios({
            method: 'POST',
            url: `${ROOT_URL}/labgrown/similar`,
            data: params
        })
        .finally(() => dispatch({type: STOP_LOADING, payload: 'Checking similar diamonds'}))
        .then(({data}) => data)
    }
}

export function excelLGExport(stones, extraColumns, {hideTier} = {}) {
    return function(dispatch) {
        const workbook = new Excel.Workbook();
        workbook.creator = 'Dialog';
        workbook.created = new Date();
        const sheet = workbook.addWorksheet('Dialog Lab Grown Inventory');
        const linkFmt = {underline: true, color: {argb: 'FF0000FF'}}
        const twoDecimals = x => Number(x).toFixed(2)
        let columns = [
            {header: 'Parcel Name', key: 'parcelName', width: 19},
            {header: 'Lab', key: 'lab', width: 15},
            {header: 'Cert Number', key: 'certNo', width: 18, excelCell: (cert, {lab}) => (lab==='GIA' || lab==='GIA-LG') ? {text: cert, hyperlink: `https://www.gia.edu/report-check?reportno=${cert}`} : cert},
            {header: 'Weight', key: 'weight', width: 8, style: {numFmt: '0.00#'}},
            {header: 'Shape', key: 'shapeName', width: 8},
            {header: 'Colour', key: 'colour', width: 8},
            {header: 'Clarity', key: 'clarity', width: 8},
            {header: 'Fluor', key: 'fluorescence', width: 8},
            {header: 'Cut', key: 'cut', width: 8},
            {header: 'Pol', key: 'polish', width: 8},
            {header: 'Sym', key: 'symmetry', width: 8},
            {header: 'Table %', key: 'tablePct', width: 8},
            {header: 'Depth %', key: 'depthPct', width: 8.5},
            {header: 'Measurements', key: 'length', width: 14.5, excelCell: (value, stone) => `${twoDecimals(stone.length)}-${twoDecimals(stone.width)}\u00d7${twoDecimals(stone.height)}`},
            {header: 'Ratio', key: 'width', width: 8, excelCell: (value, stone) => twoDecimals(Math.max(stone.length,stone.width) / Math.min(stone.length, stone.width))},
            ...(hideTier ? [] : [{header: 'Tier', key: 'tier', width: 9.8}]),
            //{header: 'RAP', key: 'displayRap', width: 10},
            //{header: 'RAP %', key: 'displayRapPercent', width: 10},
            {header: 'Price', key: 'total', width: 12, style: {numFmt: '$#,##0.00'}},
            {header: 'Status', key: 'status', width: 12},
            {header: 'V360', key: 'photoUrl', width: 12, excelCell: value => ({text: 'Image/Video', hyperlink: value})}
        ]
        if (Array.isArray(extraColumns) && extraColumns.length) {
            columns = columns.concat(extraColumns.map(col => ({
                header: col.Header,
                key: col.accessor,
                width: col.minWidth / 5,
                excelCell: col.excelCell
            })))
        }
        sheet.columns = columns;
        sheet.addRows(stones);

        for (let col of columns.filter(c => c.excelCell)) {
            const fileColumn = sheet.getColumn(col.key);
            if (!fileColumn) continue;
            fileColumn.eachCell((cell, row) => {
                if (row > 1 && cell.value) {
                    const newValue = col.excelCell(cell.value, stones[row-2]);
                    cell.value = newValue;
                    if (newValue.hyperlink) cell.font = linkFmt
                }
            })
        }

        sheet.views = [{ state: 'frozen', ySplit: 1 }]
        return workbook.xlsx.writeBuffer()
        .then(buffer => {
            FileSaver.saveAs(new Blob([buffer], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"}), `Dialog Export.xlsx`);
        })
    }
}