import React, {Component} from 'react'
import {connect} from 'react-redux'
import * as actions from '../../actions'
import StoneTable from '../common/stone_table'
import ConfirmStonesModal from '../common/confirm_stones_modal'

class CartLabGrown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedStones: {},
            requestRemarks: null
        };
    }

    componentDidMount() {
        this.refresh();
    }

    refresh = () => {
        this.props.getLabGrownCart()
    }

    handleRowSelect = (selected) => {
        this.setState({selectedStones: selected});
    }

    getSelectedStones = () => {
        if (!this.state.selectedStones) return [];
        return Object.keys(this.state.selectedStones).filter(parcelName => this.state.selectedStones[parcelName]);
    }

    removeSelectedStone = parcelName => {
        this.setState({selectedStones: {...this.state.selectedStones, [parcelName]: false}})
    }

    removeFromCart = (stone) => {
        this.props.removeFromLabGrownCart(stone.parcelName)
        .then(() => this.props.getLabGrownCart())
    }

    requestStones = () => {
        const {stones} = this.getSelectedStones().reduce(({stones,desires},parcelName) => {
            if (parcelName.startsWith('desire-')) return {stones, desires: [...desires, Number(parcelName.substring(7))]};
            else return {stones: [...stones, parcelName], desires}
        }, {stones: [], desires: []});
        if (!stones.length) return;
        return this.props.requestLabGrown(stones, this.state.requestRemarks)
        .then(() => this.setState({requestRemarks: null, selectedStones: {}}))
        .then(() => {
            this.refresh()
            this.props.getCurrentLabGrown()
        })
    }

    renderInventory = () => {
        const inventory = this.props.inventory || [];
        const selected = this.getSelectedStones();
        return (
            <div>
                <div className='row spaced'>
                    <ConfirmStonesModal
                        confirm={this.requestStones} 
                        title='Confirm Request'
                        message={`Review ${selected.length} stones and click "Request Stones" to confirm.`} 
                        confirmCaption='Request Stones'
                        deleteStone={this.removeSelectedStone}
                        trigger={onClick => <button className='btn' onClick={onClick} disabled={selected.length==0}>Request</button>}
                        stones={
                            inventory.filter(stone => selected.includes(stone.parcelName))
                        }
                        comment={this.state.requestRemarks}
                        updateComment={(e) => this.setState({requestRemarks: e.target.value})}
                    />
                </div>
                <StoneTable 
                    labGrown={true}
                    data={inventory}
                    extraColumns={[
                        {
                            Header: '',
                            id: 'removeBtn',
                            width: 50,
                            sortable: false,
                            filterable: false,
                            Cell: (row) => row.original ? (
                                <button
                                    title="Remove from Cart"
                                    className="list-button"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        this.removeFromCart(row.original);
                                    }}
                                >{
                                    <i className='fa fa-times-circle' />
                                }</button>
                            ) : null
                        }
                    ]}
                    selectedRows={this.state.selectedStones}
                    onSelectRows={this.handleRowSelect}
                    hideTier={false}
                    exporter={this.props.excelLGExport}
                    tableId='cartLG'
                    large={true}
                />
            </div>
        )
    }

    render() {
        return (
            <div style={{width: '100%'}}>
                {this.renderInventory()}
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        inventory: state.labGrown.cart
    }
}

export default connect(mapStateToProps, actions)(CartLabGrown)