import {createStore, applyMiddleware} from "redux";
import reducers from "../reducers/index";
import thunk from 'redux-thunk'
import {persistStore, persistCombineReducers} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {composeWithDevTools} from 'redux-devtools-extension'

const reducer = persistCombineReducers({ key: 'root', storage }, reducers);
const store = createStore(reducer, composeWithDevTools(applyMiddleware(thunk))); //for dev
//const store = createStore(reducer, applyMiddleware(thunk)); //for production
const persistor = persistStore(store);

export {store, persistor}