import axios from 'axios'
import Excel from 'exceljs'
import FileSaver from 'file-saver'
import {
    START_LOADING,
    STOP_LOADING,
    SET_AVAILABLE,
    SET_MY_INVENTORY,
    SET_PAST_INVENTORY,
    SET_CART,
    ADD_DETAILS,
    SET_CART_DESIRES,
    SET_OPEN_DESIRES,
    SET_CLOSED_DESIRES,
    ADD_DESIRE_PRICE
} from '../reducers/types'
import {success, error} from '../components/common/toast'
import {getInscriptionNo} from '../utils'
import {ROOT_URL} from './config'

export function getAvailableInventory() {
    return function(dispatch) {
        dispatch({type: START_LOADING, payload: 'Retrieving Inventory'})
        return axios({
            method: 'POST',
            url: `${ROOT_URL}/inventory/available`
        })
        .finally(() => dispatch({type: STOP_LOADING, payload: 'Retrieving Inventory'}))
        .then(res => {
            dispatch({type: SET_AVAILABLE, payload: res.data});
        })
        .catch(err => {
            error(err)
            return Promise.reject(err);
        })
    }
}

export function getCurrentInventory() {
    return function(dispatch) {
        dispatch({type: START_LOADING, payload: 'Retrieving Inventory'})
        return axios({
            method: 'POST',
            url: `${ROOT_URL}/inventory/customer`
        })
        .finally(() => dispatch({type: STOP_LOADING, payload: 'Retrieving Inventory'}))
        .then(res => {
            dispatch({type: SET_MY_INVENTORY, payload: res.data});
        })
        .catch(err => {
            error(err)
            return Promise.reject(err);
        })
    }
}

export function getPastInventory() {
    return function(dispatch) {
        dispatch({type: START_LOADING, payload: 'Retrieving Inventory'})
        return axios({
            method: 'POST',
            url: `${ROOT_URL}/inventory/past`
        })
        .finally(() => dispatch({type: STOP_LOADING, payload: 'Retrieving Inventory'}))
        .then(res => {
            dispatch({type: SET_PAST_INVENTORY, payload: res.data});
        })
        .catch(err => {
            error(err)
            return Promise.reject(err);
        })
    }
}

export function addToCart(parcelNames) {
    return function(dispatch) {
        dispatch({type: START_LOADING, payload: 'Updating Cart'})
        return axios({
            method: 'POST',
            url: `${ROOT_URL}/inventory/cart_stones`,
            data: {parcelNames: Array.isArray(parcelNames) ? parcelNames : [parcelNames]}
        })
        .finally(() => dispatch({type: STOP_LOADING, payload: 'Updating Cart'}))
        .catch(err => {
            error(err);
            return Promise.reject(err);
        })
    }
}

export function getCart() {
    return function(dispatch) {
        dispatch({type: START_LOADING, payload: 'Retrieving Cart'})
        return axios({
            method: 'POST',
            url: `${ROOT_URL}/inventory/cart`
        })
        .finally(() => dispatch({type: STOP_LOADING, payload: 'Retrieving Cart'}))
        .then(res => {
            dispatch({type: SET_CART, payload: res.data});
        })
        .catch(err => {
            error(err)
            return Promise.reject(err);
        })
    }
}

export function removeFromCart(parcelNames) {
    return function(dispatch) {
        dispatch({type: START_LOADING, payload: 'Updating Cart'})
        return axios({
            method: 'POST',
            url: `${ROOT_URL}/inventory/uncart_stones`,
            data: {parcelNames: Array.isArray(parcelNames) ? parcelNames : [parcelNames]}
        })
        .finally(() => dispatch({type: STOP_LOADING, payload: 'Updating Cart'}))
        .catch(err => {
            error(err);
            return Promise.reject(err);
        })
    }
}

export function requestStones(parcelNames, remarks, desireIds) {
    return function(dispatch) {
        dispatch({type: START_LOADING, payload: 'Sending Request'})
        return (
            (!desireIds || !desireIds.length) 
            ? axios({
                method: 'POST',
                url: `${ROOT_URL}/inventory/request_stones`,
                data: {
                    parcelNames: Array.isArray(parcelNames) ? parcelNames : [parcelNames],
                    remarks: remarks ? remarks : undefined
                }
            })
            : axios({
                method: 'POST',
                url: `${ROOT_URL}/inventory/request_stones_similar`,
                data: {
                    parcelNames: Array.isArray(parcelNames) ? parcelNames : (parcelNames ? [parcelNames] : []),
                    remarks: remarks ? remarks : undefined,
                    desires: desireIds
                }
            })
        )
        .finally(() => dispatch({type: STOP_LOADING, payload: 'Sending Request'}))
        .catch(err => {
            error(err);
            return Promise.reject(err);
        })
    }
}

export function returnStones(parcelNames, remarks) {
    return function(dispatch) {
        dispatch({type: START_LOADING, payload: 'Signalling Return'})
        return axios({
            method: 'POST',
            url: `${ROOT_URL}/inventory/return`,
            data: {
                parcelNames: Array.isArray(parcelNames) ? parcelNames : [parcelNames],
                remarks: remarks ? remarks : undefined
            }
        })
        .finally(() => dispatch({type: STOP_LOADING, payload: 'Signalling Return'}))
        .catch(err => {
            error(err);
            return Promise.reject(err);
        })
    }
}

export function sellStones(parcelNames, remarks) {
    return function(dispatch) {
        dispatch({type: START_LOADING, payload: 'Making Sale'})
        return axios({
            method: 'POST',
            url: `${ROOT_URL}/inventory/sale`,
            data: {
                parcelNames: Array.isArray(parcelNames) ? parcelNames : [parcelNames],
                remarks: remarks ? remarks : undefined
            }
        })
        .finally(() => dispatch({type: STOP_LOADING, payload: 'Making Sale'}))
        .catch(err => {
            error(err);
            return Promise.reject(err);
        })
    }
}

export function getDetails(parcelName) {
    return function(dispatch) {
        dispatch({type: START_LOADING, payload: 'Retrieving Stone'})
        return axios({
            method: 'POST',
            url: `${ROOT_URL}/inventory/details`,
            data: {parcelName}
        })
        .finally(() => dispatch({type: STOP_LOADING, payload: 'Retrieving Stone'}))
        .then(res => {
            dispatch({type: ADD_DETAILS, payload: res.data});
        })
        .catch(err => {
            error(err);
            return Promise.reject(err);
        })
    }
}

export function getCert(parcelName) {
    return function(dispatch) {
        dispatch({type: START_LOADING, payload: 'Retrieving Cert'})
        return axios({
            method: 'POST',
            url: `${ROOT_URL}/inventory/cert`,
            data: {parcelName}
        })
        .finally(() => dispatch({type: STOP_LOADING, payload: 'Retrieving Cert'}))
        .then(res => {
            return res.data
        })
        .catch(err => {
            error(err);
            return Promise.reject(err);
        })
    }
}

export function fmCertExists(inscrNo) {
    return function(dispatch) {
        if (!inscrNo) return
        const path = `https://dialogvault.com/certs/fm/${inscrNo}.pdf`;
        return fetch(path, {method: 'HEAD'})
        .then(resp => {
            if (resp.headers.get('content-type') === 'application/pdf') return path
        })
        .catch(() => {})
    }
}

export function deleteLastHistory(historyRow) {
    return function(dispatch) {
        dispatch({type: START_LOADING, payload: 'Cancelling History'})
        return axios({
            method: 'POST',
            url: `${ROOT_URL}/inventory/undo`,
            data: {
                parcelName: historyRow.parcelName,
                action: historyRow.type
            }
        })
        .finally(() => dispatch({type: STOP_LOADING, payload: 'Cancelling History'}))
        .then(res => {
            dispatch({type: ADD_DETAILS, payload: res.data});
        })
        .catch(err => {
            error(err);
            return Promise.reject(err);
        })
    }
}

export function backdateHistory(historyRow, newDate) {
    return function(dispatch) {
        dispatch({type: START_LOADING, payload: 'Changing Date'})
        return axios({
            method: 'POST',
            url: `${ROOT_URL}/inventory/change_date`,
            data: {
                parcelName: historyRow.parcelName,
                action: historyRow.type,
                newDate
            }
        })
        .finally(() => dispatch({type: STOP_LOADING, payload: 'Changing Date'}))
        /*.then(res => {
            dispatch({type: ADD_DETAILS, payload: res.data});
        })*/
        .catch(err => {
            error(err);
            return Promise.reject(err);
        })
    }
}

export function newDesire(desire) {
    return function(dispatch) {
        dispatch({type: START_LOADING, payload: 'Updating Cart'})
        return axios({
            method: 'POST',
            url: `${ROOT_URL}/inventory/desire_stone`,
            data: desire
        })
        .finally(() => dispatch({type: STOP_LOADING, payload: 'Updating Cart'}))
        .catch(err => {
            error(err);
            return Promise.reject(err);
        })
    }
}

export function updateDesire(desire) {
    return function(dispatch) {
        dispatch({type: START_LOADING, payload: 'Updating requests by specification'})
        return axios({
            method: 'POST',
            url: `${ROOT_URL}/inventory/update_desire`,
            data: Array.isArray(desire) ? desire : [desire]
        })
        .finally(() => dispatch({type: STOP_LOADING, payload: 'Updating requests by specification'}))
        .catch(err => {
            error(err);
            return Promise.reject(err);
        })
    }
}

export function getDesires(type) {
    return function(dispatch) {
        dispatch({type: START_LOADING, payload: 'Retrieving requests by specification'})
        return axios({
            method: 'POST',
            url: `${ROOT_URL}/inventory/${type}_desires`
        })
        .finally(() => dispatch({type: STOP_LOADING, payload: 'Retrieving requests by specification'}))
        .then(res => {
            if (Array.isArray(res.data)) res.data = res.data.map(d => ({
                virtual: true,
                desire: d,
                parcelName: `desire-${d.desireId}`,
                certNo: 'Similar Stones',
                weight: d.weight ? `${d.weight.minimum || ''} - ${d.weight.maximum || ''}` : 'All',
                shapeCode: Array.isArray(d.shape) ? d.shape.join(', ') : 'All',
                colour: Array.isArray(d.colour) ? d.colour.join(', ') : 'All',
                clarity: Array.isArray(d.clarity) ? d.clarity.join(', ') : 'All',
                fluorescence: Array.isArray(d.fluorescence) ? d.fluorescence.join(', ') : 'All',
                cut: Array.isArray(d.cut) ? d.cut.join(', ') : 'All',
                polish: Array.isArray(d.polish) ? d.polish.join(', ') : 'All',
                symmetry: Array.isArray(d.symmetry) ? d.symmetry.join(', ') : 'All'
            }))
            if (type==='carted') dispatch({type: SET_CART_DESIRES, payload: res.data});
            else if (type==='open') dispatch({type: SET_OPEN_DESIRES, payload: res.data});
            else dispatch({type: SET_CLOSED_DESIRES, payload: res.data});
            return res.data;
        })
        .catch(err => {
            error(err);
            return Promise.reject(err);
        })
    }
}

export function priceDesire(row) {
    return function(dispatch) {
        return axios({
            method: 'POST',
            url: `${ROOT_URL}/inventory/desire_price`,
            data: row.desire
        })
        .then(({data}) => {
            dispatch({type: ADD_DESIRE_PRICE, payload: data, record: row});
            return data;
        })
        .catch(err => {
            dispatch({type: ADD_DESIRE_PRICE, payload: {error: true}, record: row});
            return Promise.reject(err);
        })
    }
}

export function similarStones(params) {
    return function(dispatch) {
        dispatch({type: START_LOADING, payload: 'Checking similar diamonds'})
        return axios({
            method: 'POST',
            url: `${ROOT_URL}/inventory/similar`,
            data: params
        })
        .finally(() => dispatch({type: STOP_LOADING, payload: 'Checking similar diamonds'}))
        .then(({data}) => data)
    }
}

export function excelExport(stones, extraColumns, {hideTier} = {}) {
    return function(dispatch) {
        const workbook = new Excel.Workbook();
        workbook.creator = 'Dialog';
        workbook.created = new Date();
        const sheet = workbook.addWorksheet('Dialog Inventory');
        const linkFmt = {underline: true, color: {argb: 'FF0000FF'}}
        const twoDecimals = x => Number(x).toFixed(2)
        let columns = [
            {header: 'Parcel Name', key: 'parcelName', width: 19},
            {header: 'Lab', key: 'lab', width: 15},
            {header: 'Cert Number', key: 'certNo', width: 18, excelCell: (cert, {lab}) => lab==='GIA' ? {text: cert, hyperlink: `https://www.gia.edu/report-check?reportno=${cert}`} : cert},
            {header: 'Weight', key: 'weight', width: 8, style: {numFmt: '0.00#'}},
            {header: 'Shape', key: 'shapeName', width: 8},
            {header: 'Colour', key: 'colour', width: 8},
            {header: 'Clarity', key: 'clarity', width: 8},
            {header: 'Fluor', key: 'fluorescence', width: 8},
            {header: 'Cut', key: 'cut', width: 8},
            {header: 'Pol', key: 'polish', width: 8},
            {header: 'Sym', key: 'symmetry', width: 8},
            {header: 'Table %', key: 'tablePct', width: 8},
            {header: 'Depth %', key: 'depthPct', width: 8.5},
            {header: 'Measurements', key: 'length', width: 14.5, excelCell: (value, stone) => `${twoDecimals(stone.length)}-${twoDecimals(stone.width)}\u00d7${twoDecimals(stone.height)}`},
            {header: 'Ratio', key: 'width', width: 8, excelCell: (value, stone) => twoDecimals(Math.max(stone.length,stone.width) / Math.min(stone.length, stone.width))},
            ...(hideTier ? [] : [{header: 'Tier', key: 'tier', width: 9.8}]),
            {header: 'RAP', key: 'displayRap', width: 10},
            {header: 'RAP %', key: 'displayRapPercent', width: 10},
            {header: 'Price', key: 'total', width: 12, style: {numFmt: '$#,##0.00'}},
            {header: 'Status', key: 'status', width: 12},
            {header: 'V360', key: 'photoUrl', width: 12, excelCell: value => ({text: 'Image/Video', hyperlink: value})}
        ]
        if (Array.isArray(extraColumns) && extraColumns.length) {
            columns = columns.concat(extraColumns.map(col => ({
                header: col.Header,
                key: col.accessor,
                width: col.minWidth / 5,
                excelCell: col.excelCell
            })))
        }
        sheet.columns = columns;
        sheet.addRows(stones);

        for (let col of columns.filter(c => c.excelCell)) {
            const fileColumn = sheet.getColumn(col.key);
            if (!fileColumn) continue;
            fileColumn.eachCell((cell, row) => {
                if (row > 1 && cell.value) {
                    const newValue = col.excelCell(cell.value, stones[row-2]);
                    cell.value = newValue;
                    if (newValue.hyperlink) cell.font = linkFmt
                }
            })
        }

        sheet.views = [{ state: 'frozen', ySplit: 1 }]
        return workbook.xlsx.writeBuffer()
        .then(buffer => {
            FileSaver.saveAs(new Blob([buffer], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"}), `Dialog Export.xlsx`);
        })
    }
}