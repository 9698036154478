import React, { Component } from 'react'
import {connect} from 'react-redux';
import * as actions from '../../actions'
import StoneTable from '../common/stone_table'
import { success } from '../common/toast';

class AvailableLabGrown extends Component {

    componentDidMount() {
        this.props.getAvailableLabGrown()
    }

    addToCart = (parcelName) => {
        this.props.addToLabGrownCart(parcelName)
        .then(() => {
            success('Added to Cart')
            this.props.getAvailableLabGrown()
            this.props.getLabGrownCart()
        })
    }

    renderLabGrown = () => {
        const stones = this.props.labGrown || [];
        return (
            <StoneTable 
                labGrown={true}
                data={stones}
                extraColumns={[
                    {
                        Header: '',
                        id: 'cartBtn',
                        width: 50,
                        sortable: false,
                        filterable: false,
                        Cell: (row) => row.original ? (
                            <button
                                title="Add to Cart"
                                disabled={row.original.inCart}
                                className="list-button"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    this.addToCart(row.original.parcelName);
                                }}
                            >{
                                row.original.inCart ?
                                <i className='fa fa-check-circle' /> : 
                                <i className='fa fa-shopping-cart' />
                            }</button>
                        ) : null
                    }
                ]}
                hideTier={false}
                large={true}
                exporter={this.props.excelLGExport}
                tableId='availableLG'
                allowGallery={true}
            />
        )
    }

    render() {
        return (
            <div style={{width: '100%'}}>
                {this.renderLabGrown()}
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        labGrown: state.labGrown.available
    }
}

export default connect(mapStateToProps, actions)(AvailableLabGrown)