import React, {useEffect, useState, useMemo} from 'react'
import {connect} from 'react-redux'
import Modal from 'react-responsive-modal'
import ReassortResults from './reassort_results'
import IdealUpload from './ideal_upload'
import * as actions from '../../actions'
import IdealAvailable from './ideal_available'

function IdealInventory({customer, ...props}) {
    const [currentPage, setCurrentPage] = useState(null)
    
    useEffect(() => {
        props.getStores(customer.customerId)
        props.getCustomerColumnMap(customer.customerId)
        props.getCustomerValueMap(customer.customerId)
        return () => {
            props.clearStores()
            props.clearCustomerColumnMap()
            props.clearCustomerValueMap()
        }
    }, [])
    
    const [stores, setStores] = useState([])
    const [colMap, setColMap] = useState([])
    const [valMap, setValMap] = useState([])
    useEffect(() => {
        setStores(props.stores)
    }, [props.stores])
    useEffect(() => {
        setColMap(props.colMap)
    }, [props.colMap])
    useEffect(() => {
        setValMap(props.valMap)
    }, [props.valMap])

    const handleStoreChange = (e) => {
        const target = e.currentTarget || e.target
        const idx = target.getAttribute('index')
        const sts = stores.slice()
        sts[idx] = {...sts[idx], [target.name]: target.value}
        setStores(sts)
    }

    const addStoreRow = () => {
        setStores([...stores, {storeName: ''}])
    }

    const removeStoreRow = (e) => {
        const target = e.currentTarget || e.target
        const rowIdx = target.getAttribute('index')
        const sts = stores.slice()
        sts.splice(rowIdx, 1)
        setStores(sts)
    }

    const saveStores = () => {
        props.saveStores(stores).then(() => props.getStores(customer.customerId))
    }

    const handleColChange = (e) => {
        const target = e.currentTarget || e.target
        const idx = target.getAttribute('index')
        const cm = colMap.slice()
        cm[idx] = {...colMap[idx], [target.name]: target.value}
        setColMap(cm)
    }

    const addColMapRow = () => {
        setColMap([...colMap, {columnHeader: '', customerStoneProperty: ''}])
    }
    const removeColMapRow = (e) => {
        const target = e.currentTarget || e.target
        const rowIdx = target.getAttribute('index')
        const newMap = colMap.slice()
        newMap.splice(rowIdx, 1)
        setColMap(newMap)
    }

    const saveColMap = () => {
        props.saveColMap(colMap).then(() => props.getCustomerColumnMap(customer.customerId))
    }

    const handleValChange = (e) => {
        const target = e.currentTarget || e.target
        const idx = target.getAttribute('index')
        const vm = valMap.slice()
        vm[idx][target.name] = target.value
        setValMap(vm)
    }

    const addValMapRow = () => {
        setValMap([...valMap, {customerStoneProperty: '', inputValue: '', outputValue: ''}])
    }
    const removeValMapRow = (e) => {
        const target = e.currentTarget || e.target
        const rowIdx = target.getAttribute('index')
        const newMap = valMap.slice()
        newMap.splice(rowIdx, 1)
        setValMap(newMap)
    }

    const saveValueMap = () => {
        props.saveValueMap(valMap).then(() => props.getCustomerValueMap(customer.customerId))
    }

    const [selectedInventoryFile, setSelectedInventoryFile] = useState(null)
    const handleFileChange = (e) => {
        const target = e.currentTarget || e.target
        if (target.name === 'currentStock') {
            if (target.files.length && target.files[0]) setSelectedInventoryFile(target.files[0])
            else setSelectedInventoryFile(null)
        }
    }

    useEffect(() => {
        props.getBinTemplates()
    }, [])
    const binTemplates = useMemo(() => props.binTemplates.filter(tmpl => tmpl.active && tmpl.customerId === customer.customerId), [props.binTemplates])

    const [templateId, setTemplateId] = useState(null)
    const handleTemplateChange = (e) => {
        const target = e.currentTarget || e.target
        setTemplateId(target.value)
    }
    useEffect(() => {
        setTemplateId(binTemplates.reduce((id,template) => template.active && template.id > id ? template.id : id, null))
    }, [binTemplates])

    const submitCurrentStock = () => {
        if (!selectedInventoryFile) {
            return null
        }
        new Promise((resolve,reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result);
            reader.onerror = (err) => reject(err);
            reader.readAsDataURL(selectedInventoryFile);
        })
        .then(dataUrl => {
            let splitString = dataUrl.split(/[\:\,]/);
            let data = splitString[splitString.length-1];
            props.loadCurrentStock({
                templateId,
                excelBody: data
            }).then(() => setSelectedInventoryFile(null))
        })
    }

    const [dialogIdeal, setDialogIdeal] = useState(null)
    const getAvailableStock = () => {
        props.compareDialogIdeal({templateId})
        .then((res) => setDialogIdeal(res))
    }

    return (
        <div>
            <div className='row spaced space-contents'>
                <span style={{fontWeight: 500}}>{customer.name}</span>
                <button className={`btn${currentPage==='columns' ? '' : ' btn-secondary'}`} onClick={() => setCurrentPage(currentPage==='columns' ? null : 'columns')}>Metadata</button>
                <button className={`btn${currentPage==='ideal' ? '' : ' btn-secondary'}`} onClick={() => setCurrentPage(currentPage==='ideal' ? null : 'ideal')}>Ideal Inventory</button>
                <button className={`btn${currentPage==='reassort' ? '' : ' btn-secondary'}`} onClick={() => setCurrentPage(currentPage==='reassort' ? null : 'reassort')}>Reassort</button>
            </div>
            {
                currentPage==='columns' ? (
                    props.isAdmin ? 
                    <div className='reassort_page--maps'>
                        <div style={{maxWidth: '90%', gridArea: 'stores', marginBottom: '2rem'}}>
                            Stores
                            {stores.map((store, i) => (
                                <div key={i} className='row spaced'>
                                    <span style={{display: 'inline-block', width: '3.5rem'}}>{store.storeId ?? 'New'}</span>
                                    <input value={store.storeName} name='storeName' index={i} onChange={handleStoreChange} placeholder='Store Name' />
                                    <button className='list-button btn-warning btn-small' index={i} onClick={removeStoreRow}><i className='fa fa-minus-circle'/></button>
                                </div>
                            ))}
                            <div className='row spaced'>
                                <button className='btn btn-secondary' onClick={addStoreRow}>Add</button>
                                <button className='btn' onClick={saveStores}>Save</button>
                            </div>
                        </div>
                        <div style={{maxWidth: '90%', gridArea: 'columnmap'}}>
                            Column Header Mapping
                            {colMap.map((col, i) => (
                                <div key={i} className='row spaced'>
                                    <input value={col.columnHeader} name='columnHeader' index={i} onChange={handleColChange} placeholder='Column Header' />
                                    <select value={col.customerStoneProperty} name='customerStoneProperty' index={i} onChange={handleColChange} placeholder='Select Column...'>
                                        {customerStoneProperties.map(({value, label}, j) => (<option key={j} value={value}>{label}</option>))}
                                    </select>
                                    <button className='list-button btn-warning btn-small' index={i} onClick={removeColMapRow}><i className='fa fa-minus-circle'/></button>
                                </div>
                            ))}
                            <div className='row spaced'>
                                <button className='btn btn-secondary' onClick={addColMapRow}>Add</button>
                                <button className='btn' onClick={saveColMap}>Save</button>
                            </div>
                        </div>
                        <div style={{maxWidth: '90%', gridArea: 'valuemap'}}>
                            Value Mapping
                            {valMap.map((val, i) => (
                                <div key={i} className='row spaced'>
                                    <select value={val.customerStoneProperty} name='customerStoneProperty' index={i} onChange={handleValChange} placeholder='Select Column...'>
                                        {customerStoneProperties.map(({value, label}, j) => (<option key={j} value={value}>{label}</option>))}
                                    </select>
                                    <input value={val.inputValue} name='inputValue' index={i} onChange={handleValChange} placeholder='Excel Value' />
                                    <input value={val.outputValue} name='outputValue' index={i} onChange={handleValChange} placeholder='Read As' />
                                    <button className='list-button btn-warning btn-small' index={i} onClick={removeValMapRow}><i className='fa fa-minus-circle'/></button>
                                </div>
                            ))}
                            <div className='row spaced'>
                                <button className='btn btn-secondary' onClick={addValMapRow}>Add</button>
                                <button className='btn' onClick={saveValueMap}>Save</button>
                            </div>
                        </div>
                    </div> :
                    <div className='reassort_page--maps'>
                        <div style={{maxWidth: '90%', gridArea: 'stores', marginBottom: '2rem'}}>
                            Stores
                            {stores.map((store, i) => (
                                <div key={i} className='row spaced'>
                                    <span style={{display: 'inline-block', width: '3.5rem'}}>{store.storeId ?? 'New'}</span>
                                    <input value={store.storeName} name='storeName' disabled index={i} placeholder='Store Name' />
                                </div>
                            ))}
                        </div>
                        <div style={{maxWidth: '90%', gridArea: 'columnmap'}}>
                            Column Header Mapping
                            {colMap.map((col, i) => (
                                <div key={i} className='row spaced'>
                                    <input value={col.columnHeader} name='columnHeader' index={i} disabled placeholder='Column Header' />
                                    <select value={col.customerStoneProperty} name='customerStoneProperty' index={i} disabled placeholder='Select Column...'>
                                        {customerStoneProperties.map(({value, label}, j) => (<option key={j} value={value}>{label}</option>))}
                                    </select>
                                </div>
                            ))}
                        </div>
                        <div style={{maxWidth: '90%', gridArea: 'valuemap'}}>
                            Value Mapping
                            {valMap.map((val, i) => (
                                <div key={i} className='row spaced'>
                                    <select value={val.customerStoneProperty} name='customerStoneProperty' index={i} disabled placeholder='Select Column...'>
                                        {customerStoneProperties.map(({value, label}, j) => (<option key={j} value={value}>{label}</option>))}
                                    </select>
                                    <input value={val.inputValue} name='inputValue' index={i} disabled placeholder='Excel Value' />
                                    <input value={val.outputValue} name='outputValue' index={i} disabled placeholder='Read As' />
                                </div>
                            ))}
                        </div>
                    </div>
                ) : null
            }
            {
                currentPage==='ideal' ? <IdealUpload /> : null
            }
            {
                currentPage==='reassort' && customer?.customerId!==1 ? 
                <div className='row spaced'>
                    <div>Upload Current Stock</div>
                    <select value={templateId} onChange={handleTemplateChange}>
                        {binTemplates.map(t => <option key={t.id} value={t.id}>{t.id}{t.idealInventoryName ? ' | ' + t.idealInventoryName : ''} | {t.templateString}</option>)}
                    </select>
                    <input name='currentStock' type='file' accept='.xlsx' onChange={handleFileChange} value={selectedInventoryFile==null ? '' : undefined} />
                    <button className='btn' onClick={submitCurrentStock} disabled={!selectedInventoryFile}>Submit</button>
                </div> : null
            }
            {
                currentPage==='reassort' && customer?.customerId===1 ? 
                <div className='row-spaced'>
                    <div>Check Current Stock</div>
                    <select value={templateId} onChange={handleTemplateChange}>
                        {binTemplates.map(t => <option key={t.id} value={t.id}>{t.id}{t.idealInventoryName ? ' | ' + t.idealInventoryName : ''} | {t.templateString}</option>)}
                    </select>
                    <button className='btn' onClick={getAvailableStock}>Get</button>
                </div> : null
            }
            <Modal 
                open={props.reassortOutput != null} 
                onClose={props.clearReassortResults}
                showCloseIcon={true}
                closeOnOverlayClick={false}
                styles={{modal: {maxWidth: '85vw', padding: '1rem'}}}
                center
            >
                <ReassortResults reassortOutput={props.reassortOutput} binTemplate={binTemplates.find(t => t.id==templateId)} />
            </Modal>
            <Modal
                open={dialogIdeal != null}
                onClose={() => setDialogIdeal(null)}
                showCloseIcon={true}
                closeOnOverlayClick={false}
                styles={{modal: {maxWidth: '85vw', padding: '1rem'}}}
                center
            >
                <IdealAvailable bins={dialogIdeal} template={binTemplates.find(tmp => tmp.id === templateId)} />
            </Modal>
        </div>
    )
}

const customerStoneProperties = [
    {value: null, label: ''},
	{value: 'customerStoneId', label: 'Stone ID'},
	{value: 'lab', label: 'Lab'},
	{value: 'certNo', label: 'Cert #'},
	{value: 'weight', label: 'Weight'},
	{value: 'shape', label: 'Shape'},
	{value: 'colour', label: 'Colour'},
	{value: 'clarity', label: 'Clarity'},
	{value: 'fluorescence', label: 'Fluor'},
	{value: 'cut', label: 'Cut'},
	{value: 'polish', label: 'Pol'},
	{value: 'symmetry', label: 'Symm'},
	{value: 'price', label: 'Price'},
	{value: 'retailPrice', label: 'Retail Price'},
	{value: 'status', label: 'Status'},
	{value: 'soldDate', label: 'Sold Date'},
	{value: 'storeId', label: 'Store ID'},
	{value: 'storeName', label: 'Store Name'},
    {value: 'quantity', label: 'Quantity'}
]

function mapStateToProps(state) {
    return {
        customer: state.user.customer,
        stores: state.admin.stores || [],
        colMap: state.admin.customerColumnMap || [],
        valMap: state.admin.customerValueMap || [],
        binTemplates: state.admin.binTemplates || [],
        reassortOutput: state.admin.reassortOutput || null,
        isAdmin: Boolean(state.user?.currentUser?.administrator)
    }
}

export default connect(mapStateToProps, actions)(IdealInventory)