import React, { useEffect, useState, useMemo } from 'react'
import {connect} from 'react-redux';
import * as actions from '../../actions'
import JewelleryModelTable from './jewellery_model_table'
import { success } from '../common/toast';
import NumberFormat from 'react-number-format';
import ConfirmModelModal from './confirm_model_modal';
import ModelManagement from './model_management'
import { exportToExcel } from './modelExcelReader';

function AvailableJewellery(props) {

    const jwls = useMemo(() => props.models || [], [props.models])

    useEffect(() => {
        props.getAvailableJewelleryModels()
    }, [])

    const setRequestQty = props.setModelsRequestQty

    //console.log('requestQty', requestQty)
    const getRequestQty = model => Number(props.requestQty[model] ?? 0)
    // const changeRequestQty = (e) => {
    //     const target = e.currentTarget || e.target
    //     if (target.name) {
    //         setRequestQty({...requestQty, [target.name]: Number(target.value)})
    //     }
    // }
    const upRequestQty = (e) => {
        const name = (e.currentTarget || e.target)?.name
        if (name) {
            setRequestQty({...props.requestQty, [name]: getRequestQty(name)+1})
        }
    }
    const downRequestQty = (e) => {
        const name = (e.currentTarget || e.target)?.name
        if (name) {
            if (getRequestQty(name) === 1) {
                const newQty = {...props.requestQty}
                delete newQty[name]
                setRequestQty(newQty)
            } else {
                setRequestQty({...props.requestQty, [name]: getRequestQty(name)-1})
            }
        }
    }
    const reqQty = useMemo(() => {
        return Object.values(props.requestQty).reduce((tot,qty) => tot+qty, 0)
    }, [props.requestQty])

    const [requestRemarks, setRequestRemarks]= useState('')

    const requestModels = async () => {
        const reqModels = Object.entries(props.requestQty)
        .filter(([model, qty]) => !!qty && qty >= 1 && jwls.some(jwl => jwl.name === model))
        .reduce((arr, [model, qty]) => arr.concat(Array(qty).fill(model)), [])
        props.requestJewelleryModels(reqModels, requestRemarks)
        .then(() => {
            setRequestRemarks('')
            setRequestQty({})
        })
        .then(() => props.getAvailableJewelleryModels())
    }

    const removeSelectedPiece = (jwlName) => {
        setRequestQty({...props.requestQty, [jwlName]: props.requestQty[jwlName]-1})
    }

    const [modelsModal, setModelsModal] = useState(false)
    const requestOver = useMemo(() => jwls.some(model => getRequestQty(model.name) > model.availableTotalQty), [jwls, props.requestQty])

    const ClearQtyButton = () => (
        <div className='table-filter-button-wrapper'>
            <button className='link-btn' disabled={reqQty==0} onClick={() => {setRequestQty({})}}>Clear</button>
        </div>
    )

    return (
        <div style={{width: '100%'}}>
            <div className='row spaced'>
                <ConfirmModelModal
                    confirm={requestModels} 
                    title='Confirm Request'
                    message={`Review ${reqQty} pieces and click "Request Stones" to confirm.`} 
                    confirmCaption='Request Jewellery'
                    deleteStone={removeSelectedPiece}
                    trigger={onClick => <button className='btn' onClick={onClick} disabled={reqQty==0}>Request</button>}
                    stones={
                        props.models.reduce((jwls, model) => jwls.concat(Array(props.requestQty[model.name] ?? 0).fill(model)), [])
                    }
                    comment={requestRemarks}
                    updateComment={(e) => setRequestRemarks(e.target.value)}
                />
                &nbsp;
                <button style={props.isAdmin ? {} : {display: 'none'}} className='btn' onClick={() => setModelsModal(true)}>Manage</button>
                &nbsp;
                {requestOver ? (
                    <span className='request-over-warning'>Some of the items you selected are not available on-hand. It may take some time to fill your order.</span>
                ) : null}
            </div>
            <JewelleryModelTable 
                data={jwls}
                extraColumns={[
                    {
                        Header: 'Order',
                        id: 'orderQty',
                        width: 50,
                        sortable: false,
                        Filter: ClearQtyButton,
                        Cell: (row) => row.original ? <>
                            <button 
                                name={row.original.name} 
                                className='table-stepper-button' 
                                disabled={getRequestQty(row.original.name)===0} 
                                onClick={downRequestQty}
                            >
                                <i className='fas fa-minus-circle'/>
                            </button>
                            <NumberFormat 
                                name={row.original.name} 
                                className={`model-req-qty${getRequestQty(row.original.name) > row.original.availableTotalQty ? ' over-request' : ''}`} 
                                value={getRequestQty(row.original.name)} 
                                decimalScale={0} 
                                // onChange={changeRequestQty} 
                                displayType='text'
                            />
                            <button
                                name={row.original.name}
                                className={`table-stepper-button${getRequestQty(row.original.name)>=row.original.availableTotalQty ? ' over-request' : ''}`} 
                                onClick={upRequestQty}
                            >
                                <i className='fas fa-plus-circle'/>
                            </button>
                        </> : null
                    }
                ]}
                exporter={rows => exportToExcel(rows, false)}
                tableId='availableJ'
                selected={props.requestQty}
            />
            <ModelManagement open={modelsModal} onClose={() => setModelsModal(false)} />
        </div>
    )
}

function mapStateToProps(state) {
    return {
        isAdmin: state.user.currentUser.administrator, 
        models: state.jewellery.models,
        requestQty: state.jewellery.toRequest || {}
    }
}

export default connect(mapStateToProps, actions)(AvailableJewellery)