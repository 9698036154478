import React, {Component} from 'react';
import {connect} from 'react-redux'
//import LoadImage from '../../assets/circle-laptop.png'
import Modal from 'react-responsive-modal'
import {Eclipse} from 'react-loading-io'
import * as actions from '../../actions'

function Loading(props) {
    const toLoad = props.loading ? Object.keys(props.loading).filter(x => props.loading[x]) : [];
    return (
        <Modal
            open={toLoad.length > 0}
            onClose={() => {}}
            showCloseIcon={false}
            classNames={{overlay: 'loading-overlay', modal: 'loading-modal'}}
            center
        >
            <div className='row centred-text'>
                {/*<img src={LoadImage} className='spinning' style={{width: '3rem'}} alt='Loading...' />*/}
                <Eclipse style={{marginLeft: '1.5rem'}} size={150} speed={1.25} width={3} color='var(--primary-colour)' /> {/*was #0B69A4 */}
            </div>
            <div className='row spaced'/>
            {toLoad.map(cause => (
                <div key={cause} className='row ellipsis-after'>{cause}</div>
            ))}
            <div className='row spaced centred-text'>
                <button className='link-btn' onClick={props.clearLoading}>Impatient</button>
            </div>
        </Modal>
    )
}

function mapStateToProps(state) {
    return {
        loading: state.tabs.loading
    }
}

export default connect(mapStateToProps, actions)(Loading);

// https://imkrunal.github.io/react-loading-io/?path=/story/eclipse--custom&knob-Size=150&knob-Color=rgba(11,105,164,1)&knob-Speed=1.25&knob-Width=3