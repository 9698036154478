import React, {Component} from 'react'
import {connect} from 'react-redux'
import ReactTable from 'react-table-6'
import NumberFormat from 'react-number-format'
//import ReactSlider from 'react-slider'
import * as actions from '../../actions'

class CustomerList extends Component {

    componentDidMount() {
        this.props.getAllCustomers();
    }

    defaultFilterMethod = (filter, row, column) => {
        const rowVal = row[filter.pivotId || filter.id]
        if (column.altFilters) {
            let rowVals = [rowVal, ...column.altFilters.map(id => row[id] || (row._original && row._original[id]))];
            return rowVals.some(val => val !== undefined ? String(val).toLowerCase().includes(String(filter.value).toLowerCase()) : false)
                || rowVals.every(val => val===undefined);
        } else {
            return rowVal !== undefined ? String(rowVal).toLowerCase().includes(String(filter.value).toLowerCase()) : true
        }
    }

    numericSort = (a,b) => {
        a = Number(a); b = Number(b);
        return a-b;
    }

    render() {
        return <ReactTable
            data={this.props.customerList}
            filterable={Array.isArray(this.props.customerList) && this.props.customerList.length > 5}
            resizable={false}
            className={'-highlight'}
            NoDataComponent={() => "0 customers found"}
            defaultFilterMethod={this.defaultFilterMethod}
            minRows={0}
            //Can enable paging by commenting out the next line
            showPagination={false} pageSize={(this.props.customerList).length}
            defaultSorted={[
                {id: "name", desc: false},
            ]}
            getTrProps={(state, row) => ({onClick: this.props.viewCustomer(row.original.customerId)})}
            columns={[
                {Header: 'ID', accessor: 'customerId', minWidth: 40},
                {Header: 'Name', accessor: 'name', minWidth: 140},
                {Header: 'Legal Name', accessor: 'legalName', minWidth: 160},
                {Header: 'Email', accessor: 'email', sortMethod: this.numericSort, minWidth: 110},
                {Header: 'Minimum', accessor: 'minInventory', minWidth: 80, Cell: ({value}) => (
                    <NumberFormat value={value} displayType='text' thousandSeparator={true} decimalScale={2} />
                )},
                {Header: 'Current', accessor: 'currentlyHolding', minWidth: 80, Cell: ({value}) => (
                    <NumberFormat value={value} displayType='text' thousandSeparator={true} decimalScale={2} />
                )},
                {Header: 'Maximum', accessor: 'maxInventory', minWidth: 80, Cell: ({value}) => (
                    <NumberFormat value={value} displayType='text' thousandSeparator={true} decimalScale={2} />
                )},
                /*{
                    Header: 'Current Inventory', id: 'currentlyHolding', minWidth: 120,
                    accessor: (row) => ([row.minInventory, row.currentlyHolding, row.maxInventory]),
                    Cell: ({value}) => (
                        <ReactSlider className='horizontal-slider'
                            thumbClassName='slider-handle' trackClassName='slider-track'
                            renderThumb={(props,state) => <div {...props}><div className='handle-div' /><div className='caption-div'>{state.valueNow}</div></div>}
                            value={value} min={Math.min(...value)} max={Math.max(...value)}
                        />
                    )
                }*/
            ]}
        />
    }
}

function mapStateToProps(state) {
    return {
        customerList: state.admin.customerList || []
    }
}

export default connect(mapStateToProps, actions)(CustomerList);