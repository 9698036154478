import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as actions from '../../actions'
import StoneTable from '../common/stone_table';
import { success } from '../common/toast';

class AvailableInventory extends Component {

    componentDidMount() {
        this.props.getAvailableInventory()
    }

    addToCart = (parcelName) => {
        this.props.addToCart(parcelName)
        .then(() => {
            success('Added to Cart')
            this.props.getAvailableInventory()
            this.props.getCart()
        })
    }

    cartDesire = (row) => {
        this.props.newDesire(row.desire)
        .then(() => {
            success('Added to Cart')
            this.props.getCart()
            row.inCart = true
        })
    }

    renderInventory = () => {
        const inventory = this.props.inventory || [];
        return (
            <StoneTable 
                data={inventory}
                extraColumns={[
                    {
                        Header: '',
                        id: 'cartBtn',
                        width: 50,
                        sortable: false,
                        filterable: false,
                        Cell: (row) => row.original ? (
                            <button
                                title="Add to Cart"
                                disabled={row.original.inCart}
                                className="list-button"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    if (!row.original.virtual) {
                                        this.addToCart(row.original.parcelName);
                                    } else {
                                        this.cartDesire(row.original);
                                    }
                                }}
                            >{
                                row.original.inCart ?
                                <i className='fa fa-check-circle' /> : 
                                <i className='fa fa-shopping-cart' />
                            }</button>
                        ) : null
                    }
                ]}
                exporter={this.props.excelExport}
                includeDesireRow={true}
                tableId='available'
                large={true}
                allowGallery={true}
            />
        )
    }

    render() {
        return (
            <div style={{width: '100%'}}>
                {this.renderInventory()}
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        inventory: state.inventory.available
    }
}

export default connect(mapStateToProps, actions)(AvailableInventory)